import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    Conversation,
    GetConversationRequestPayload,
    PageData,
} from '../common/types';
import {
    DeleteSalesOrderManuallyRequestPayload,
    GetSalesOrderChangesRequestPayload,
    GetSalesOrdersRequestPayload,
    RequestCreateSalesOrderManuallyPayload,
    RequestEditSalesOrderManuallyPayload,
    SalesOrder,
    SalesOrderAddCommentRequestPayload,
    SalesOrderChanges,
    SalesOrdersActionTypes,
} from './types';

export const getSalesOrdersRequestAction = (payload: GetSalesOrdersRequestPayload) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDERS_REQUEST, payload);
export const getSalesOrdersForOrganisationRequestAction = (payload: GetSalesOrdersRequestPayload) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDERS_FOR_ORGANISATION_REQUEST, payload);
export const getSalesOrdersSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(SalesOrdersActionTypes.GET_SALES_ORDERS_SUCCESS, payload);
export const getSalesOrdersErrorAction = (payload: string[]) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDERS_ERROR, payload);

export const updateSalesOrdersFiltersAction = (
    filters: GetSalesOrdersRequestPayload['filters']
) => action(SalesOrdersActionTypes.UPDATE_SALES_ORDERS_FILTERS, filters);
export const updateSalesOrdersSortByAndStateAction = (tableSortAndState: {
    sortBy: GetSalesOrdersRequestPayload['sortBy'];
    sortAscending: boolean;
    salesOrderState: string;
}) =>
    action(
        SalesOrdersActionTypes.UPDATE_SALES_ORDERS_SORT_BY_AND_STATE,
        tableSortAndState
    );

export const updateSalesOrdersTableFilterAction = (tableFilter: string) =>
    action(SalesOrdersActionTypes.UPDATE_SALES_ORDERS_TABLE_FILTER_STATE, tableFilter);

/**
 * Action for resetting table filters and sort values.
 */
export const clearSalesOrdersStateAllTableFiltersAction = () =>
    action(SalesOrdersActionTypes.CLEAR_SALES_ORDERS_STATE_ALL_TABLE_FILTERS);

export const clearSalesOrdersStateDataAction = () =>
    action(SalesOrdersActionTypes.CLEAR_SALES_ORDERS_STATE_DATA);

export const clearSalesOrdersListData = () =>
    action(SalesOrdersActionTypes.CLEAR_SALES_ORDERS_LIST_DATA);

// Single Record
export const setSelectedSalesOrderIdRequestAction = (
    salesOrderId: string | null,
    callback: () => void
) =>
    action(SalesOrdersActionTypes.SET_SALES_ORDER_SELECTED_ID_REQUEST, {
        salesOrderId,
        callback,
    });
export const setSelectedSalesOrderIdSuccessAction = (salesOrderId: string | null) =>
    action(SalesOrdersActionTypes.SET_SALES_ORDER_SELECTED_ID_SUCCESS, salesOrderId);

/**
 * Actions for getting salesOrder data.
 */
export const getSalesOrderDataRequestAction = (
    salesOrderId: string,
) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_DATA_REQUEST, {
        salesOrderId
    });
/**
 * Actions for getting salesOrder data.
 */
export const getOrganisationSalesOrderDataRequestAction = (
    salesOrderId: string,
    region: string,
    isUsingCloudImportType: boolean
) =>
    action(SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_DATA_REQUEST, {
        salesOrderId,
        region,
        isUsingCloudImportType,
    });

export const getSalesOrderDataSuccessAction = (payload: { record: {} }) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_DATA_SUCCESS, payload);
export const getSalesOrderDataErrorAction = (payload: string[]) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_DATA_ERROR, payload);

export const clearSalesOrderDataAction = () =>
    action(SalesOrdersActionTypes.CLEAR_SALES_ORDER_DATA_SUCCESS);

// Conversation actions
export const getSalesOrderConversationRequestAction = (
    payload: GetConversationRequestPayload
) => action(SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_REQUEST, payload);
export const getOrganisationSalesOrderConversationRequestAction = (
    payload: GetConversationRequestPayload,
    region: string
) => action(SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_CONVERSATION_REQUEST, {
    payload,
    region
});
export const getSalesOrderConversationSuccessAction = (payload: {
    data: Conversation[];
    pageData: PageData;
}) => action(SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_SUCCESS, payload);
export const getSalesOrderConversationErrorAction = (payload: string[]) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_ERROR, payload);

export const updateSalesOrderConversationFiltersAction = (
    filters: GetConversationRequestPayload['filters']
) => action(SalesOrdersActionTypes.UPDATE_SALES_ORDER_CONVERSATION_FILTERS, filters);

export const updateSalesOrderConversationTableFilterAction = (
    conversationTableFilter: string
) =>
    action(
        SalesOrdersActionTypes.UPDATE_SALES_ORDER_CONVERSATION_TABLE_FILTER,
        conversationTableFilter
    );
export const updateSalesOrderConversationSortByAction = (tableSort: {
    sortBy: GetConversationRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(SalesOrdersActionTypes.UPDATE_SALES_ORDER_CONVERSATION_SORT_BY, tableSort);

export const salesOrderAddCommentRequestAction = (
    payload: SalesOrderAddCommentRequestPayload
) => action(SalesOrdersActionTypes.SALES_ORDER_ADD_COMMENT_REQUEST, payload);
export const organisationSalesOrderAddCommentRequestAction = (
    payload: SalesOrderAddCommentRequestPayload,
    region: string
) => action(SalesOrdersActionTypes.ORGANISATION_SALES_ORDER_ADD_COMMENT_REQUEST, {
    payload,
    region
});

// SalesOrderChanges actions
/**
 * Action for getting the salesOrder changes list.
 * @param payload
 */
export const getSalesOrderChangesRequestAction = (
    payload: GetSalesOrderChangesRequestPayload
) => action(SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_REQUEST, payload);
export const getOrganisationSalesOrderChangesRequestAction = (
    payload: GetSalesOrderChangesRequestPayload,
    region: string
) => action(SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_CHANGES_REQUEST, {
    payload,
    region
});
export const getSalesOrderChangesSuccessAction = (payload: {
    data: SalesOrderChanges[];
    pageData: PageData;
}) => action(SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_SUCCESS, payload);
export const getSalesOrderChangesErrorAction = (payload: string[]) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_ERROR, payload);

export const updateSalesOrderChangesFiltersAction = (
    filters: GetSalesOrderChangesRequestPayload['filters']
) =>
    action(SalesOrdersActionTypes.UPDATE_SALES_ORDER_CHANGES_FILTERS, filters);

export const updateSalesOrderChangesTableFilterAction = (
    salesOrderChangesTableFilter: string
) =>
    action(
        SalesOrdersActionTypes.UPDATE_SALES_ORDER_CHANGES_TABLE_FILTER,
        salesOrderChangesTableFilter
    );
export const updateSalesOrderChangesSortByAction = (tableSort: {
    sortBy: GetSalesOrderChangesRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        SalesOrdersActionTypes.UPDATE_SALES_ORDER_CHANGES_SORT_BY,
        tableSort
    );

export const setTableCompactViewAction = (isCompact: boolean) =>
    action(SalesOrdersActionTypes.SALES_ORDER_SET_TABLE_COMPACT_VIEW, isCompact);

    /**
* Function for getting the Task Custom Fields for a company.
* @param payload
*/
export const getSalesOrderCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });

/** Function for create sales orders manually
 * @param payload
 */
export const createSalesOrderManuallyRequestAction = (
    payload: RequestCreateSalesOrderManuallyPayload
) => action(SalesOrdersActionTypes.CREATE_SALES_ORDER_MANUALLY_REQUEST, payload);

/**
 * Function for edit sales order manually
 * @param payload
 */
export const editSalesOrderManuallyRequestAction = (
    payload: RequestEditSalesOrderManuallyPayload
) => action(SalesOrdersActionTypes.EDIT_SALES_ORDER_MANUALLY_REQUEST, payload);

export const deleteSalesOrderManuallyRequestAction = (
    payload: DeleteSalesOrderManuallyRequestPayload
) => action(SalesOrdersActionTypes.DELETE_SALES_ORDER_MANUALLY_REQUEST, payload);

export const getSalesOrdersBySalesOrderCodesRequestAction = (
    payload: any,
    callback?: Function
) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_BY_SALES_ORDER_CODES_REQUEST, { payload, callback });

export const getSalesOrdersBySalesOrderCodesSuccessAction = (payload: {
    data: SalesOrder[];
}) => action(SalesOrdersActionTypes.GET_SALES_ORDER_BY_SALES_ORDER_CODES_SUCCESS, payload);
export const getSalesOrdersBySalesOrderCodesErrorAction = (payload: string[]) =>
    action(SalesOrdersActionTypes.GET_SALES_ORDER_BY_SALES_ORDER_CODES_ERROR, payload);