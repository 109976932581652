/**
 * Component to get the common fields for raising a ticket.
 */

import { Col, DatePicker, Form, Input, Row, Select, Slider } from 'antd';
import { filter, get, includes, map } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    raiseTicketFieldVariables,
    ticketOptionTypes,
} from '../../constants/customerAssistanceSortAndFilters';
import { dateFormatDDMMMYYYYSpace } from '../../constants/dateFormats';
import {
    getDomainPrefixBasedOnEnvironment,
    parseToHTML,
    getTranslatedText
} from '../../utils/commonFunctions';
import CurrencyInput from './CurrencyInput';
import { withNumberFormatHandler } from './NumberFormatHandler';

const { Option } = Select;
const { Item: FormItem } = Form;
const { TextArea } = Input;

interface IProps {
    readonly getFieldDecorator: any;
    readonly getFieldValue: any;
    readonly setFieldsValue: any;
    readonly ticketOptions: {
        options: any[];
        loading: boolean;
        customerId: string;
        companyId: string;
    };
    readonly isMobile?: boolean;
    readonly formatToParts: (amount: number) => string;
}
const RaiseTicketCommonFields: React.FC<IProps> = ({
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    ticketOptions,
    isMobile,
}: IProps) => {
    /**
     * Function for formatting the slider tip.
     * @param value
     */
    const sliderTipFormatter = (value: any) => {
        return `${value} month${value > 1 ? 's' : ''}`;
    };

    /**
     * Function that is called to validate the amount paid form field.
     * @param _rule
     * @param value
     */
    const checkAmountPaid = async (_rule: any, value: any) => {
        const usedValue =
            value || getFieldValue(raiseTicketFieldVariables.AMOUNT_PAID);

        if (!usedValue || !(usedValue > 0)) {
            throw new Error(getTranslatedText('Amount entered should be greater than 0!'));
        }
    };

    /**
     * Common function for populating currency input box
     * @param key
     */
    const populateNumberFormatElement = (key: string) => (
        <CurrencyInput
            onChange={(floatValue: undefined | number) => {
                setFieldsValue({ [key]: floatValue });
            }}
            currentValue={getFieldValue(key)}
        />
    );

    const disableWeekends = (date: Moment) => {
        const day = date.day();
        return day === 0 || day === 6; // 0: Sunday, 6: Saturday
    };

    /**
     * Function to populate the content body of the panel for raising ticket.
     */
    const populateContentBody = () => {
        const selectedTicketId = getFieldValue(
            raiseTicketFieldVariables.SELECT_ACTION
        );
        const selectedTicketOption = get(
            filter(ticketOptions.options, ['Id', selectedTicketId]),
            '0'
        );

        const Reason = get(selectedTicketOption, 'Reason');
        const Type = get(selectedTicketOption, 'Type');
        const CaptureMessage =
            get(selectedTicketOption, 'CaptureMessage') ||
            'More information is requested';
        
        /**
         * Retrieving ticket options label name
         */
        const ticketOptionLabels = get(selectedTicketOption, 'TicketOptionLabels');
        let dateTimeLabel = 'Date payment was made';
        let paymentLabel = 'Amount paid';

        if (ticketOptionLabels) {
            dateTimeLabel = get(ticketOptionLabels, 'DateTimeLabel');
            paymentLabel = get(ticketOptionLabels, 'PaymentLabel');
        }

        let disabledDate;
        if ((includes(Reason, 'Promise to pay') && (includes(dateTimeLabel, 'Intended payment date')))) {
            disabledDate = (dateValue: any) => {
                return moment(dateValue).isBefore(moment().startOf('day')) || disableWeekends(moment(dateValue));
            };
        }

        if (Type === ticketOptionTypes.INVOICE_PAID) {
            return (
                <Col md={24} sm={24}>
                    <Row>
                        <Col span={24}>
                            <i>{getTranslatedText('Please provide payment information')}</i>
                        </Col>
                    </Row>
                    <Row type="flex" align="middle">
                        <Col xs={24} md={10}>
                            {getTranslatedText(dateTimeLabel)}:
                        </Col>
                        <Col xs={24} md={14} className="ta-right">
                            <FormItem>
                                {getFieldDecorator(
                                    raiseTicketFieldVariables.PAYMENT_DATE,
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    getTranslatedText('Payment date required!'),
                                            },
                                        ],
                                    }
                                )(
                                    <DatePicker
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder={getTranslatedText("Date")}
                                        disabledDate={disabledDate}
                                        style={{
                                            width: isMobile ? '58.5%' : '100%',
                                        }}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="mt-5" type="flex" align="middle">
                        <Col xs={10}>{getTranslatedText(paymentLabel)}:</Col>
                        <Col xs={14}>
                            <FormItem>
                                {getFieldDecorator(
                                    raiseTicketFieldVariables.AMOUNT_PAID,
                                    {
                                        initialValue: 0.0,
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    getTranslatedText('Please enter a valid amount!'),
                                                validator: checkAmountPaid,
                                            },
                                        ],
                                    }
                                )(
                                    populateNumberFormatElement(
                                        raiseTicketFieldVariables.AMOUNT_PAID
                                    )
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={24}>{getTranslatedText('Any other information')}:</Col>
                        <Col xs={24}>
                            <FormItem>
                                {getFieldDecorator(
                                    raiseTicketFieldVariables.OTHER_INFORMATION,
                                    {
                                        rules: [
                                            {
                                                max: 1024,
                                                message:
                                                    getTranslatedText('Up to 1024 characters only can be saved!'),
                                            }
                                        ],
                                    }
                                )(<TextArea rows={3} />)}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else if (
            includes(Reason, 'incorrect') &&
            Type === ticketOptionTypes.GENERAL
        ) {
            return (
                <Col md={24} sm={24}>
                    <Row>
                        <Col span={24}>
                            <i>
                                {getTranslatedText('Please identify which information is incorrect')}
                            </i>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={24}>{getTranslatedText('Please provide further information')}</Col>
                        <Col xs={24}>
                            <FormItem>
                                {getFieldDecorator(
                                    raiseTicketFieldVariables.OTHER_INFORMATION,
                                    {
                                        rules: [
                                            {
                                                max: 1024,
                                                message:
                                                    getTranslatedText('Up to 1024 characters only can be saved!'),
                                            }
                                        ],
                                    }
                                )(<TextArea rows={3} />)}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else if (includes(Reason, 'payment plan')) {
            const sliderMonthValue =
                getFieldValue(raiseTicketFieldVariables.SLIDER_MONTH) || 1;
            return (
                <Col md={24} sm={24}>
                    <Row>
                        <Col span={24}>
                            <i>{getTranslatedText('Please provide more information')}</i>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={24}>
                            {getTranslatedText('Select the requested payment plan duration')}
                        </Col>
                        <Col xs={24}>
                            <Row>
                                <Col xs={24} sm={20}>
                                    <FormItem>
                                        {getFieldDecorator(
                                            raiseTicketFieldVariables.SLIDER_MONTH,
                                            {
                                                initialValue: 1,
                                                rules: [
                                                    {
                                                        type: 'number',
                                                        message:
                                                            getTranslatedText('The number of months selected is not valid!'),
                                                    },
                                                ],
                                            }
                                        )(
                                            <Slider
                                                min={1}
                                                max={12}
                                                marks={{
                                                    2: 2,
                                                    4: 4,
                                                    6: 6,
                                                    8: 8,
                                                    10: 10,
                                                    12: 12,
                                                }}
                                                tipFormatter={
                                                    sliderTipFormatter
                                                }
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col
                                    className="ta-center"
                                    xs={24}
                                    sm={4}
                                    style={
                                        isMobile
                                            ? {}
                                            : {
                                                  paddingLeft: 10,
                                                  paddingTop: 28,
                                              }
                                    }
                                >
                                    {getTranslatedText('Months')}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} className="ta-center">
                                    <b>
                                        <i>
                                            {getTranslatedText(`You are required to pay $550.00 per
                                            month for ${sliderMonthValue} month
                                            ${sliderMonthValue > 1 ? 's' : ''}`)}
                                        </i>
                                    </b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            );
        } else if (Type === ticketOptionTypes.GENERAL) {
            return (
                <Col md={24} sm={24}>
                    <Row>
                        <Col span={24}>
                            <i>{getTranslatedText(CaptureMessage)}</i>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={24}>{getTranslatedText('Please provide further information')}</Col>
                        <Col xs={24}>
                            <FormItem>
                                {getFieldDecorator(
                                    raiseTicketFieldVariables.OTHER_INFORMATION,
                                    {
                                        rules: [
                                            {
                                                max: 1024,
                                                message:
                                                    getTranslatedText('Up to 1024 characters only can be saved!'),
                                            }
                                        ],
                                    }
                                )(<TextArea rows={3} />)}
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
            );
        } else if (Type === ticketOptionTypes.INFO_ONLY) {
            const primaryText = get(selectedTicketOption, 'PrimaryText');
            const primaryTextDisplay = parsePrimaryTextForDisplay(primaryText);
            return (
                <Col md={24} sm={24}>
                    <Row>
                        <Col span={24}>{parseToHTML(primaryTextDisplay)}</Col>
                    </Row>
                </Col>
            );
        } else if (Type === ticketOptionTypes.INFO_ESCALATE) {
            const primaryText = get(selectedTicketOption, 'PrimaryText');
            const primaryTextDisplay = parsePrimaryTextForDisplay(primaryText);
            const supplementaryText = get(
                selectedTicketOption,
                'SupplementaryText'
            );
            return (
                <Col md={24} sm={24}>
                    <Row>
                        <Col span={24}>{parseToHTML(primaryTextDisplay)}</Col>
                    </Row>
                    <Row>
                        <Col span={24}>{parseToHTML(supplementaryText)}</Col>
                    </Row>
                </Col>
            );
        } else {
            return null;
        }
    };

    /**
     * Function for displaying the primary text and replacing all the placeholders.
     */
    const parsePrimaryTextForDisplay = (primaryText: any) => {
        const usedDomainPrefix = getDomainPrefixBasedOnEnvironment();

        return primaryText
            .replaceAll('[CustomerId]', get(ticketOptions, 'customerId'))
            .replaceAll('[CompanyId]', get(ticketOptions, 'companyId'))
            .replaceAll('[Environment]', usedDomainPrefix);
    };

    const onChangeTicketOptionType = () => {
        setFieldsValue({ [raiseTicketFieldVariables.PAYMENT_DATE]: null });
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        {getTranslatedText('Select from one of the options and fill in any details if required')}
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col span={24}>
                        <FormItem>
                            {getFieldDecorator(
                                raiseTicketFieldVariables.SELECT_ACTION,
                                {
                                    initialValue: get(
                                        ticketOptions.options,
                                        '0.Id'
                                    ),
                                }
                            )(
                                <Select
                                    style={{ width: '100%' }}
                                    loading={ticketOptions.loading}
                                    onChange={onChangeTicketOptionType}
                                >
                                    {map(
                                        ticketOptions.options,
                                        ({ Id, Reason }) => (
                                            <Option key={Id} value={Id}>
                                                {getTranslatedText(Reason)}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row className="mt-5">{populateContentBody()}</Row>
            </Col>
        </Row>
    );
};

export default withNumberFormatHandler(RaiseTicketCommonFields);
