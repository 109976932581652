/**
 * Component drawer for Configuring widget panel.
 */

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    Modal,
    Row,
    Select,
    TimePicker,
} from 'antd';

import { get, initial, isEmpty, map, set } from 'lodash';
import moment from 'moment-timezone/moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    scheduleMonthlyTimingOptions,
    scheduleTimingOptions,
    scheduleTypeOptions,
} from '../../constants/dashboards';
import {
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import SelectReadonly from '../FormComponents/SelectReadonly';
import { dateFormatDDMMMYYYYSpace, dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { ScheduledReportDateFilter } from '../../constants/reportSortAndFilters';
import ModalWithSpinner from '../common/ModalWithSpinner';
import { createScheduledReportRequest } from '../../store/scheduledReports/actions';
import { getTranslatedText } from '../../utils/commonFunctions';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    visible: boolean;
    readonly closePanel?: (closePanel?: boolean) => void;
    readonly form?: any;
    widgetQuery: string[];
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 15 },
    },
};

const CreateCompleteScheduledReportPanel: React.FC<IProps> = ({
    visible,
    closePanel,
    form,
    widgetQuery,
}: IProps) => {
    const drawerRef: any = useRef(null);
    const [hideModalBody, setHideModalBody] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
    } = form;

    const [scheduleType, setScheduleType] = useState<number>(0);
    const [applyScheduleDateRange, setApplyScheduleDateRange] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    /**
     * Function called when the `Save` button is clicked.
     */
    const onSaveButtonClick = () => {
        validateFields((err: any, values: any) => {
            setSubmitLoading(true);
            const ScheduleStartDate = get(values, 'ReportDateStart');
            const ScheduleStartTime = get(values, 'ReportTimeStart')
                if (ScheduleStartDate) {
                    set(values, 'ReportDateStart', moment(ScheduleStartDate).format(
                        dateFormatYYYYMMDDDash
                    ).concat(" ").concat(moment(ScheduleStartTime).minutes(5).format("hh:mm A")));
                }
            const getIsApplySchedule = get(values ,'applyScheduleRange') ? get(values ,'applyScheduleRange') : false;

            const payload = {
                ScheduleType: get(values ,'scheduleType'),
                ScheduleTiming1: get(values ,'scheduleTiming1'),
                ScheduleTiming2: get(values ,'scheduleTiming2'), 
                ApplyScheduleDate: getIsApplySchedule, 
                DateFilterField: get(values ,'DateFilter'), 
                ScheduleStartDate: (new Date(get(values ,'ReportDateStart'))).toISOString(),
                Type: getIsApplySchedule ? get(widgetQuery, 'Type') : 3 ,
                ...widgetQuery
            }

            dispatch(
                createScheduledReportRequest({
                    ...payload,
                    callback: actionResponseModal
                })
            );
        });
    };

    /**
     * Function for populating the response modal when completing action.
     * Either success/error.
     */
    const actionResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: getTranslatedText("Scheduled report created successfully!"),
                zIndex:2500
            });
            resetFields();
            setApplyScheduleDateRange(false);
            if (closePanel) closePanel(true);
        } else {
            let errorMessageContent: any = getTranslatedText("Failed to create scheduled report!");
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                zIndex: 2500
            });
        }
    };

    /**
     * Function called when the `Close` button is clicked.
     */
    const onCancelButtonClick = () => {
        if (closePanel) closePanel();
    };
  
    const disablePastDates = (current:any) => {
        return current && current < moment().startOf('day');
      };

      /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
        else{
            const applySchedule = getFieldValue('applyScheduleRange'); 
            setApplyScheduleDateRange(applySchedule);
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    return (
            <div
                className="drawer-container"
                ref={drawerRef}
            >
                <Form
                    labelAlign='left'
                    className="form-inline-mb-0"
                    {...formItemLayout}
                >
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            {getTranslatedText("The report will be generated on the schedule you choose, based on the current filters applied on this search. Please check your filters are correct before creating the schedule.")}
                        </Col>
                        <Col span={24}>
                            <FormItem label={getTranslatedText("Type")}>
                                {getFieldDecorator('type', {
                                    initialValue: getTranslatedText("Complete Report"),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Type required!',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Type"
                                        disabled={true}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label={getTranslatedText("Schedule Type")}>
                                    {getFieldDecorator('scheduleType', {
                                        initialValue: scheduleTypeOptions[1].value,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Schedule Type required!',
                                            },
                                        ],
                                    })(
                                        <SelectReadonly
                                        style={{ width: '100%' }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                        onChange={(value:any)=> {
                                            setScheduleType(value);
                                        }}
                                    >
                                        {map(
                                            scheduleTypeOptions.filter(({ label }) => label !== 'Hourly'),
                                            ({ label, value }: { label: string; value: string }) => (
                                                <Option key={value} value={value}>
                                                    {getTranslatedText(label)}
                                                </Option>
                                            )
                                        )}
                                    </SelectReadonly>
                                    )}
                            </FormItem>
                        </Col>
                        <Col span={14}>
                            <FormItem label={getTranslatedText("Timing")}>
                                    {getFieldDecorator('scheduleTiming1', {
                                        initialValue: scheduleTimingOptions(getFieldValue('scheduleType'))[0].value,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Timing required!',
                                            },
                                        ],
                                    })(
                                        <SelectReadonly
                                        style={{ width: '100%' }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(
                                            scheduleTimingOptions(getFieldValue('scheduleType')),
                                            ({ label, value }: { label: string; value: string }) => (
                                                <Option key={value} value={value}>
                                                    {label}
                                                </Option>
                                            )
                                        )}
                                    </SelectReadonly>
                                    )}
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            {scheduleType === 3 &&
                                <FormItem label={getTranslatedText("On the")}>
                                    {getFieldDecorator('scheduleTiming2', {
                                        initialValue: scheduleMonthlyTimingOptions()[0].value,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Timing required!',
                                            },
                                        ],
                                    })(
                                        <SelectReadonly
                                        style={{ width: '100%' }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(
                                            scheduleMonthlyTimingOptions(),
                                            ({ label, value }: { label: string; value: string }) => (
                                                <Option key={value} value={value}>
                                                    {label}
                                                </Option>
                                            )
                                        )}
                                    </SelectReadonly>
                                    )}
                            </FormItem>
                            }
                        </Col>
                        <Col span={24}>
                            <FormItem wrapperCol={{ span: 24 }}>
                                        {getFieldDecorator("applyScheduleRange", {
                                            initialValue: true,
                                            valuePropName: 'checked',
                                            onChange: (event: any) => {
                                                const checked = get(event, 'target.checked');
                                                    setApplyScheduleDateRange(checked);
                                            }
                                        })(
                                            <Checkbox
                                            disabled={true}
                                            >
                                                {getTranslatedText("Apply schedule range as a date filter")}</Checkbox>
                                        )}
                            </FormItem>
                            <FormItem label={getTranslatedText("Date Filter")} >
                                {getFieldDecorator('DateFilter', {
                                    initialValue: 'Created Date',
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Date Filter required!',
                                        },
                                    ],
                                })(
                                    <Select
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                    disabled={true}
                                >
                                        <Option key="Created Date" value="Created Date" >
                                            Created Date
                                        </Option>
                                </Select>
                                )}
                        </FormItem>
                            
                        </Col>
                        <Col span={15}>
                            <FormItem label={getTranslatedText("Report Start")} >
                                    {getFieldDecorator('ReportDateStart', {
                                        initialValue: moment(),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Date required!',
                                            },
                                        ],
                                    })(
                                        <DatePicker
                                        className="dashboard-datepicker"
                                        format={dateFormatDDMMMYYYYSpace}
                                        placeholder="Date"
                                        style={{ width: '100%' }}
                                        disabledDate={disablePastDates}
                                        getCalendarContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    />
                                    )}
                            </FormItem>
                        </Col>
                        <Col span={9}>
                            <FormItem>
                                    {getFieldDecorator('ReportTimeStart', {
                                        initialValue: moment().add(1, 'hour'),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Time required!',
                                            },
                                        ],
                                    })(
                                        <TimePicker
                                        className="dashboard-datepicker"
                                            use12Hours 
                                            format="hh a"
                                            placeholder='Time'
                                            style={{ width: '100%' }}
                                            getPopupContainer={populatePopoverContainer(
                                                drawerRef
                                            )}
                                        />
                                    )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                    <Col className="ta-right">
                        <Button
                            className="mr-10 w-100px"
                            type="primary"
                            onClick={onSaveButtonClick}
                        >
                            {getTranslatedText("Create")}
                        </Button>
                        <Button
                            className="buttonGrey w-100px"
                            onClick={onCancelButtonClick}
                        >
                            {getTranslatedText("Back")}
                        </Button>
                    </Col>
                </Row>
                </Form>
                {submitLoading && (
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Creating scheduled report")}
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while creating scheduled report . . .")}
                        zIndex={2500}
                    />
                )}
            </div>
    );
};

const CreateCompleteScheduledReportPanelForm = Form.create({
    name: 'create-complete-scheduled-report-panel-form'
})(CreateCompleteScheduledReportPanel);

export default withRouter(CreateCompleteScheduledReportPanelForm);
