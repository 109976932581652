export const USERS_PAGE = {
    pageSize: 20,
    rowHeight: 65,
};

export const ACCESS_TOKENS_PAGE = {
    pageSize: 150,
    rowHeight: 65,
};

export const IMPORT_HISTORY_PAGE = {
    pageSize: 14,
    rowHeight: 50,
    IMPORT_HISTORY_DATA: {
        status: {
            inProgress: 'InProgress',
            successful: 'successful'
        }
    },
    ERRORS_AND_WARNINGS: {
        pageSize: 10,
        rowHeight: 50,
    }
};

export const CUSTOMERS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const INVOICES_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const SALES_ORDERS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};


export const REPORTS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const CREDITS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const TASKS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
    compactRowHeight: 40,
};

export const CUSTOMER_ASSISTANCE_PAGE = {
    pageSize: 20,
    rowHeight: 80,
    dataTasks: {
        pageSize: 20,
    },
};

export const TASK_HISTORY_PAGE = {
    pageSize: 20,
    rowHeight: 80,
    notifications: {
        pageSize: 20,
        rowHeight: 50,
        tasks: {
            pageSize: 20,
            rowHeight: 70,
        },
    },
    deliveryReport: {
        pageSize: 20,
        rowHeight: 50,
        tasks: {
            pageSize: 20,
            rowHeight: 70,
        },
    },
    changedTasks: {
        pageSize: 20,
        rowHeight: 70,
    },
    collections: {
        pageSize: 20,
        rowHeight: 70,
    },
    failedTasks: {
        pageSize: 20,
        rowHeight: 70,
    }
};

export const PAYMENTS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const REMITTANCE_ADVICES_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const ORGANISATION_COMPANIES_PAGE = {
    pageSize: 20,
    rowHeight: 70,
};

export const BANK_FILE_DESCRIPTORS_PAGE = {
    pageSize: 20,
    rowHeight: 65,
};

export const ORGANISATION_USERS_PAGE = {
    pageSize: 20,
    rowHeight: 70,

    companiesAndRoles: {
        pageSize: 20,
        rowHeight: 50,
    },
};

export const PAYMENT_PLANS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const DETAILS_TAB = {
    INVOICE_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 70,
    },
    INVOICE_CHANGES_TIMELINE: {
        pageSize: 20,
    },
    INVOICE_LIST: {
        pageSize: 20,
        rowHeight: 50,
    },
    CUSTOMER_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 70,
    },
    CUSTOMER_CHANGES_TIMELINE: {
        pageSize: 20,
    },
    CUSTOMER_LIST: {
        pageSize: 20,
        rowHeight: 50,
    },
    CONVERSATION_TIMELINE: {
        pageSize: 20,
    },
    CREDIT_CHANGES_TIMELINE: {
        pageSize: 20,
    },
    PAYMENT_CHANGES_TIMELINE: {
        pageSize: 20,
        rowHeight: 50,
    },
    REMITTANCE_ADVICE_CHANGES_TIMELINE: {
        pageSize: 20,
        rowHeight: 50,
    },
    CREDIT_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 70,
    },
    PAYMENT_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 70,
    },
    PAYMENT_PLAN_SCHEDULE_LIST: {
        pageSize: 20,
        rowHeight: 70,
    },
    BANK_FILE_DESCRIPTOR_CHANGES_TIMELINE: {
        pageSize: 20,
    },
    SALES_ORDER_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 70,
    },
    SALES_ORDER_CHANGES_TIMELINE: {
        pageSize: 20,
    },
    SALES_ORDER_LIST: {
        pageSize: 20,
        rowHeight: 50,
    },
    TICKET_LIST_COMPLETE: {
        pageSize: 20,
        rowHeight: 50,
    },
};

export const PAYMENT_REMMITANCE_PAGE = {
    pageSize: 10,
    rowHeight: 80,
};

export const CUSTOMIZE_SMS_PROVIDER = {
    pageSize: 150,
    rowHeight: 65,
};

export const SUPPORT_TICKET_LISTS_PAGE = {
    pageSize: 20,
    rowHeight: 65,
};

export const WORKFLOW_CONFIGURATIONS_PAGE = {
    pageSize: 20,
    rowHeight: 80,
};

export const PAGE_NAMES_FOR_VIEW = {
    USERS_PAGE: 'UsersPage',
    CUSTOMERS_PAGE: 'CustomersPage',
    CUSTOMERS_ORGANISATION_PAGE: 'CustomersOrganisationPage',
    INVOICES_PAGE: 'InvoicesPage',
    INVOICES_ORGANISATION_PAGE: 'InvoicesOrganisationPage',
    SALES_ORDERS_PAGE: 'SalesOrdersPage',
    SALES_ORDERS_ORGANISATION_PAGE: 'SalesOrdersOgranisationPage',
    TASK_HISTORY_PAGE: 'TaskJobHistoryPage',
    TASK_HISTORY_ORGANISATION_PAGE: 'TaskJobHistoryOrganisationPage',
    CUSTOMER_ASSISTANCE_PAGE: 'CustomerAssistancePage',
    TASKS_PAGE: 'ActiveTasksPage',
    TASKS_ORGANISATION_PAGE: 'ActiveTasksOrganisationPage',
    INACTIVE_TASKS_PAGE: 'InActiveTasksPage',
    ARCHIVE_TASKS_PAGE: 'ArchiveTasksPage',
    ACCESS_TOKENS_PAGE: 'AccessTokensPage',
    CREDITS_PAGE: 'CreditsPage',
    CREDITS_ORGANISATION_PAGE: 'CreditsOrganisationPage',
    DASHBOARD_PAGE: 'Dashboard',
    PAYMENTS_PAGE: 'PaymentsPage',
    PAYMENT_PLANS_PAGE: 'PaymentPlansPage',
    REMITTANCE_ADVICES_PAGE: 'RemittanceAdvicesPage',
    TASK_DELIVERY_REPORT_PAGE: 'DeliveryHistoriesPage',
    IMPORT_HISTORY_PAGE: 'ImportHistoryPage',
    REPORT_HISTORY_PAGE: 'ReportHistoryPage',
    TICKET_CHAT_PAGE: 'TicketChatPage',
    WORKFLOW_CONFIGURATIONS_PAGE: 'WorkflowConfigurationsPage',
    BANK_FILE_DESCRIPTORS_PAGE: 'BankFileDescriptorsPage',

    ORG_COMPANIES_PAGE: 'OrgCompaniesPage',
    ORG_USERS_PAGE: 'OrgUsersPage',
    CONVERSATION_PAGE: 'ConversationPage',
    SUPPORT_TICKET_LISTS: 'Support Ticket Lists'
};

export const WIDGET_NAMES = {
    SALES: 'SalesWidget',
    OPEN_SUPPORT_TICKETS: 'OpenSupportTicketsWidget',
    PAYMENTS: 'PaymentsWidget',
    TOP_INVOICES: 'TopInvoicesWidget',
    TOP_CREDITS: 'TopCreditsWidget',
    OPEN_INVOICES: 'OpenInvoicesWidget',
    CURRENT_ATB: 'CurrentATBWidget',
    NOTIFICATIONS: 'NotificationsWidget',
    TICKETS: 'TicketsWidget',
    INVOICING_SETTLEMENTS: 'InvoicingAndSettlementsWidget',
    NOTIFICATION_SUCCESS: 'NotificationSuccessWidget',
    TOP_CUSTOMERS: 'TopCustomersWidget',
    TASKS: 'TasksWidget',
    TASK_COUNT_ACTIONED_BY_USER:'TaskActionedPerUserWidget',
    TASK_COUNT_ACTIONED_BY_USER_TYPE:'TaskActionedPerUserTypeWidget',
    CUSTOMERS_SETTLEMENT: 'CustomersSettlementTimeframeWidget',
    STATISTIC_CHANGES_PER_TYPE: 'StatisticChangesPerTypeWidget',
    STATISTIC_CHANGES: 'StatisticChangesWidget',
    RECENT_CHANGES: 'RecentChangesWidget',
    ACTIVE_TASKS_PER_USER: 'ActiveTasksPerUserWidget',
    ACTIVE_TASKS_PER_USER_TYPE: 'ActiveTasksPerUserTypeWidget',
    TICKETS_FOR_USER: 'UsersAssignedToTicketsWidget',
    TICKETS_FOR_USER_TYPE: 'TicketsForUserTypeWidget',
    COMMUNICATION_DELIVERY: 'CommunicationDeliveryWidget',
    PAYMENTS_ALLOCATION: 'PaymentsAllocationWidget',
    TOTAL_OWNED: 'TotalOwedWidget',
    CASHFLOW_FORECASTING:'CashflowForecastingWidget',
    DAILY_SALES_OUT: 'DailySalesOutWidget',
    CUSTOMER_PAYMENT_BEHAVIOUR_INSIGHTS: 'CustomersPaymentBehaviourBreakdownWidget',
    CUSTOMER_INSIGHTS_HISTORY: 'CustomersInsightsHistoryWidget'
}

export const CUSTOM_FIELD_TYPES = {
    INVOICE: 'Invoice',
    CUSTOMER: 'Customer',
    CREDIT: 'Credit',
    SALES_ORDER: 'SalesOrder'
};

export const OPEN_SUPPORT_TICKETS = {
    STATUS: 1,
};
