/**
 * Component that validates if user is signed in.
 */

import { Alert, Button, Icon, Input, Modal, Tooltip } from 'antd';
import Amplify, { Auth, Hub } from 'aws-amplify';
import Cookies from 'js-cookie';
import { get, includes, isEmpty, map } from 'lodash';
import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import iodmLogo from '../../assets/loginLogo.png';
import { DEFAULT_REGION_NAME, INVITE_COMPANY_NAME, INVITE_TOKEN_NAME, IODM_LOGIN_WITH_HOSTED_UI, TOKEN_NAME } from '../../config/config';
import { ApplicationState } from '../../store';
import {
    companyInviteLinkAction,
    companyInviteLinkingInProgressUpdateAction,
} from '../../store/invites/actions';
import { CompanyInviteLinkResponse } from '../../store/invites/types';
import {
    getRegionConfigFromList,
    setCookieLastActiveCurrent,
    setShouldFetchAnnouncementsOnLogin,
    getTranslatedText
} from '../../utils/commonFunctions';
import { withAuthHandler } from '../common/AuthHandler';
import './App.less';
import { getIdentityProviderConfigAction, getRegionKeyConfigAction, getRegionSettingsConfigAction, signInWithOtherProvidersAction } from '../../store/auth/actions';
import { generateAmplifyConfig, getFederatedDefaultConfig } from '../../AmplifyConfig';
import { getDemoOptionsConfigAction } from '../../store/common/actions';
import { getIdentityProviderConfig, getRegionKeyConfig, getRegionSettingConfig } from '../../store/auth/sagas';

interface IProps {
    authState?: string;
    authData?: object | string;
    loginUser: (userData: string | object | undefined) => void;
    logoutUser: () => void;
    forgotPasswordRef?: any;
    setWithTopPadding: (withTopPadding: boolean) => void;
}

let listenerSetUpCalled = false;
let resendCodeDone = false;
let timeoutHandle: any = null;
const AuthAndLogoContainer: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const currentRoute = useSelector(
        (state: ApplicationState) => state.storedRoute.currentRoute
    );
    const isAuth = useSelector((state: ApplicationState) => state.auth.isAuth);
    const regionSettingsConfig = useSelector(getRegionSettingConfig);
    const regionKeyConfig = useSelector(getRegionKeyConfig);
    const identityProviderConfig = useSelector(getIdentityProviderConfig);
    const authContainerRef = useRef<any>(null);

    const [showHelperText, setShowHelperText] = useState<boolean>(false);
    const [providerName, setProviderName] = useState<string>('');
    const [ssoLoginErrorMessage, setSsoLoginErrorMessage] = useState<string>('');
    const [customLoading, setCustomLoading] = useState<boolean>(true);
    
    /**
     * Function for populating the messages in the (success/error) modal content.
     * @param messages - array list of messages
     */
    const populateMessages = (messages: string[]) => {
        const messagesComponent = map(
            messages,
            (message: string, index: number) => <p key={index}>{message}</p>
        );

        return (
            <>
                <span>{messagesComponent}</span>
            </>
        );
    };

    /**
     * Function for setting if top padding from parent should be set
     */
    const updateWithTopPadding = () => {
        const withTopPadding = props.authState === 'signUp' && showHelperText;
        props.setWithTopPadding(withTopPadding);
    };

    useEffect(updateWithTopPadding, [showHelperText, props.authState]);

    /**
     * Function responsible for linking a user account to a company based on the invite link given.
     */
    const createUserWithInviteToken = useCallback(() => {
        const companyName = Cookies.get(INVITE_COMPANY_NAME);
        dispatch(companyInviteLinkingInProgressUpdateAction(true));
        const modalInfo = Modal.info({
            title: getTranslatedText("Linking your account"),
            content: getTranslatedText(`Please wait while we link your account to ${companyName} company`),
            maskClosable: false,
            keyboard: false,
            okText: getTranslatedText('Please wait. . .'),
            okButtonProps: {
                disabled: true,
                loading: true,
            },
        });

        dispatch(
            companyInviteLinkAction(
                Cookies.get(INVITE_TOKEN_NAME),
                (data: CompanyInviteLinkResponse) => {
                    modalInfo.destroy();

                    const messageContent = populateMessages(data.Messages);
                    localStorage.removeItem(INVITE_TOKEN_NAME);
                    Cookies.remove(INVITE_TOKEN_NAME, { path: '/' });
                    Cookies.remove(INVITE_COMPANY_NAME, { path: '/' });

                    if (data.IsSuccess) {
                        Modal.success({
                            icon: <Icon type="check-circle" />,
                            title: getTranslatedText('Success'),
                            content: messageContent,
                            okText: getTranslatedText('Ok'),
                            okButtonProps: {
                                disabled: false,
                                loading: false,
                            },
                        });
                    } else {
                        Modal.error({
                            icon: <Icon type="close-circle" />,
                            title: getTranslatedText('Error'),
                            content: messageContent,
                            okText: getTranslatedText('Close'),
                            okButtonProps: {
                                disabled: false,
                                loading: false,
                            },
                            okType: 'danger',
                        });
                    }

                    dispatch(companyInviteLinkingInProgressUpdateAction(false));
                }
            )
        );
    }, [dispatch]);

    /**
     * Callback function needed by amplify Hub to listen for what action has the user initiated.
     */
    const authListener = useCallback(
        (data: { payload: any }) => {
            switch (data.payload.event) {
                case 'signIn':
                    setShouldFetchAnnouncementsOnLogin('true');
                    setCookieLastActiveCurrent();
                    const inviteToken = Cookies.get(INVITE_TOKEN_NAME);
                    if (inviteToken) {
                        createUserWithInviteToken();
                    }
                    break;

                case 'forgotPassword':
                    const resendCodeAnchor = document.querySelector(
                        'a[data-test="forgot-password-resend-code-link"]'
                    );
                    if (resendCodeAnchor) {
                        resendCodeDone = true;
                    }
                    break;
            }
        },
        [createUserWithInviteToken]
    );

    /**
     * Function for checking the invite token cookie and displays the appropriate text if cookie is found.
     */
    const checkTokenAndShowHelperText = useCallback(() => {
        const inviteToken = Cookies.get(INVITE_TOKEN_NAME);
        if (inviteToken) {
            setShowHelperText(true);
        }
    }, []);

    useEffect(() => {
        if (!listenerSetUpCalled) {
            listenerSetUpCalled = true;
            Hub.listen('auth', authListener);
        }
    }, [authListener]);

    /**
     * Listener function that manipulates the amplify auth dom (to add loading indicator and disabling based on certain conditions).
     */
    const listenerOnLoad = () => {
        if (authContainerRef.current) {
            const allowedAuthStates = ['signIn', 'signUp', 'forgotPassword'];
            if (!includes(allowedAuthStates, props.authState)) return;
            setTimeout(() => {
                const submitBtn = document.querySelector(
                    `span[class^='Section__sectionFooterPrimaryContent___'] > button`
                );

                if (submitBtn) {
                    const toastQuery = 'div[data-test="authenticator-error"]';
                    let checkElemQuery: string | undefined = undefined;
                    let resendCodeAnchor: any = undefined;
                    if (props.authState === 'forgotPassword') {
                        checkElemQuery = `input[name='code']`;
                        const resendCodeCheckingInterval = setInterval(() => {
                            resendCodeAnchor = document.querySelector(
                                'a[data-test="forgot-password-resend-code-link"]'
                            );
                            if (resendCodeAnchor) {
                                clearInterval(resendCodeCheckingInterval);
                                resendCodeAnchor.addEventListener(
                                    'click',
                                    () => {
                                        const toastElemCheckResendClick =
                                            document.querySelector(toastQuery);

                                        if (toastElemCheckResendClick) {
                                            const closeToastElem =
                                                toastElemCheckResendClick.querySelector(
                                                    'a'
                                                );
                                            if (closeToastElem) {
                                                closeToastElem.click();
                                            }
                                        }

                                        commonFunctionAddOrRemoveDisableStatus(
                                            submitBtn,
                                            true,
                                            true
                                        );
                                        commonFunctionAddOrRemoveDisableStatus(
                                            resendCodeAnchor,
                                            true,
                                            false
                                        );
                                        const resendCodeListener = setInterval(
                                            () => {
                                                const toastElemForErrorChecking =
                                                    document.querySelector(
                                                        toastQuery
                                                    );
                                                if (
                                                    resendCodeDone ||
                                                    toastElemForErrorChecking
                                                ) {
                                                    clearInterval(
                                                        resendCodeListener
                                                    );
                                                    resendCodeDone = false;
                                                    commonFunctionAddOrRemoveDisableStatus(
                                                        submitBtn,
                                                        false,
                                                        true
                                                    );
                                                    commonFunctionAddOrRemoveDisableStatus(
                                                        resendCodeAnchor,
                                                        false,
                                                        false
                                                    );
                                                }
                                            },
                                            400
                                        );
                                    }
                                );
                            }
                        }, 300);
                    }

                    submitBtn.addEventListener('click', () => {
                        const toastElem = document.querySelector(toastQuery);
                        if (toastElem) {
                            const closeToastElem = toastElem.querySelector('a');
                            if (closeToastElem) {
                                closeToastElem.click();
                            }
                        }
                        setTimeout(() => {
                            commonFunctionAddOrRemoveDisableStatus(
                                submitBtn,
                                true,
                                true
                            );
                            let signInCreateAccountAnchor: any = undefined;
                            let signUpSignInAnchor: any = undefined;
                            let forgotPassBackToSignInAnchor: any = undefined;
                            if (props.authState === 'signIn') {
                                signInCreateAccountAnchor =
                                    document.querySelector(
                                        'a[data-test="sign-in-create-account-link"]'
                                    );
                                commonFunctionAddOrRemoveDisableStatus(
                                    signInCreateAccountAnchor,
                                    true,
                                    false
                                );
                            } else if (props.authState === 'signUp') {
                                signUpSignInAnchor = document.querySelector(
                                    'a[data-test="sign-up-sign-in-link"]'
                                );
                                commonFunctionAddOrRemoveDisableStatus(
                                    signUpSignInAnchor,
                                    true,
                                    false
                                );
                            } else if (props.authState === 'forgotPassword') {
                                forgotPassBackToSignInAnchor =
                                    document.querySelector(
                                        'a[data-test="forgot-password-back-to-sign-in-link"]'
                                    );
                                commonFunctionAddOrRemoveDisableStatus(
                                    forgotPassBackToSignInAnchor,
                                    true,
                                    false
                                );
                                commonFunctionAddOrRemoveDisableStatus(
                                    resendCodeAnchor,
                                    true,
                                    false
                                );
                            }

                            const toastCheckingInterval = setInterval(() => {
                                const toastElem =
                                    document.querySelector(toastQuery);
                                const checkElem = checkElemQuery
                                    ? document.querySelector(checkElemQuery)
                                    : undefined;
                                if (toastElem || checkElem) {
                                    setTimeout(() => {
                                        clearInterval(toastCheckingInterval);
                                        commonFunctionAddOrRemoveDisableStatus(
                                            submitBtn,
                                            false,
                                            true
                                        );

                                        if (props.authState === 'signIn') {
                                            commonFunctionAddOrRemoveDisableStatus(
                                                signInCreateAccountAnchor,
                                                false,
                                                false
                                            );
                                        } else if (
                                            props.authState === 'signUp'
                                        ) {
                                            commonFunctionAddOrRemoveDisableStatus(
                                                signUpSignInAnchor,
                                                false,
                                                false
                                            );
                                        } else if (
                                            props.authState === 'forgotPassword'
                                        ) {
                                            resendCodeDone = false;
                                            commonFunctionAddOrRemoveDisableStatus(
                                                forgotPassBackToSignInAnchor,
                                                false,
                                                false
                                            );
                                            commonFunctionAddOrRemoveDisableStatus(
                                                resendCodeAnchor,
                                                false,
                                                false
                                            );
                                        }
                                    }, 100);
                                }
                            }, 300);
                        }, 100);
                    });
                }
            }, 400);
        }
    };

    /**
     * Function for manipulating the html element disabled status based on element type.
     */
    const commonFunctionAddOrRemoveDisableStatus = (
        elem: any,
        isAdd: boolean,
        isBtn: boolean
    ) => {
        if (elem) {
            if (isAdd) {
                if (isBtn) {
                    (elem as HTMLButtonElement).disabled = true;
                } else {
                    elem.classList.add('disabled');
                }
                elem.classList.add('spinner');
            } else {
                if (isBtn) {
                    (elem as HTMLButtonElement).disabled = false;
                } else {
                    elem.classList.remove('disabled');
                }
                elem.classList.remove('spinner');
            }
        }
    };

    useLayoutEffect(listenerOnLoad, [authContainerRef, props.authState]);

    useEffect(() => {
        checkTokenAndShowHelperText();
    }, [checkTokenAndShowHelperText]);

    useEffect(() => {
        if (props.authState === 'forgotPassword') {
            if (timeoutHandle) clearInterval(timeoutHandle);
            timeoutHandle = setInterval(() => {
                const codeInput: any =
                    document.querySelector(`input[name='code']`);
                if (codeInput) {
                    if (codeInput.value) codeInput.value = '';
                    const newPassInput: any = document.querySelector(
                        `input[name='password']`
                    );
                    if (newPassInput.value) newPassInput.value = '';
                    clearInterval(timeoutHandle);
                }
            }, 1000);
        } else if (props.authState !== 'loading') {
            if (timeoutHandle) clearInterval(timeoutHandle);
            if (
                !isEmpty(props.authData) &&
                props.authState === 'signedIn' &&
                isAuth !== true
            ) {
                props.loginUser(props.authData);
            } else if (props.authState !== 'signedIn' && isAuth === true) {
                props.logoutUser();
            }
        }
    }, [createUserWithInviteToken, isAuth, props]);


    const initializeSettingsConfig = () => {
        dispatch(getRegionSettingsConfigAction());
        dispatch(getRegionKeyConfigAction());
        dispatch(getDemoOptionsConfigAction());
        dispatch(getIdentityProviderConfigAction());
    };

    useEffect(initializeSettingsConfig, []);

    const setupAmplifyConfig = () => {
        let amplifyConfig: any = undefined;
        if (!isEmpty(regionSettingsConfig) && !isEmpty(regionKeyConfig) && props.authState === 'signIn') {
            const config = getRegionConfigFromList(
                DEFAULT_REGION_NAME,
                regionKeyConfig,
                regionSettingsConfig
            );

            amplifyConfig = generateAmplifyConfig(config);
            Amplify.configure(amplifyConfig);
        }
    };

    useEffect(setupAmplifyConfig, [regionKeyConfig, regionSettingsConfig, identityProviderConfig, props.authState]);

    useEffect(() => {
        const amplifyHostedUI = localStorage.getItem(IODM_LOGIN_WITH_HOSTED_UI) === 'true';
        const isAuthLoading = props.authState === 'loading';
        const invalidLoggedIn = amplifyHostedUI && isAuthLoading;
        if(invalidLoggedIn) {
            const urlParams = new URLSearchParams(window.location.search);
            const hasAuthParams = urlParams.has('code') || urlParams.has('state');

            if (!hasAuthParams) {
                localStorage.removeItem(IODM_LOGIN_WITH_HOSTED_UI);
                window.location.href = currentRoute;
            }
            else {
                setCustomLoading(invalidLoggedIn);
            }
        }
        else {
            setCustomLoading(invalidLoggedIn);
        }
    }, [props.authState]);

    const signInWithOtherProviders = (provider: string) => {
        const amplifyConfig = getFederatedDefaultConfig();
        Amplify.configure(amplifyConfig);
        const oauth = get(amplifyConfig, 'Auth.oauth');
            
        if(oauth && provider) {
            localStorage.setItem(IODM_LOGIN_WITH_HOSTED_UI, "true");
            Auth.federatedSignIn({ customProvider: provider });
        }
    }

    const handleSignInWithOtherProviders = () => {
        if (!providerName.trim()) {
            setSsoLoginErrorMessage(getTranslatedText("Provider is required"));
            return;
        }

        if(!isEmpty(identityProviderConfig) && !isEmpty(providerName)) {
            if ((identityProviderConfig as string[]).includes(providerName)) {
                signInWithOtherProviders(providerName);
            }
            else {
                setSsoLoginErrorMessage('The provider is not exist!');
            }
        }
    }

    const onProviderChange = (value: string) => {
        setProviderName(value);
        if (value){
            setSsoLoginErrorMessage('');
        }
    };

    const renderTranslatedTextWithJSX = (translatedText: string) => {
        // Split by the <b> and </b> tags
        const parts = translatedText.split(/(<b>.*?<\/b>)/).filter(Boolean);

        return parts.map((part, index) => {
            if (part.startsWith('<b>') && part.endsWith('</b>')) {
                // Strip out the <b> and </b> tags and return the content inside as bold
                const content = part.replace(/<\/?b>/g, '');
                return <b key={index}>{content}</b>;
            } else {
                // Return regular text
                return <span key={index}>{part}</span>;
            }
        });
    };

    if (props.authState === 'signedIn') {
        return <Redirect to={currentRoute} />;
    } else {
        const companyName = Cookies.get(INVITE_COMPANY_NAME);
        return (
            <>
                {showHelperText && (
                    <div className="auth-invitation-cont">
                        <Alert
                            message={getTranslatedText(`You've been invited to join ${companyName} company!`)}
                            description={
                                <div>{renderTranslatedTextWithJSX(getTranslatedText('<b>Sign in</b> if you already have an account or click <b>Create Account</b> to create a new one.'))}</div>
                            }
                            type="info"
                            showIcon
                            closable
                            onClose={() => setShowHelperText(false)}
                        />
                    </div>
                )}
                <AppLogo ref={authContainerRef} style={{ marginTop: showHelperText ? 100 : 0 }}
                    handleSignInWithOtherProviders={handleSignInWithOtherProviders}
                    onProviderChange={onProviderChange}
                    ssoLoginErrorMessage={ssoLoginErrorMessage}
                    loading={customLoading}/>
            </>
        );
    }
};

export default withRouter(withAuthHandler(AuthAndLogoContainer));

interface ILogoProps {
    style?: CSSProperties;
    handleSignInWithOtherProviders?: () => void;
    onProviderChange?: (value: string) => void;
    ssoLoginErrorMessage?: string;
    loading?: boolean;
}

const AppLogo = React.forwardRef<HTMLDivElement, ILogoProps>(({
    handleSignInWithOtherProviders, onProviderChange, ssoLoginErrorMessage, loading, ...props
}, ref) => {
    return <div
        ref={ref}
        className="App auth-header-cont"
        {...props}
    >
        <div className="auth-header-body">
            <div>
                <img
                    src={iodmLogo}
                    alt={getTranslatedText("IODM Logo")}
                    style={{ width: '150px', height: 'auto' }}
                />
                <div className="login-brand">{getTranslatedText("Connect")}</div>
                {/* <div className="login-brand">Task Portal</div> */}
                <div className="intro-text-cont">
                    {getTranslatedText("IODM uses digital technology to automate your accounts receivable process and communications.")}
                </div>
                {handleSignInWithOtherProviders && (
                    <div className='mt-8'>
                        <Tooltip 
                            overlayClassName='sso-provider-error-message'
                            title={ssoLoginErrorMessage} 
                            visible={!!ssoLoginErrorMessage}
                            placement='topRight'
                        >
                            <Input hidden={loading}
                                className='sso-provider-input'
                                onChange={(e) => onProviderChange && onProviderChange(e.target.value)}
                                placeholder={getTranslatedText("Enter your provider name")}
                            />
                        </Tooltip>
                        <br/>
                        <Button 
                            loading={loading} 
                            type="primary" 
                            className='sso-provider-button'
                            onClick={handleSignInWithOtherProviders}
                        >
                                SIGN IN USING SSO
                        </Button>
                    </div>
                )}
            </div>
        </div>
    </div>;
});

export {
    AppLogo
}