/**
 * Component for the Sales > Sales Order page. `/app/sales/salesOrders`
 */

import { Button, Col, Drawer, Modal, Row, Select, Spin, Typography } from 'antd';
import {
    capitalize,
    debounce,
    difference,
    every,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    lowerCase,
    map,
    remove,
    toLower,
    uniq,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../components/common/AccountingSystemHandler';
import ActionBar from '../../components/common/ActionBar';
import AddCommentPanel from '../../components/common/AddCommentPanel';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import QuickSearchComponent, {
    qsFilterNames,
} from '../../components/common/QuickSearchComponent';
import VirtualizedList from '../../components/common/VirtualizedList';
import {
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
    SALES_ORDERS_PAGE,
} from '../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import {
    defaultPageSizeForReport,
    salesOrdersSummaryPageQuery,
    salesOrdersSummaryQueryName,
} from '../../constants/downloadToExcel';
import { ApplicationState } from '../../store';
import {
    downloadToExcelAction,
    getCustomFieldsFilterListByTypes,
} from '../../store/common/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../../store/companies/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { getCustomerFirstLetterValuesRequestAction } from '../../store/customers/actions';
import { initialState } from '../../store/salesOrders/reducer';
import { getRolePermissions } from '../../store/roles/sagas';
import { getCurrentUser } from '../../store/users/sagas';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getIfIsLg,
    getSortFieldsWithCustomFields,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    getTranslatedText,
    useCompanyFlagValue
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import CreateScheduledReportDrawer from '../../components/common/CreateScheduledReportDrawer';
import { AtbViewType } from '../../constants/settings';
import { GetSalesOrdersRequestPayload, SalesOrder, SalesOrdersState } from '../../store/salesOrders/types';
import { createSalesOrderManuallyRequestAction, deleteSalesOrderManuallyRequestAction, getSalesOrderCustomFieldsForCompanyRequestAction, getSalesOrderDataRequestAction, getSalesOrdersRequestAction, salesOrderAddCommentRequestAction, setSelectedSalesOrderIdRequestAction, updateSalesOrdersFiltersAction, updateSalesOrdersSortByAndStateAction, updateSalesOrdersTableFilterAction } from '../../store/salesOrders/actions';
import { salesOrdersSortByOptions } from '../../constants/salesOrdersSortAndFilters';
import { salesOrdersFilterBarFilters, salesOrdersStateFilterOptions } from '../../constants/salesOrdersSortAndFilters';
import { getSelectedSalesOrderId } from '../../store/salesOrders/sagas';
import CreateSalesOrderManuallyPanel from '../../components/sales/CreateSalesOrderManuallyPanel';
import SalesOrderItemComponent from '../../components/sales/SalesOrderItemComponent';
import { confirmModalCancelText, confirmModalOkText } from '../../config/config';
import CreateSalesOrderItemManuallyDrawerContentWrapper from '../../components/sales/CreateSalesOrderItemManuallyDrawerContent';

const SalesOrderItemDetailsDrawerComponent = lazy(
    () => import('../../components/sales/SalesOrderItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}
let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchSalesOrdersRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = SALES_ORDERS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.SALES_ORDERS_PAGE;
let initialTableFilter: undefined | string = undefined;
const SalesOrdersManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
}: IProps) => {
    
    const customerLabel = useSelector(getCustomerUILabel);
    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const currentUser = useSelector(getCurrentUser);
    const selectedId = useSelector(getSelectedSalesOrderId);

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    const [customFieldValuesList, setCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [customerValuesOption, setCustomerValuesList] = useState<DynamicObject[]>(
        []
    );

    const [usedRowHeight, setUsedRowHeight] = useState<number>(
        SALES_ORDERS_PAGE.rowHeight
    );

    const rolePermissions = useSelector(getRolePermissions);

    const [salesOrderDetailsDrawer, setSalesOrderDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const salesOrdersState: SalesOrdersState = useSelector(
        (state: ApplicationState) => state.salesOrders
    );

    const [salesOrdersTableFilter, setSalesOrdersTableFilter] = useState<
        string | undefined
    >(
        isEqual(salesOrdersState.filters, initialState.filters)
            ? initialState.tableFilter
            : salesOrdersState.tableFilter
    );


    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const IsSearchCustomFieldsByCheckBox: boolean = get(
        selectedUserCompany,
        'Company.IsSearchCustomFieldsByCheckbox'
    );

    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);

    const [tableFilters, setTableFilters] = useState<any>(
        salesOrdersState.filters
    );

    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
        selectedPausedKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
        selectedPausedKeys: [],
    });

    /**
     * Function that checks if there are filters applied and saved in redux.
     * Returns a boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(salesOrdersState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        downloadToExcel: boolean;
        createScheduledReport: boolean;
        createSalesOrderManuallyDrawer: boolean;
        editSalesOrderManuallyDrawer: boolean; 
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        addCommentDrawer: false,
        allSelected: false,
        downloadToExcel: false,
        createScheduledReport: false,
        createSalesOrderManuallyDrawer: false,
        editSalesOrderManuallyDrawer: false
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        sortAscending: boolean;
        salesOrderState: string;
    }>({
        sortBy: salesOrdersState.sortBy,
        sortAscending: salesOrdersState.sortAscending,
        salesOrderState: salesOrdersState.salesOrderState,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(salesOrdersState, 'pageData.currentPage', 0)
    );

    const [editAllowed, setEditAllowed] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    /**
     * Function to get used sort options
     */
    const getSalesOrdersSortByOptions = () => {
        return getUsedSortByOptionsIfCloud(
            salesOrdersSortByOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getSalesOrdersSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, salesOrdersState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateSalesOrdersSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        salesOrdersState.sortBy,
    ]);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const usingCustomerWorkflow = get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );
        const usingMultipleWorkflow = get(
            selectedUserCompany,
            'Company.UsingMultipleWorkflow'
        );
        const payload: GetSalesOrdersRequestPayload = {
            filters: salesOrdersState.filters,
            sortBy: salesOrdersState.sortBy,
            sortAscending: salesOrdersState.sortAscending,
            pageSize,
            currentPage: currentPage,
            salesOrderState: salesOrdersState.salesOrderState,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for fetching the SalesOrders data.
     * @param currentPage - current page to fetch data from
     * @param pageSize - number of items in page
     */
    const fetchSalesOrders = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;

        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getSalesOrdersRequestAction(payload));
    };

    /**
     * Function called in preparation for fetching the salesOrders data.
     * Manages the page to be called.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            salesOrdersState.loading
        )
            return;

        if (!salesOrdersState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchSalesOrders(nextPage);
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.SALES_ORDER, CUSTOM_FIELD_TYPES.CUSTOMER], // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    /**
     * Function that fetches the custom fields values
     */
    const getCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getSalesOrderCustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response) {
                            if (response.IsSuccess) {
                                customFieldsFilterOptions = await getCustomFieldsOptionsByKey(customFieldsFilterOptions,response.CustomFieldValues);
                                setCustomFieldValuesList(customFieldsFilterOptions)
                            }
                        }
                    }                    
                )
            ); 
        }          
    };   

    /**
     * Function for populating the custom field options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getCustomFieldsOptionsByKey = async (
        customFieldsFilterOptions: DynamicObject[],
        customFieldValuesList: DynamicObject[]
    ) => {
        forEach(
            customFieldsFilterList,
            (customFieldsOption,index) => {
                const customFieldName = get(customFieldsOption, 'FieldName');
                const customFieldLists = customFieldValuesList.filter(x => x.Name == customFieldName && x.Value != null);

                const childCustomFieldValues = map(customFieldLists, (cfl,index) => ({
                    label: cfl.Value,
                    value: cfl.Value,
                }));

                customFieldsFilterOptions.push({
                    Number:index,
                    Type: get(customFieldsOption, 'Type'),
                    FieldName: get(customFieldsOption, 'FieldName'),
                    children: childCustomFieldValues,
                    filterLoading: salesOrdersState.loading
                });
            }
        );

        return customFieldsFilterOptions;
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);
    useEffect(getCustomFieldsValueList, [customFieldsFilterList]);

    /**
     * Listener function for calling the fetch salesOrders function.
     * Can be triggered upon changes on selected user company, filter values, sort values, and salesOrder state filter update.
     */
    const callFetchSalesOrders = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }

            if (!selectedUserCompanyLoading) fetchSalesOrders(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending, salesOrderState } = salesOrdersState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending ||
            salesOrderState !== tableSortState.salesOrderState
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
                salesOrderState,
            });
        }
    };

    useEffect(callFetchSalesOrders, [
        salesOrdersState.sortBy,
        salesOrdersState.sortAscending,
        salesOrdersState.filters,
        salesOrdersState.salesOrderState,
        selectedUserCompany,
    ]);

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = salesOrdersState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchSalesOrdersRequired) {
                fetchSalesOrders(0);
                fetchSalesOrdersRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [salesOrdersState.tableFilter]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Listener function responsible for showing the state of checkboxes (checked/unchecked) upon data update.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(salesOrdersState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [salesOrdersState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function called when salesOrder table filter (upper-left dropdown next to refresh button) is updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeSalesOrdersTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== salesOrdersState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setSalesOrdersTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === salesOrdersState.tableFilter) {
            handleSalesOrderFilterRefresh();
        } else {
            dispatch(updateSalesOrdersTableFilterAction(filter));
        }
    };

    /**
     * Function called when the refresh button is clicked.
     */
    const handleSalesOrderFilterRefresh = (
        showConditionsObject?: DynamicObject
    ) => {
        fetchSalesOrdersRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys(showConditionsObject);
        refetchListAndResetScroll();
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchSalesOrders(0);
    };

    /**
     * Function for getting the selected salesOrders values.
     */
    const getSelectedSalesOrdersValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }

        const filterObject = {
            ...tableFilters,
            salesOrderState: tableSortState.salesOrderState,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function for populating the dropdown render of the table filter (upper-left).
     * @param menu - menu items component
     */
    const populateSalesOrderFilterSelectDropdownRender = (menu: any) => (
        <div>
            {menu}
        </div>
    );

    /**
     * Function for populating the `Select/Deselect all` button label.
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = salesOrdersState.pageData.hasNextPage === false;

        let selectDeselectLabel = '';
        if (
            !isEmpty(salesOrdersState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    salesOrdersState.data.length ===
                        tableRowSelection.selectedRowKeys.length))
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function triggered when the select/deselect all button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length <
                salesOrdersState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(salesOrdersState.data, selectedKeysName);

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });

        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedPausedKeys: [],
            });
        }

        setEditAllowed(false);
    };

    /**
     * Function called when the sort order button link is clicked (inside the ellipsis popover).
     * @param sortAscending - boolean indicator if sort order is ascending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateSalesOrdersSortByAndStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled =
        salesOrdersState.loading || isEmpty(salesOrdersState.data);

    /**
     * Function for populating the upper section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText('Filter')}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            salesOrdersState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText('Change sort order')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => downloadToExcelHandler()}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Download to Excel')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={createScheduledReportHandler}
                // disabled={downloadDisabled}
            >
                    <FontAwesome
                        icon={['far', 'calendar']}
                        className={`icon calendar`}
                    />
                    <FontAwesome
                        icon={['fas', 'clock']}
                        className={`icon clock`}
                    />
                <span className='icon-button-wrapper'>{getTranslatedText('Create Scheduled Report')}</span>
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = (specializedReportType?: string) => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            salesOrderState,
            sortBy,
            sortAscending,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true,
        );

        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const variables = {
            ...cleanFilters,
            SalesOrderState: salesOrderState,
            ...sortObject,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            UsingCustomerWorkflow: usingCustomerWorkflow,
        };

        const payload = {
            Query: salesOrdersSummaryPageQuery,
            OperationName: salesOrdersSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.SALES_ORDERS_PAGE,
            SpecializedReport: specializedReportType
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

      /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
      const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            salesOrderState,
            sortBy,
            sortAscending,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice'
        );

        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const variables = {
            ...cleanFilters,
            SalesOrderState: salesOrderState,
            ...sortObject,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            UsingCustomerWorkflow: usingCustomerWorkflow,
        };

        const payload = {
            Query: salesOrdersSummaryPageQuery,
            OperationName: salesOrdersSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.SALES_ORDERS_PAGE,
            title: "Sales Orders",
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the lower section of ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => (
        <div className="pop-action-content">
            <div className="mb-10">
                <span>{getTranslatedText('Sort by')}</span>
            </div>
            <div>
                <Select
                    onChange={(sortBySelected: string) =>
                        updateTableSortStateObject({
                            sortBy: sortBySelected,
                        })
                    }
                    value={tableSortState.sortBy}
                >
                    {map(
                        getSalesOrdersSortByOptions(),
                        ({
                            label,
                            value,
                        }: {
                            label: string;
                            value: string;
                        }) => {
                            const labelUsed = label
                                .replace('Customer', capitalize(customerLabel))
                                .replace('customer', toLower(customerLabel));
                            return (
                                <Option key={value} value={value}>
                                    {getTranslatedText(labelUsed)}
                                </Option>
                            );
                        }
                    )}
                    {map(
                        customFieldsFilterList,
                        ({
                            Type,
                            Number: CFNumber,
                            FieldName,
                        }: CompanyCustomFieldConfigure) => (
                            <Option
                                key={Type + CFNumber + FieldName}
                                value={`${customFieldIndicator}${Type}--${FieldName}`}
                            >
                                {getTranslatedText(FieldName)}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="mb-10">
                <span>{getTranslatedText('State of sales orders')}</span>
            </div>
            <div>
                <Select
                    onChange={(stateSelected: string) =>
                        updateTableSortStateObject({
                            salesOrderState: stateSelected,
                        })
                    }
                    value={tableSortState.salesOrderState}
                >
                    {map(
                        salesOrdersStateFilterOptions,
                        ({ label, value }: any) => (
                            <Option key={value} value={value}>
                                {getTranslatedText(label)}
                            </Option>
                        )
                    )}
                </Select>
            </div>
            <br />
            <div className="ta-right">
                <Button
                    type="primary"
                    disabled={
                        salesOrdersState.loading ||
                        (tableSortState.sortBy === salesOrdersState.sortBy &&
                            tableSortState.salesOrderState ===
                                salesOrdersState.salesOrderState)
                    }
                    onClick={applySortedByAndState}
                >
                    {getTranslatedText('Apply')}
                </Button>
            </div>
        </div>
    );

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (salesOrdersState.tableFilter !== initialTableFilter) {
            setSalesOrdersTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeSalesOrdersTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called when applying the filters.
     * Set the filters in state and redux.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     * @param fromQuickSearch - boolean indicator if called from the quick search component
     */
    const applyFilters = async (
        filters?: GetSalesOrdersRequestPayload['filters'],
        fromFilterBar?: boolean,
        fromQuickSearch?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    salesOrdersTableFilter,
                    fromFilterBar,
                    () => {
                        changeSalesOrdersTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateSalesOrdersFiltersAction(appliedFilters));
        const showConditionsObject = fromQuickSearch ? { filterBar: true } : {};
        await resetAllSelectedRowKeys(showConditionsObject);
    };

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updateSalesOrdersSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param showConditionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function called to close the salesOrder details drawer.
     * Clears the data saved during drawer close.
     */
    const closeSalesOrderDetailsDrawer = () => {
        setSalesOrderDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Function to update the `tableSortState` state.
     * @param tableSortStateObject - must conform to `tableSortState`
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when clicking on checkbox.
     * @param record - record for the row where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked.
     * @param record - data where the row is clicked
     * @param activeTab - kind of panel to show based on the row clicked
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setSelectedSalesOrderIdRequestAction(get(record, 'Id'), () => {
                setSalesOrderDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function for populating the table loading text.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } sales orders`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected (left side checkbox is ticked).
     * @param record - data for the selected row
     * @param selected - boolean indicator if the row is selected/unselected
     * @param selectedRows - selected row keys
     * @param nativeEvent - event fired when the row is selected
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        const selectedPausedKeys = [...tableRowSelection.selectedPausedKeys];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );

            if (record.IsBlocked) {
                selectedPausedKeys.push(record.key);
            }
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];

            if (record.IsBlocked) {
                remove(
                    selectedPausedKeys,
                    (pausedKey) => pausedKey === record.key
                );
            }
        }

        let isEditAllowed = false;
        if (selectedRowKeys.length === 1) {
             const salesOrderItem: DynamicObject = salesOrdersState.data.filter(item => item.Id === selectedRowKeys[0]);
            if (salesOrderItem[0].State === "Open") {
                isEditAllowed = true;
            }
        }

        setEditAllowed(isEditAllowed);

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
            selectedPausedKeys,
        });
    };

     /**
         * Function using for get selected credit
         */
        const getSelectedSalesOrder = () => {
            let salesOrder = undefined;
    
            if (tableRowSelection.selectedRowKeys.length === 1) {
                salesOrder = salesOrdersState.data.find(item => item.Id === tableRowSelection.selectedRowKeys[0]) as SalesOrder;
            }
    
            return salesOrder;
        };

    /**
     * Function to control the visibility of popovers.
     * @param name - name of popover to change the visibility
     * @param condition - boolean indicator to apply the condition
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'addCommentDrawer'
                ) {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the table scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all the selected/unselected row keys.
     */
    const resetAllSelectedRowKeys = (
        showConditionsObject: DynamicObject = {}
    ) => {
        const usedConditions = {
            ...showConditionsObject,
            allSelected: false,
        };
        updateShowConditionsObject(usedConditions);

        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
            selectedPausedKeys: [],
        });

    };

    /**
     * Function for closing the filter bar section.
     * Resets the applied filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

     /**
     * Function called when panel is closed.
     * @param panelType - type of panel to be closed. Must be the same with showConditions state props
     */
     const closePanel = (panelType: string) => {
        return (refreshList?: boolean) => {
            updateShowConditionsObject({
                [panelType]: false,
            });

            if (refreshList) {
                refetchListAndResetScroll();
            }
        };
    };

    /**
     * Function called to close the add comment panel
     * @param refreshList
     */
    const closeAddCommentPanel = (refreshList?: boolean) => {
        updateShowConditionsObject({
            addCommentDrawer: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };


    /**
     * Function for populating the payload needed for actions like add comment / pause/resume.
     */
    const getSalesOrdersFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedSalesOrdersValues();

        const payload: DynamicObject = {
            filter: filterObject,
            invoiceIds: keysToUse,
            excludeInvoices: allExcept,
        };

        return payload;
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        const isLg = getIfIsLg();
        const rowHeight = isLg
            ? SALES_ORDERS_PAGE.rowHeight + 20
            : SALES_ORDERS_PAGE.rowHeight;
        setUsedRowHeight(rowHeight);
    };

    /**
     * Common function for applying the quick search filters
     * @param filters
     * @param fromFilterBar
     */
    const applyQuickSearchFilters = (
        filters?: any,
        fromFilterBar?: boolean
    ) => {
        applyFilters(filters, fromFilterBar, true);
    };

    /**
     * Common function for populating the quick search common filter section
     */
    const populateQSCommonFilterOptions = () => {
        const filterOptions = [
            {
                label: 'Sales order number',
                value: qsFilterNames.INVOICE,
            },
            {
                label: capitalize(customerLabel),
                value: qsFilterNames.CUSTOMER,
            },
        ];

        return filterOptions;
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    const usingCustomerWorkflow = get(
        selectedUserCompany,
        'Company.UsingCustomerWorkflow'
    );

    /**
    * Function that fetches the customer first letter values
    */
    const getCustomerFirstLatterValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ) => {
        forEach(
            valuesList,
            (value, index) => {
                options.push({
                    label: value,
                    value: value
                });
            }
        );
        return options;
    };
    const getCustomerFirstLetterValuesList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let options: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerFirstLetterValuesRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            options = await getCustomerFirstLatterValuesOptions(options, response.Values);
                            setCustomerValuesList(options)
                        }
                    }
                )
            );
        }
    };
    useEffect(getCustomerFirstLetterValuesList, [selectedUserCompany]);


    const deleteAllowed = (() => {
        const { allExcept } = getSelectedSalesOrdersValues();
        if (allExcept) {
            return true;
        }
        const salesOrders = filter(salesOrdersState.data, c => includes(tableRowSelection.selectedRowKeys, c.Id));
        return !isEmpty(salesOrders) && every(salesOrders, c => get(c, 'State') !== 'Settled');;
    })();

    const onDeleteClick = () => {
            Modal.confirm({
                className: 'modal-swapped-buttons',
                title: getTranslatedText('Confirm'),
                content: getTranslatedText('Are you sure you want to delete the selected sales orders?'),
                okText: getTranslatedText(confirmModalOkText),
                cancelText: getTranslatedText(confirmModalCancelText),
                onOk: () => {
                    setDeleteLoading(true);
                    const payload = getSalesOrdersFilterPayload();
                    dispatch(deleteSalesOrderManuallyRequestAction({
                        ...payload,
                        filter: payload.filter || {},
                        invoiceIds: payload.invoiceIds || [],
                        excludeInvoices: payload.excludeInvoices || false, 
                        callback: deleteSalesOrderManuallyResponseModal,

                    }));
                }
            });
        };

    const deleteSalesOrderManuallyResponseModal = ({
            IsSuccess,
            Messages
        }: ResponseModalObject) => {
            setDeleteLoading(false);
            if (IsSuccess) {
                Modal.success({
                    title: getTranslatedText('Success'),
                    content: getTranslatedText('Sales orders successfully deleted!'),
                    onOk: () => {
                        refetchListAndResetScroll();
                    },
                    okText: getTranslatedText("OK")
                });
            } else {
                let errorMessageContent: any = getTranslatedText('Failed to delete sales orders!');
                if (!isEmpty(Messages)) {
                    errorMessageContent = map(
                        Messages,
                        (error: string, index: number) => (
                            <div key={index}>{getTranslatedText(error)}</div>
                        )
                    );
                }
                Modal.error({
                    title: getTranslatedText('Error'),
                    content: errorMessageContent,
                    okText: getTranslatedText("OK")
                });
            }
        };

    const populateDeleteSalesOrdersModalTitle = () => {
        const { keysToUse } = getSelectedSalesOrdersValues();

        return `Deleting sales order${keysToUse.length > 1 ? 's' : ''}`;
    };

    const populateDeleteSalesOrdersModalDisplayMessage = () => {
        const { keysToUse } = getSelectedSalesOrdersValues();

        return `Please wait while deleting the sales order${keysToUse.length > 1 ? 's' : ''} . . .`;
    };

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>{getTranslatedText('Sales Orders')}</Title>
                    </Col>
                    <Col className="ta-right">
                        <QuickSearchComponent
                            loading={salesOrdersState.loading}
                            customFieldsFilterList={customFieldsFilterList}
                            applyFilters={applyQuickSearchFilters}
                            filterOptions={populateQSCommonFilterOptions().map(option => ({
                                ...option,
                                label: getTranslatedText(option.label)
                            }))}
                            selectedUserCompany={selectedUserCompany}
                        />
                    </Col>
                </Row>
                <div className="spacer-5" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={salesOrdersState.loading}
                                    applyFilters={applyFilters}
                                    filters={salesOrdersFilterBarFilters(
                                        customerValuesOption,
                                        capitalize(customerLabel) + ' A-Z',
                                        isCalendarView
                                    )}
                                    filterValues={salesOrdersState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        salesOrderDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={salesOrdersTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={IsSearchCustomFieldsByCheckBox ? customFieldValuesList : customFieldsFilterList}
                                    tableSortState={{
                                        sortBy: salesOrdersState.sortBy,
                                        sortAscending:
                                            salesOrdersState.sortAscending,
                                        salesOrderState:
                                            salesOrdersState.salesOrderState,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={salesOrdersState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'sales-order-filter',
                                actionType: 'select-with-button',
                                selectValue: salesOrdersTableFilter,
                                selectDropdownRender:
                                    populateSalesOrderFilterSelectDropdownRender,
                                onSelectChange: changeSalesOrdersTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: salesOrdersState.loading,
                                onButtonClick: handleSalesOrderFilterRefresh,
                            },
                            {
                                actionKey: 'sales-order-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {getTranslatedText(populateSelectDeselectAllLabel())}
                                        </span>
                                    </>
                                ),
                            },
                            {
                                actionKey: 'sales-order-add-comment',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.addCommentDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'addCommentDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText('Add a comment'),
                                popoverContent: (
                                    <AddCommentPanel
                                        visible={
                                            showConditions.addCommentDrawer
                                        }
                                        closePanel={closeAddCommentPanel}
                                        filterPayload={getSalesOrdersFilterPayload()}
                                        dispatchAction={
                                            salesOrderAddCommentRequestAction
                                        }
                                    />
                                ),
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'comment']}
                                        />
                                        <span>{getTranslatedText('Add comment')}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'create-sales-order-manually',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.CREATE_CREDIT_MANUALLY,
                                popoverVisible:
                                    showConditions.createSalesOrderManuallyDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'createSalesOrderManuallyDrawer'
                                ),
                                popoverTitle: getTranslatedText('Create sales orders'),
                                popoverContent: (
                                    <div>
                                        <CreateSalesOrderManuallyPanel
                                            visible={
                                                showConditions.createSalesOrderManuallyDrawer
                                            }
                                            closePanel={closePanel(
                                                'createSalesOrderManuallyDrawer'
                                            )}
                                            dispatchAction={
                                                createSalesOrderManuallyRequestAction
                                            }
                                        />
                                    </div>
                                ),
                                buttonDisabled: salesOrdersState.loading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText('Create sales orders')}</span>
                                    </>
                                ),
                                drawerWidth: 700,
                            },
                            {
                                actionKey: 'edit-sales-order-manually',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.EDIT_CREDIT_MANUALLY,
                                popoverVisible:
                                    showConditions.editSalesOrderManuallyDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'editSalesOrderManuallyDrawer'
                                ),
                                popoverTitle: getTranslatedText('Edit sales orders'),
                                popoverContent: (
                                    <div>
                                        <CreateSalesOrderItemManuallyDrawerContentWrapper
                                            visible={showConditions.editSalesOrderManuallyDrawer}
                                            isEditMode={true}
                                            isEditViaManamentPage={true}
                                            closePanel={closePanel('editSalesOrderManuallyDrawer')}
                                            editSalesOrder={getSelectedSalesOrder()}
                                        />
                                    </div>
                                ),
                                buttonDisabled: !editAllowed,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'edit']}
                                        />
                                        <span>{getTranslatedText('Edit sales orders')}</span>
                                    </>
                                ),
                                drawerWidth: 700,
                            },
                            {
                                actionKey: 'delete-sales-order-manually',
                                actionType: 'protected-button',
                                allowedRoles: rolePermissions.DELETE_SALES_ORDER_MANUALLY,
                                buttonDisabled: !deleteAllowed,
                                onButtonClick: onDeleteClick,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'trash']}
                                        />
                                        <span>{getTranslatedText('Delete')}</span>
                                    </>
                                )
                            }
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={salesOrdersState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                usedRowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={salesOrdersState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(salesOrdersState.errorMessages)
                                    ? get(salesOrdersState, 'errorMessages.0')
                                    : 'No sales orders found'
                            }
                            hasNextPage={salesOrdersState.pageData.hasNextPage}
                            itemComponent={SalesOrderItemComponent}
                            itemHeight={usedRowHeight}
                            selectedId={selectedId}
                            usingCustomerWorkflow={usingCustomerWorkflow}
                            customerLabel={customerLabel}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                            }}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <SalesOrderItemDetailsDrawerComponent 
                            visible={salesOrderDetailsDrawer.visible}
                            activeTab={salesOrderDetailsDrawer.activeTab}
                            closeDrawer={closeSalesOrderDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            getSalesOrderDataRequestAction={getSalesOrderDataRequestAction}
                        />
                    </Suspense>
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                    <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
                    {deleteLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDeleteSalesOrdersModalTitle())}
                                modalVisible={deleteLoading}
                                displayMessage={getTranslatedText(populateDeleteSalesOrdersModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(SalesOrdersManagementPage)
);
