/**
 * File responsible for the content when clicking `Allocate to invoice` in SalesOrders management pages.
 */

import { Button, Col, Form, Modal, Row, Table } from 'antd';
import { isEmpty, map, filter, first, findIndex, capitalize } from 'lodash';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, roundNumberToDecimalDigits, getTranslatedText } from '../../utils/commonFunctions';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { SalesOrder, RequestCreateSalesOrderManuallyPayload } from '../../store/salesOrders/types';
import FontAwesome from '../common/FontAwesome';
import CreateSalesOrderItemManuallyPanelWrapper from './CreateSalesOrderItemManuallyPanel';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { Payment } from '../../store/payments/types';


const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));
export interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly dispatchAction: (requestCreateSalesOrderManuallyPayload: RequestCreateSalesOrderManuallyPayload) => void;
    readonly formatCurrency?: (amount: number) => string;
    readonly formatToParts?: (amount: number) => Intl.NumberFormatPart[];
    readonly onCreateSuccess?: (SalesOrderCodes: Partial<SalesOrder>[]) => void;
    readonly disableCustomer?: boolean;
    readonly payment?: Payment;
}

const CreateSalesOrderManuallyPanel: React.FC<IProps> = ({
    containerRef,
    visible,
    closePanel,
    form,
    dispatchAction,
    formatCurrency,
    formatToParts,
    onCreateSuccess,
    disableCustomer,
    payment,
}: IProps) => {
    const dispatch = useDispatch();
    
    const customerLabel = useSelector(getCustomerUILabel);
    const [isEditMode, setisEditMode] = useState<boolean>(false);
    const [salesOrderList, setSalesOrderList] = useState<Partial<SalesOrder>[]>([]);
    const [salesOrder, setsalesOrder] = useState<Partial<SalesOrder>>({});
    const [isCreateSalesOrderItemManuallyPanelVisible, setIsCreateSalesOrderItemManuallyPanelVisible] = useState<boolean>(false);

    const salesOrdersTableColumns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            width: '20%'
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            width: '27%'
        },
        {
            title: `${capitalize(customerLabel)}`,
            dataIndex: 'Customer',
            width: '20%'
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            width: '25%'
        }
    ];

    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const {
        validateFields,
        resetFields,
    } = form;

    const isCreateBtnDisabled = submitLoading || salesOrderList.length === 0;

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
    * Function for calling when the CreateSalesOrderItemManuallyPanel is closed.
    * @param refreshList
    */
    const onCreateSalesOrderItemManuallyPanelClose = (isSalesOrderCreatedSuccessfully?: boolean) => {
        setIsCreateSalesOrderItemManuallyPanelVisible(false);
        setisEditMode(false);

        if (isSalesOrderCreatedSuccessfully) {
            if (closePanel) {
                closePanel(true);
            };
        }
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                const SalesOrders: Partial<SalesOrder>[] = salesOrderList.map(salesOrder => {
                    return {
                        Type: salesOrder.Type,
                        InvoiceCode: salesOrder.InvoiceCode,
                        Description: salesOrder.Description,
                        Customer: salesOrder.Customer,
                        OriginalAmount: salesOrder.OriginalAmount,
                    }
                });

                const requestCreateSalesOrderManuallyPayload: RequestCreateSalesOrderManuallyPayload = {
                    SalesOrders: SalesOrders,
                    callback: createSalesOrderResponseModal
                }

                dispatch(
                    dispatchAction(requestCreateSalesOrderManuallyPayload)
                );
            }
        });
    };

    /**
     * Function responsible for showing the response modal after salesOrder created.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createSalesOrderResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content:
                    getTranslatedText('Sales orders created successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK'),
            });
            if (onCreateSuccess) {
                onCreateSuccess(salesOrderList);
            }
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to create Sales orders!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK'),
            });
        }
    };

    /**
     * Function called for formatting an amount if formatCurrency HOC function exists.
     * @param amount - number for format
     */
    const handleFormatCurrency = (amount: number) => {
        return {
            amountDisplay: formatCurrency ? formatCurrency(amount) : amount,
            currency: first(map(
                filter(
                    (formatToParts ? formatToParts(amount) : []), p => p.type === 'currency'
                ),
                p => p.value
            ))
        };
    };

    const populateSalesOrderTableDataSourceItem = (salesOrder: SalesOrder, index: number) => {
        const { currency } = handleFormatCurrency(salesOrder.OriginalAmount);

        return {
            key: index,
            Name: salesOrder.InvoiceCode,
            Description: salesOrder.Description,
            Customer: salesOrder.Customer.DisplayName,
            Amount: (<>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} className="mr-5">
                        {`${currency + ' ' + roundNumberToDecimalDigits(salesOrder.OriginalAmount)}`}
                    </div>{' '}
                    <div className="fs-16 cursor-p-div mr-5" onClick={handleEditSalesOrder(salesOrder)}>
                        <FontAwesome icon={['fas', 'edit']} />
                    </div>{' '}
                    <div className="fs-16 cursor-p-div" onClick={handleDeleteSalesOrder(salesOrder)}>
                        <FontAwesome icon={['fas', 'trash']} />
                    </div>
                </div>
            </>),
        }
    };

    const handleAddSalesOrder = () => {
        setIsCreateSalesOrderItemManuallyPanelVisible(true);
    };

    const handleAddSalesOrderSuccessfully = (salesOrder: Partial<SalesOrder>) => {
        setSalesOrderList(list => {
            return [...list, salesOrder];
        });
    };

    const handleEditSalesOrder = (salesOrder: SalesOrder) => () => {
        setisEditMode(true);
        setsalesOrder(salesOrder);
        setIsCreateSalesOrderItemManuallyPanelVisible(true);
    };

    const handleEditSalesOrderSuccessfully = (salesOrder: Partial<SalesOrder>) => {
        setisEditMode(false);

        const SalesOrderIdx = findIndex(salesOrderList, cre => cre.Id === salesOrder.Id);

        if (SalesOrderIdx !== -1) {
            const SalesOrders: Partial<SalesOrder>[] = [...salesOrderList];
            SalesOrders.splice(SalesOrderIdx, 1, salesOrder);

            setSalesOrderList(SalesOrders);
        }
    };

    const handleDeleteSalesOrder = (salesOrder: SalesOrder) => () => {
        const SalesOrderIdx = findIndex(salesOrderList, cre => cre === salesOrder);

        if (SalesOrderIdx !== -1) {
            const SalesOrders: Partial<SalesOrder>[] = [...salesOrderList];
            SalesOrders.splice(SalesOrderIdx, 1);

            setSalesOrderList(SalesOrders);
        }
    };

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row gutter={[16, 16]} type="flex" align="middle">
                    <Col span={24}>
                        <Table
                            className="app-SalesOrders-table"
                            columns={salesOrdersTableColumns.map(column => ({
                                ...column,
                                title: getTranslatedText(column.title)
                            }))}
                            dataSource={map(salesOrderList, populateSalesOrderTableDataSourceItem)}
                            pagination={false}
                            bordered={true}
                            locale={{
                                emptyText: getTranslatedText('No Data'),
                            }}
                        />
                    </Col>
                    <Col span={24} className="ta-right">
                        <Button
                            type="link"
                            onClick={handleAddSalesOrder}
                            style={{ padding: 0 }}
                        >
                            <FontAwesome
                                icon={['fa', 'plus']}
                                className="mr-8"
                            />
                            <span>{getTranslatedText('Add sales order')}</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isCreateBtnDisabled}
                        >
                            {getTranslatedText('Create')}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Creating new sales order")}
                        modalVisible={submitLoading}
                        displayMessage = {
                            getTranslatedText("Please wait while creating new sales order . . .")}
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
            <Suspense fallback={null}>
                <div ref={null}>
                    <CreateSalesOrderItemManuallyPanelWrapper
                        visible={isCreateSalesOrderItemManuallyPanelVisible}
                        isEditMode={isEditMode}
                        editSalesOrder={salesOrder}
                        closePanel={onCreateSalesOrderItemManuallyPanelClose}
                        handleAddSalesOrder={ handleAddSalesOrderSuccessfully }
                        handleEditSalesOrder={ handleEditSalesOrderSuccessfully }
                        existingSalesOrderList={ salesOrderList }
                        disableCustomer={disableCustomer}
                        payment={payment}
                    />
                </div>
            </Suspense>
        </Row>
    );
};


const CreateSalesOrderManuallyPanelForm = Form.create({
    name: 'create-sales-order-manually-panel-form',
})(CreateSalesOrderManuallyPanel);

export default withRouter(
    withNumberFormatHandler(
        withDateFormatHandler(CreateSalesOrderManuallyPanelForm)
    )
)