import {
    commonSalesOrderListSortOptions,
    salesOrdersSortByOptions,
} from '../../constants/salesOrdersSortAndFilters';
import {
    AddCommentCommonFilterPayload,
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import {
    Attachment,
    ConversationLine,
    ConversationState,
    CustomField,
    PageData,
} from '../common/types';
import { Customer } from '../customers/types';
import { Company } from '../companies/types';
import { Task } from '../tasks/types';

export enum SalesOrdersActionTypes {
    GET_SALES_ORDERS_REQUEST = '@@salesOrders/GET_SALES_ORDERS_REQUEST',
    GET_SALES_ORDERS_FOR_ORGANISATION_REQUEST = '@@salesOrders/GET_SALES_ORDERS_FOR_ORGANISATION_REQUEST',
    GET_SALES_ORDERS_SUCCESS = '@@salesOrders/GET_SALES_ORDERS_SUCCESS',
    GET_SALES_ORDERS_ERROR = '@@salesOrders/GET_SALES_ORDERS_ERROR',
    UPDATE_SALES_ORDERS_FILTERS = '@@salesOrders/UPDATE_SALES_ORDERS_FILTERS',
    UPDATE_SALES_ORDERS_SORT_BY_AND_STATE = '@@salesOrders/UPDATE_SALES_ORDERS_SORT_BY_AND_STATE',
    UPDATE_SALES_ORDERS_TABLE_FILTER_STATE = '@@salesOrders/UPDATE_SALES_ORDERS_TABLE_FILTER_STATE',
    CLEAR_SALES_ORDERS_STATE_ALL_TABLE_FILTERS = '@@salesOrders/CLEAR_SALES_ORDERS_STATE_ALL_TABLE_FILTERS',
    CLEAR_SALES_ORDERS_STATE_DATA = '@@salesOrders/CLEAR_SALES_ORDERS_STATE_DATA',
    CLEAR_SALES_ORDERS_LIST_DATA = '@@salesOrders/CLEAR_SALES_ORDERS_LIST_DATA',
    GET_SALES_ORDER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST = '@@salesOrders/GET_SALES_ORDER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST',

    SET_SALES_ORDER_SELECTED_ID_REQUEST = '@@salesOrders/SET_SALES_ORDER_SELECTED_ID_REQUEST',
    SET_SALES_ORDER_SELECTED_ID_SUCCESS = '@@salesOrders/SET_SALES_ORDER_SELECTED_ID_SUCCESS',
    GET_SALES_ORDER_DATA_REQUEST = '@@salesOrders/GET_SALES_ORDER_DATA_REQUEST',
    GET_ORGANISATION_SALES_ORDER_DATA_REQUEST = '@@salesOrders/GET_ORGANISATION_SALES_ORDER_DATA_REQUEST',
    GET_SALES_ORDER_DATA_SUCCESS = '@@salesOrders/GET_SALES_ORDER_DATA_SUCCESS',
    GET_SALES_ORDER_DATA_ERROR = '@@salesOrders/GET_SALES_ORDER_DATA_ERROR',
    CLEAR_SALES_ORDER_DATA_SUCCESS = '@@salesOrders/CLEAR_SALES_ORDER_DATA_SUCCESS',

    // Conversation
    GET_SALES_ORDER_CONVERSATION_REQUEST = '@@salesOrders/GET_SALES_ORDER_CONVERSATION_REQUEST',
    GET_ORGANISATION_SALES_ORDER_CONVERSATION_REQUEST = '@@salesOrders/GET_ORGANISATION_SALES_ORDER_CONVERSATION_REQUEST',
    GET_SALES_ORDER_CONVERSATION_SUCCESS = '@@salesOrders/GET_SALES_ORDER_CONVERSATION_SUCCESS',
    GET_SALES_ORDER_CONVERSATION_ERROR = '@@salesOrders/GET_SALES_ORDER_CONVERSATION_ERROR',
    UPDATE_SALES_ORDER_CONVERSATION_FILTERS = '@@salesOrders/UPDATE_SALES_ORDER_CONVERSATION_FILTERS',
    UPDATE_SALES_ORDER_CONVERSATION_TABLE_FILTER = '@@salesOrders/UPDATE_SALES_ORDER_CONVERSATION_TABLE_FILTER',
    UPDATE_SALES_ORDER_CONVERSATION_SORT_BY = '@@salesOrders/UPDATE_SALES_ORDER_CONVERSATION_SORT_BY',

    // Changes
    GET_SALES_ORDER_CHANGES_REQUEST = '@@salesOrders/GET_SALES_ORDER_CHANGES_REQUEST',
    GET_ORGANISATION_SALES_ORDER_CHANGES_REQUEST = '@@salesOrders/GET_ORGANISATION_SALES_ORDER_CHANGES_REQUEST',
    GET_SALES_ORDER_CHANGES_SUCCESS = '@@salesOrders/GET_SALES_ORDER_CHANGES_SUCCESS',
    GET_SALES_ORDER_CHANGES_ERROR = '@@salesOrders/GET_SALES_ORDER_CHANGES_ERROR',
    UPDATE_SALES_ORDER_CHANGES_FILTERS = '@@salesOrders/UPDATE_SALES_ORDER_CHANGES_FILTERS',
    UPDATE_SALES_ORDER_CHANGES_TABLE_FILTER = '@@salesOrders/UPDATE_SALES_ORDER_CHANGES_TABLE_FILTER',
    UPDATE_SALES_ORDER_CHANGES_SORT_BY = '@@salesOrders/UPDATE_SALES_ORDER_CHANGES_SORT_BY',

    SALES_ORDER_ADD_COMMENT_REQUEST = '@@salesOrders/SALES_ORDER_ADD_COMMENT_REQUEST',
    ORGANISATION_SALES_ORDER_ADD_COMMENT_REQUEST = '@@salesOrders/ORGANISATION_SALES_ORDER_ADD_COMMENT_REQUEST',

    SALES_ORDER_SET_TABLE_COMPACT_VIEW = '@@salesOrders/SALES_ORDER_SET_TABLE_COMPACT_VIEW',

    CREATE_SALES_ORDER_MANUALLY_REQUEST = '@@salesOrders/CREATE_SALES_ORDER_MANUALLY_REQUEST',
    EDIT_SALES_ORDER_MANUALLY_REQUEST = '@@salesOrders/EDIT_SALES_ORDER_MANUALLY_REQUEST', 
    DELETE_SALES_ORDER_MANUALLY_REQUEST = '@@salesOrders/DELETE_SALES_ORDER_MANUALLY_REQUEST',

    GET_SALES_ORDER_BY_SALES_ORDER_CODES_REQUEST = '@@salesOrders/GET_SALES_ORDER_BY_SALES_ORDER_CODES_REQUEST',
    GET_SALES_ORDER_BY_SALES_ORDER_CODES_SUCCESS = '@@salesOrders/GET_SALES_ORDER_BY_SALES_ORDER_CODES_SUCCESS',
    GET_SALES_ORDER_BY_SALES_ORDER_CODES_ERROR = '@@salesOrders/GET_SALES_ORDER_BY_SALES_ORDER_CODES_ERROR'
}

export interface SalesOrdersState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: SalesOrder[];
    readonly pageData: PageData;
    readonly filters: GetSalesOrdersRequestPayload['filters'];
    readonly sortBy: GetSalesOrdersRequestPayload['sortBy'];
    readonly sortAscending: boolean;
    readonly salesOrderState: string;
    readonly tableFilter: string | undefined;
    readonly activeData: {
        loading: boolean;
        record: {};
        salesOrderChanges: SalesOrderChangesState;
        conversation: ConversationState;
        selectedId: string | null;
        errorMessages: string[];
    };
    readonly compactView: boolean;
}

export interface DeliveryDetails {
    Type: string;
    State: string;
    Comment: string;
    Reason: string;
}
export interface SalesOrder {
    readonly Id: string;
    readonly CompanyId: string;
    readonly InvoiceCode: string;
    readonly Number: string;
    readonly CreatedDate: string;
    readonly LocalCreatedDate: string;
    readonly DueDate: string;
    readonly LocalDueDate: string;
    readonly OriginalAmount: number;
    readonly AmountOwing: number;
    readonly State: string;
    readonly SettledDate: string;
    readonly LocalSettledDate: string;
    readonly Type: string;
    readonly CreatedDateTime: string;
    readonly UpdateDateTime: string;
    readonly Customer: Customer;
    readonly ConversationLine: ConversationLine;
    readonly CustomFields: CustomField[];
    readonly Attachments: Attachment[];
    readonly LastCommunication: LastCommunication;
    readonly CurrentTask: Task;
    readonly IsBlocked: boolean;
    readonly Company: Company;
    readonly GlCode: string;
    readonly PromiseToPayDate: string;
    readonly LocalPromiseToPayDate: string;
    readonly Description: string;
}

export interface DeliveryDetails {
    Type: string;
    State: string;
    Comment: string;
    Reason: string;
}

export interface GetSalesOrdersRequestPayload {
    filters: {
        InvoiceNumber: string;
        SalesOrderNumber: string;
        Customer: string;
        CustomerCode: string;
        CustomerId?: string;
        CreatedDateMin: string;
        CreatedDateMax: string;
        DueDateMin: string;
        DueDateMax: string;
        Amount?: DynamicObject;
        AmountType: string;
        AmountValue: string;
        AmountOperator: string;
        InvoiceIds?: string[];
        CompanyIds?: string[];
    };
    excludeInvoices?: boolean;
    salesOrderState: string;
    sortBy: typeof salesOrdersSortByOptions[number]['value'];
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    isUsingCloudImportType?: boolean;
    usingMultipleWorkflow?: boolean;
    usingCustomerWorkflow?: boolean;
    isPaymentPlanEnabled?: boolean;
    region?: string;
    conversionCurrency?: number;
    organisationCurrencies?: SalesOrderOrganisationCurrency[]
    callback?: (response: any) => void;
}

export interface GetCommonSalesOrderListSalesOrdersRequestPayload {
    filters: {};
    sortBy: typeof commonSalesOrderListSortOptions[number]['value'];
    sortAscending: boolean;
    salesOrderstate: string;
    pageSize: number;
    currentPage: number;
}

export interface SalesOrderAddCommentRequestPayload
    extends AddCommentCommonFilterPayload {
    invoiceIds: string[] | number[];
    excludeInvoices: boolean;
}

export interface GetSalesOrderConversationRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    conversationTableFilter: string;
}

export interface LastCommunication {
    WorkflowName: string;
    StateName: string;
    SentDateTime: string;
    DeliveryDetails: DeliveryDetails[];
}

export interface GetSalesOrderChangesRequestPayload {
    filters: {};
    sortBy: string;
    sortAscending: boolean;
    pageSize: number;
    currentPage: number;
    salesOrderChangesTableFilter: string;
}

export interface SalesOrderChangesState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: SalesOrder[];
    readonly pageData: PageData;
    readonly filters: GetSalesOrderChangesRequestPayload['filters'];
    readonly salesOrderChangesTableFilter: string;
    readonly sortBy: GetSalesOrderChangesRequestPayload['sortBy'];
    readonly sortAscending: boolean;
}

export interface SalesOrderOrganisationCurrency {
    Value: string
    Rate: number
}

export interface SalesOrderChanges {}


export interface RequestCreateSalesOrderManuallyPayload {
    SalesOrders: Partial<SalesOrder>[];
    callback?: (response: any) => void;
}

export interface RequestEditSalesOrderManuallyPayload {
    SalesOrders: Partial<SalesOrder>;
    callback?: (response: any) => void;
}

export interface DeleteSalesOrderManuallyRequestPayload {
    filter: {};
    invoiceIds: string[] | number[];
    excludeInvoices: boolean;
    callback?: (response: ResponseModalObject) => void;
}
