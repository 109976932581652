/**
 * File responsible for the content when clicking `Allocate to invoice` in sales orders management pages.
 */

import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { get, capitalize, isEmpty, map } from 'lodash';
import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPopoverContainer, getTranslatedText } from '../../utils/commonFunctions';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { getCustomerUILabel } from '../../store/customers/sagas';
import InputAutoCompleteWithButton from '../common/InputAutoCompleteWithButton';
import TextArea from 'antd/lib/input/TextArea';
import { DynamicObject } from '../../utils/commonInterfaces';
import { SalesOrder, RequestEditSalesOrderManuallyPayload } from '../../store/salesOrders/types';
import { Customer } from '../../store/customers/types';
import { v4 as uuidv4 } from 'uuid';
import { Payment } from '../../store/payments/types';
import CurrencyInput from '../common/CurrencyInput';
import { editSalesOrderManuallyRequestAction } from '../../store/salesOrders/actions';


const formFieldNames = {
    Name: 'Name',
    Description: 'Description',
    CustomerCode: 'CustomerCode',
    Amount: 'Amount',
};

const ModalWithSpinner = lazy(() => import('../common/ModalWithSpinner'));

const { Item: FormItem } = Form;
interface ICombinedProps extends IProps {
    valueChanges?: any;
    setValueChanges: (valueChanges?: any) => void;
}

interface IProps {
    readonly containerRef?: any;
    readonly visible: boolean;
    readonly isEditViaManamentPage?: boolean;
    readonly isEditMode: boolean;
    readonly editSalesOrder?: Partial<SalesOrder>;
    readonly existingSalesOrderList?: Partial<SalesOrder>[];
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly handleAddSalesOrder?: (salesOrderVM: Partial<SalesOrder>) => void;
    readonly handleEditSalesOrder?: (salesOrderVM: Partial<SalesOrder>) => void;
    readonly disableCustomer?: boolean;
    readonly payment?: Payment;
}

const CreateSalesOrderItemManuallyDrawerContent: React.FC<ICombinedProps> = ({
    containerRef,
    visible,
    isEditViaManamentPage,
    isEditMode,
    editSalesOrder,
    existingSalesOrderList,
    closePanel,
    form,
    handleAddSalesOrder,
    handleEditSalesOrder,
    valueChanges,
    setValueChanges,
    disableCustomer,
    payment,
}: ICombinedProps) => {
    
    const [hasError, setHasError] = useState<boolean>(true);
    const [customerCode, setCustomerCode] = useState<string | undefined>('');
    const [customerSearch, setCustomerSearch] = useState<string>('');
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
        setFieldsValue
    } = form;

    const dispatch = useDispatch();

    const customerLabel = useSelector(getCustomerUILabel);

    const isCreateBtnDisabled = hasError;
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [warningMessage, setWarningMessage] = useState<string | null>(null);

    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    /**
     * Function that listens if panel is closed.
     * If closed, the form fields and values will reset.
     */
    const listenForClosingPanel = () => {
        if (!visible) {
            resetFields();
        }
        else {
            setFieldsValue({
                [formFieldNames.CustomerCode]: undefined
            });
            setCustomerCode(undefined);
            setCustomerSearch('');
        }
    };

    useEffect(listenForClosingPanel, [visible]);

    useEffect(() => {
        if (payment && visible) {
            setFieldsValue({
                [formFieldNames.CustomerCode]: get(payment, 'Customer.CustomerCode')
            });

            setValueChanges({
                [formFieldNames.CustomerCode]: true
            });

            updateCustomerCode(get(payment, 'Customer.CustomerCode'));
            setCustomerSearch(get(payment, 'Customer.DisplayName'));
        }
    }, [visible]);

    /**
     * Function called when submitting the form.
     */
    const handleSubmitForm = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const salesOrder: Partial<SalesOrder> = {
                    Id: isEditMode && editSalesOrder ? editSalesOrder.Id : uuidv4(),
                    InvoiceCode: getFieldValue(formFieldNames.Name),
                    Description: getFieldValue(formFieldNames.Description),
                    OriginalAmount: getFieldValue(formFieldNames.Amount),
                    Customer: {
                        CustomerCode: (customerCode || ''),
                        DisplayName: customerSearch
                    } as Customer
                };
                console.log("from drawer", salesOrder);
            

                if (isEditViaManamentPage) {
                    handleSaveSalesOrder(salesOrder);
                } else {
                    if (isEditMode && handleEditSalesOrder) {
                        handleEditSalesOrder(salesOrder);
                    } else if (handleAddSalesOrder) {
                        handleAddSalesOrder(salesOrder);
                    }

                    handleClosePanel();
                }
            }
        });
    };

    /**
     * Function called when user submitting the form from the salesOrder manament page.
     */
    const handleSaveSalesOrder = (salesOrder: Partial<SalesOrder>) => {
        validateFields((err: any, values: any) => {
            if (!err) {
                setSubmitLoading(true);

                const requestCreateSalesOrderManuallyPayload: RequestEditSalesOrderManuallyPayload = {
                    SalesOrders: salesOrder,
                    callback: createSalesOrderResponseModal
                };

                dispatch(editSalesOrderManuallyRequestAction(requestCreateSalesOrderManuallyPayload));
            }
        });
    };

    /**
     * Function responsible for showing the response modal after salesOrder created.
     * @param param0 - object with success indicator and error message from api (if there's any)
     */
    const createSalesOrderResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content:
                    getTranslatedText('Sales order edited successfully!'),
                onOk: () => {
                    if (closePanel) closePanel(true);
                },
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to edit sales order!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{getTranslatedText(error)}</div>
                    )
                );
            }

            Modal.error({
                title: getTranslatedText('Error'),
                content: errorMessageContent,
                getContainer: () => getPopoverContainer(containerRef),
                okText: getTranslatedText('OK')
            });
        }
    };


    const updateCustomerCode = useMemo(() => (value: React.SetStateAction<string | undefined>) => {
        setFieldsValue({
            [formFieldNames.CustomerCode]: value
        });

        setCustomerCode(value);

        setValueChanges({
            [formFieldNames.CustomerCode]: true
        });
    }, [setValueChanges]);

    const validateForm = useMemo(() => (options?: {
        success?: (values: any) => void
    }) => {
        validateFields({ suppressWarning: true }, (err: any, values: DynamicObject) => {
            if (!err) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        });
    }, [validateFields]);

    useEffect(() => {
        validateForm();
    }, [valueChanges, validateForm, visible]);

    useEffect(() => {
        if (isEditMode) {
            updateCustomerCode(get(editSalesOrder, 'Customer.CustomerCode'));
            setCustomerSearch(get(editSalesOrder, 'Customer.DisplayName'));
            
            
            setFieldsValue({
                [formFieldNames.Name]: get(editSalesOrder, 'InvoiceCode'),
                [formFieldNames.Description]: get(editSalesOrder, 'Description'),
            });
        }
    }, [isEditMode, editSalesOrder]);

    useEffect(() => {
        if (isEditMode) {
            setFieldsValue({
                [formFieldNames.Amount]: get(editSalesOrder, 'OriginalAmount'),
            })
        }
    }, [isEditMode]);

    /**
     * Function called when user update the salesOrder name.
     */
    const isDuplicatedSalesOrderCode = (salesOrderCode: string) => {        
        if (salesOrderCode && existingSalesOrderList) {
            if (isEditMode) {
                return existingSalesOrderList.some(c => editSalesOrder && c.Id !== editSalesOrder.Id && c.Number === salesOrderCode);
            }
            else {
                return existingSalesOrderList.some(c => c.Number === salesOrderCode);
            }
        }

        return false;
    };

    /**
    * Function that is called to validate the amount paid form field.
    * @param _rule
    * @param value
    */
    const checkAmount = async (_rule: any, value: any) => {
        const usedValue =
            value || getFieldValue(formFieldNames.Amount);

        if (!usedValue || !(usedValue > 0)) {
            throw new Error('Amount required!');
        }
    };

    /**
     * Common function for populating currency input box
     * @param key
     */
    const populateNumberFormatElement = (key: string) => (
        <CurrencyInput
            onChange={(floatValue: undefined | number) => {
                setFieldsValue({ [key]: floatValue });
            }}
            currentValue={getFieldValue(key)}
        />
    );

    /**
     * Function responsible for populating the panel content.
     * Form fields.
     */
    const populatePanelContent = () => {
        return (
            <Form labelAlign='left' className="form-inline-mb-0" labelCol={{ span: 8 }}>
                <Row type="flex" align="middle">
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Name")}>
                            {getFieldDecorator(formFieldNames.Name, {
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Name required!'),
                                    },
                                    {
                                        validator: async (_rule: any, value: any) => {
                                            const salesOrderCode = value || getFieldValue(formFieldNames.Name);
                                            if(isDuplicatedSalesOrderCode(salesOrderCode)) {
                                                throw new Error(getTranslatedText(`Duplicated Sales Order Name!`));
                                            }    
                                            if (salesOrderCode && salesOrderCode.length < 3) {
                                                setWarningMessage(getTranslatedText("Name should be atleast 3 characters!"));
                                            } else {
                                                setWarningMessage(null);
                                            }                                
                                        }
                                    }
                                ]
                            })(<Input />)}
                            {warningMessage && <div style={{ color: 'orange', textAlign:"right", padding:0}}>{warningMessage}</div>}
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Description")}>
                            {getFieldDecorator(formFieldNames.Description)
                                (<TextArea rows={4} />)}
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={getTranslatedText(capitalize(customerLabel))}>
                            {getFieldDecorator(formFieldNames.CustomerCode, {
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText(`${capitalize(customerLabel)} required!`),
                                        
                                    },
                                ],
                            })(
                                <Input hidden />
                            )}<InputAutoCompleteWithButton
                                updateField={(value: any) => {
                                    if (!value && customerCode) {
                                        updateCustomerCode(undefined);
                                    }
                                    setCustomerSearch(value);
                                }}
                                onSelect={(value: any) => {
                                    const CustomerCode = get(value, 'CustomerCode');
                                    updateCustomerCode(CustomerCode);
                                }}
                                minimumLength={1}
                                stateValue={customerSearch}
                                placeholder={getTranslatedText("Search customer")}
                                keyField={'Id'}
                                queryName={'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER'}
                                filterField={'Customer'}
                                queryFilterName={'Customer'}
                                sortField={'Company name'}
                                responseName={'GetCustomersForCompany.Customers'}
                                labelField={'DisplayName'}
                                hasNoOkButton
                                loading={false}
                                readOnly={disableCustomer}
                            />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Amount")} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            {getFieldDecorator(formFieldNames.Amount, {
                                initialValue: 0.0,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Amount required!'),
                                        validator: checkAmount,
                                    }
                                ]
                            })(
                                populateNumberFormatElement(
                                    formFieldNames.Amount
                                )
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Row>
            <Col>
                <div>{populatePanelContent()}</div>
                <br />
                <Row>
                    <Col className="ta-right" span={24}>
                        <Button
                            className="mr-8"
                            type="primary"
                            onClick={handleSubmitForm}
                            disabled={isCreateBtnDisabled}
                        >
                            {getTranslatedText(`${isEditMode ? 'Save' : 'Add'}`)}
                        </Button>
                        <Button onClick={handleClosePanel}>{getTranslatedText('Cancel')}</Button>
                    </Col>
                </Row>
            </Col>
            {submitLoading && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Editing sales order")}   
                        modalVisible={submitLoading}
                        displayMessage={getTranslatedText("Please wait while Editing sales order . . .")} 
                        containerRef={containerRef}
                    />
                </Suspense>
            )}
        </Row>
    );
};

const CreateSalesOrderItemManuallyDrawerContentWrapper: React.FC<IProps> = (props) => {
    const [valueChanges, setValueChanges] = useState<any>();

    const InnerForm = useMemo(() => {
        const CreateSalesOrderItemManuallyDrawerContentForm = Form.create({
            name: 'create-sales-order-manually-drawer-content-form',
            onValuesChange(props, changedValues, allValues) {
                setValueChanges(changedValues);
            },
        })(CreateSalesOrderItemManuallyDrawerContent);

        return withRouter(
            withNumberFormatHandler(
                withDateFormatHandler(CreateSalesOrderItemManuallyDrawerContentForm)
            )
        );
    }, []);

    return <InnerForm {...props}
        valueChanges={valueChanges}
        setValueChanges={setValueChanges} />
};

export default CreateSalesOrderItemManuallyDrawerContentWrapper;