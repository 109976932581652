/**
 * Component drawer for Action manual allocate payment panel.
 */

import { Drawer } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { populatePopoverContainer, getTranslatedText } from '../../../utils/commonFunctions';
import TaskActionManuallyRenameOverpaymentsPanel from './TaskActionManuallyRenameOverpaymentsPanel';


interface IProps {
    visible: boolean;
    containerRef?: any;
    closePanel?: (refetchList?: boolean) => void;
    goBack?: () => void;
    panelTitle?: string;
    getSelectedTasksValues: any;
    selectedTaskIds: any;
}

const TaskActionManuallyRenameOverpaymentsDrawer: React.FC<IProps> = ({
    visible,
    containerRef,
    closePanel,
    goBack,
    panelTitle,
    getSelectedTasksValues,
    selectedTaskIds
}: IProps) => {
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };


    return (
        <Drawer
            getContainer={populatePopoverContainer(containerRef)}
            width={500}
            visible={visible}
            onClose={handleClosePanel}
            title={getTranslatedText('Create Overpayment as credit')}
            maskClosable={false}
            closable={false}
            bodyStyle={{ overflowX: 'hidden' }}
        >
            {visible && <TaskActionManuallyRenameOverpaymentsPanel
                containerRef={containerRef}
                visible={visible}
                closePanel={closePanel}
                goBack={goBack}
                getSelectedTasksValues={getSelectedTasksValues}
                selectedTaskIds={selectedTaskIds}
            />}
        </Drawer>
    );
};

export default TaskActionManuallyRenameOverpaymentsDrawer;
