/**
 * Component drawer for Add comment panel.
 */

import { Drawer } from 'antd';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCustomerUILabel } from '../../store/customers/sagas';
import { capitalize } from 'lodash';
import { getTranslatedText } from '../../utils/commonFunctions';
import SupportTicketActionComponent from './SupportTicketActionComponent';

interface IProps {
    visible: boolean;
    onClose: () => void;
    readonly selectedTicketTaskId: undefined | string;
    readonly getSelectedTasksValues: any;
    readonly customerLabel?: any;
}

const SupportTicketActionDrawer: React.FC<IProps> = ({
    visible,
    onClose,
    selectedTicketTaskId,
    getSelectedTasksValues,
    customerLabel
}: IProps) => {
    const drawerRef: any = useRef(null);
    return (
        <Drawer
            visible={visible}
            width={500}
            onClose={onClose}
            zIndex={1000}
            title={getTranslatedText(`Action ${capitalize(customerLabel)} assistance ticket`)}
            maskClosable={false}
            closable={false}
        >
            <div id="drawer-container" ref={drawerRef}>
                <SupportTicketActionComponent
                    visible={visible}
                    closePanel={onClose}
                    selectedTicketTaskId={selectedTicketTaskId}
                    getSelectedTasksValues={getSelectedTasksValues}
                    customerLabel={customerLabel}
                    containerRef={drawerRef}
                />
            </div>
        </Drawer>
    );
};

export default SupportTicketActionDrawer;
