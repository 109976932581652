import moment from "moment-timezone";
import { dateSelectOptions, invoicesAmountOperatorOptions, invoicesAmountType } from "./invoicesSortAndFilters";

export const salesOrdersSortValues = {
    SALES_ORDER_NUMBER: 'Sales order number',
    CUSTOMER_CODE: 'Customer code',
    COMPANY_NAME: 'Company name',
    CREATED_DATE: 'Sales order created date',
    DUE_DATE: 'Sales order due date',
    AMOUNT_OWING: 'Amount owing',
};

export const salesOrdersSortByOptions = [
    { label: 'Sales order number', value: salesOrdersSortValues.SALES_ORDER_NUMBER },
    { label: 'Customer code', value: salesOrdersSortValues.CUSTOMER_CODE },
    { label: 'Company name', value: salesOrdersSortValues.COMPANY_NAME },
    { label: 'Sales order created date', value: salesOrdersSortValues.CREATED_DATE },
    { label: 'Sales order due date', value: salesOrdersSortValues.DUE_DATE },
    { label: 'Amount owing', value: salesOrdersSortValues.AMOUNT_OWING },
];

export const salesOrdersStateFilterOptions = [
    { label: 'All open sales orders', value: 'Open' },
    { label: 'All sales orders', value: 'All' },
];

export const salesOrdersChangesFilters = {
    ALL_CHANGES: 'All changes',
};

export const salesOrdersChangesTableFilterOptions = [
    {
        label: 'All changes',
        value: salesOrdersChangesFilters.ALL_CHANGES,
    },
];

export const preConfiguredFilters = {
    ALL_SALES_ORDERS: 'All sales orders',
};

export const salesOrdersTableFilterOptions = [
    // { label: 'All open invoices', value: 'Open' },
    { label: 'All sales orders', value: preConfiguredFilters.ALL_SALES_ORDERS },
];

const {
    THIS_MONTH,
    NEXT_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
    CREATE_DATE_ATB_CURRENT,
    CREATE_DATE_ATB_30_DAYS,
    CREATE_DATE_ATB_60_DAYS,
    CREATE_DATE_ATB_90_PLUS_DAYS,
    CREATE_DATE_ATB_1_MONTH,
    CREATE_DATE_ATB_2_MONTHS,
    CREATE_DATE_ATB_3_PLUS_MONTHS,
    DUE_DATE_ATB_NOT_DUE,
    DUE_DATE_ATB_CURRENT,
    DUE_DATE_ATB_30_DAYS,
    DUE_DATE_ATB_60_DAYS,
    DUE_DATE_ATB_90_PLUS_DAYS,
    DUE_DATE_ATB_1_MONTH,
    DUE_DATE_ATB_2_MONTHS,
    DUE_DATE_ATB_3_PLUS_MONTHS,
    UNDEFINED
} = dateSelectOptions;

export const salesOrderDueInOptions = (isCalendarView: boolean) => [
    THIS_MONTH,
    NEXT_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    DUE_DATE_ATB_NOT_DUE,
    DUE_DATE_ATB_CURRENT,
    isCalendarView ? DUE_DATE_ATB_1_MONTH : DUE_DATE_ATB_30_DAYS,
    isCalendarView ? DUE_DATE_ATB_2_MONTHS : DUE_DATE_ATB_60_DAYS,
    isCalendarView ? DUE_DATE_ATB_3_PLUS_MONTHS : DUE_DATE_ATB_90_PLUS_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];


export const salesOrdersFilterBarFilters = (customerValuesOption?: any, customerLabel?: string, isCalendarView?: boolean) => {
    const filters = [
        {
            filterName: 'Sales order number',
            filterStateName: 'SalesOrderNumber',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_SALES_ORDERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: salesOrdersSortValues.SALES_ORDER_NUMBER,
            filterResponse: 'GetSalesOrdersForCompany.SalesOrders',
            filterLabelField: 'Number',
        },
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Created in',
            filterStateName: 'CreatedDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: salesOrderDueInOptions(!!isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'last-days-range',
            },
            // offset: 1,
            // span: 5,
        },
        {
            filterName: 'Due in',
            filterStateName: 'DueDate',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: salesOrderDueInOptions(!!isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range',
            },
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: invoicesAmountType,
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                },
            ],
            // offset: 1,
        },
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerCountry',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
        },
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'CustomerState',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
        },
    ];
    
    if (customerValuesOption && customerLabel) {
        filters.splice(2, 0, {
            filterName: customerLabel,
            filterStateName: 'CustomerFirstLetter',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: customerValuesOption
        });
    }

    return filters;
};

export const commonSalesOrderListSortOptions = [
    {
        label: 'Due date',
        value: salesOrdersSortValues.DUE_DATE,
    },
    {
        label: 'Amount',
        value: salesOrdersSortValues.AMOUNT_OWING,
    },
    {
        label: 'Sales order number',
        value: salesOrdersSortValues.SALES_ORDER_NUMBER,
    }
];

// for sales order changes 
export const salesOrderChangesFilters = {
    ALL_CHANGES: 'All changes',
};

export const salesOrderChangeTypes = {
    NewResource: 'NewResource',
    UpdatedResource: 'UpdatedResource',
};

export const selectSalesOrderSortOrderOption = [
    {
        label: 'Sales order number',
        value: salesOrdersSortValues.SALES_ORDER_NUMBER,
    },
    {
        label: 'Due date',
        value: salesOrdersSortValues.DUE_DATE,
    },
    {
        label: 'Sales order amount',
        value: salesOrdersSortValues.AMOUNT_OWING,
    },
    {
        label: 'Customer Code',
        value: salesOrdersSortValues.CUSTOMER_CODE,
    }
];