/**
 * Component for populating the Tasks > Active tasks page. `/app/tasks/active-tasks`
 */

import { Button, Col, Modal, Row, Select, Typography, InputNumber, Popover } from 'antd';
import {
    capitalize,
    debounce,
    difference,
    every,
    filter,
    find,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    omit,
    remove,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../../components/common/AccountingSystemHandler';
import ActionBar from '../../../components/common/ActionBar';
import AddCommentPanel from '../../../components/common/AddCommentPanel';
import { withDateFormatHandler } from '../../../components/common/DateFormatHandler';
import { customFieldIndicator } from '../../../components/common/FilterBar';
import FontAwesome from '../../../components/common/FontAwesome';
import { withPageViewHandler } from '../../../components/common/PageViewHandler';

import QuickSearchComponent, {
    qsFilterNames,
} from '../../../components/common/QuickSearchComponent';
import VirtualizedList from '../../../components/common/VirtualizedList';
import TaskAssignToDrawerContent from '../../../components/tasks/active_tasks/TaskAssignToDrawerContent';
import TaskEditDrawerContent from '../../../components/tasks/active_tasks/TaskEditDrawerContent';
import TaskItemComponent from '../../../components/tasks/active_tasks/TaskItemComponent';
import {
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
    TASKS_PAGE,
} from '../../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../../constants/authUserAttributes';
import { MAX_INT_VALUE, selectUserFilterMapping } from '../../../constants/common';
import {
    activeTasksSummaryPageQuery,
    activeTasksSummaryQueryName,
    defaultPageSizeForReport,
} from '../../../constants/downloadToExcel';
import {
    invoicesAmountOperatorOptions,
    invoicesDueInOptions,
    invoicesSortValues,
} from '../../../constants/invoicesSortAndFilters';
import {
    newInvoiceEmailTaskType,
    paymentPlanTaskTypes,
    paymentAllocationTaskType,
    taskHistoryAutomation,
    taskHistoryBatchTypes,
    tasksAmountType,
    tasksReadyStateOptions,
    tasksSortByOptions,
    tasksStatusFilterOptions,
    tasksTypeOptions,
    ticketActionsOptions,
    paymentBehaviorInsightsType
} from '../../../constants/tasksSortAndFilters';
import '../../../less/compact.less';
import { ApplicationState } from '../../../store';
import {
    downloadToExcelAction,
    getCustomFieldsFilterListByTypes,
} from '../../../store/common/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
    WorkflowOption,
} from '../../../store/companies/types';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import { getCustomerFirstLetterValuesRequestAction } from '../../../store/customers/actions';
import { getRolePermissions } from '../../../store/roles/sagas';
import {
    addTaskViewingsRequestAction,
    bulkSaveTaskDetailsUpdateRequestAction,
    checkTasksReadyStateAction,
    deleteTaskViewingsRequestAction,
    getTableCompactViewRequestAction,
    getTasksActionFilterOptionsRequestAction,
    getTasksRequestAction,
    setSelectedTaskIdRequestAction,
    setTableCompactViewRequestAction,
    setTaskExpandStatusAction,
    taskAddCommentRequestAction,
    updateTasksFiltersAction,
    updateTasksSortByAndStatusAction,
    updateTasksTableFilterAction,
    getTaskViewerDetailForUserRequestAction,
    getTaskCustomerCustomFieldsForCompanyRequestAction,
    getTaskInvoiceCustomFieldsForCompanyRequestAction,
    getTaskDataRequestAction
} from '../../../store/tasks/actions';
import { initialState } from '../../../store/tasks/reducer';
import {
    getSelectedTaskId,
    populateSortFieldsParamSortParams,
} from '../../../store/tasks/sagas';
import {
    GetTaskViewerDetailForUserRequestPayload,
    ChangeTaskViewingRequestPayload,
    GetTasksRequestPayload,
    Task,
    TasksState
} from '../../../store/tasks/types';
import { getCurrentUser } from '../../../store/users/sagas';
import { UserPreference } from '../../../store/users/types';
import {
    checkIfEmailIsValid,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getIfIsLg,
    getUsedSortByOptionsIfCloud,
    insertAt,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    replaceInstancesOfCustomerString,
    getTranslatedText,
    useCompanyFlagValue
} from '../../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../../utils/commonInterfaces';
import TaskActionDrawer from '../../../components/tasks/active_tasks/TaskActionDrawer';
import { AtbViewType } from '../../../constants/settings';
import CreateScheduledReportDrawer from '../../../components/common/CreateScheduledReportDrawer';
import { getTicketOptionsRequestAction } from '../../../store/notifications/actions';


const TaskItemDetailsDrawerComponent = lazy(
    () =>
        import(
            '../../../components/tasks/active_tasks/TaskItemDetailsDrawerComponent'
        )
);
const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

const GET_TASK_VIEWER_INTERVAL = 5000;
const ADD_TASK_VIEWING_INTERVAL = 10000;

interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
    readonly customerLabel: string;

}
let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let fetchTasksRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = TASKS_PAGE.pageSize;
let isRefetching = false;
const pageName = PAGE_NAMES_FOR_VIEW.TASKS_PAGE;
let initialTableFilter: undefined | string = undefined;

const ActiveTasksManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);

    const dispatch = useDispatch();

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const currentUser = useSelector(getCurrentUser);
    const rolePermissions = useSelector(getRolePermissions);

    const [usedRowHeight, setUsedRowHeight] = useState<number>(
        TASKS_PAGE.compactRowHeight
    );

    const toggledIds: string[] = useSelector(
        (state: ApplicationState) =>
            state.tasks.activeTasks.toggledExpandStatusIds
    );

    const userPreference: UserPreference = useSelector(
        (state: ApplicationState) => state.users.userPreference
    );

    const [filterItemList, setFilterItemList] = useState<DynamicObject[]>([]);
    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    const [customFieldValuesList, setCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [customerCustomFieldValuesList, setCustomerCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [invoiceCustomFieldValuesList, setInvoiceCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [customerValuesOption, setCustomerValuesList] = useState<DynamicObject[]>(
        []
    );

    const [ticketReasons, setTicketReasons] = useState<DynamicObject[]>(
        []
    );

    const [taskDetailsDrawer, setTaskDetailsDrawer] = useState<{
        visible: boolean;
        drawerType: string;
        drawerAutomation: string;
    }>({
        visible: false,
        drawerType: taskHistoryBatchTypes.Communication,
        drawerAutomation: taskHistoryAutomation.Automatic,
    });

    const [getTaskViewerIntervalId, setGetTaskViewerIntervalId] = useState<number | undefined>();
    const [addTaskViewingIntervalId, setAddTaskViewingIntervalId] = useState<number | undefined>();

    const tasksState: TasksState['activeTasks'] = useSelector(
        (state: ApplicationState) => state.tasks.activeTasks
    );
    const selectedId = get(tasksState, 'activeData.selectedId');

    const [tasksTableFilter, setTasksTableFilter] = useState<
        string | undefined
    >(
        isEqual(tasksState.filters, initialState.activeTasks.filters)
            ? initialState.activeTasks.taskTableFilter
            : tasksState.taskTableFilter
    );

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    /**
     * Function for getting the company Id of the currently selected user company.
     */
    const companyId = useSelector((state: ApplicationState) =>
        get(selectedUserCompany, 'Company.CompanyId')
    );

    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);

    const usingMultipleWorkflow: boolean = get(
        selectedUserCompany,
        'Company.UsingMultipleWorkflow'
    );

    const usingCustomerWorkflow: boolean = get(
        selectedUserCompany,
        'Company.UsingCustomerWorkflow'
    );

    const IsSearchCustomFieldsByCheckBox: boolean = get(
        selectedUserCompany,
        'Company.IsSearchCustomFieldsByCheckbox'
    );

    const supportPaymentBehaviourInsights = get(
        selectedUserCompany,
        'Company.EnablePaymentBehaviourInsights'
    );

    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const workflowFetchLoading: boolean = useSelector(
        (state: ApplicationState) => state.companies.workflowFetchLoading
    );

    const workflowNames: WorkflowOption[] = useSelector(
        (state: ApplicationState) => state.companies.workflowOptions
    );

    const [tableFilters, setTableFilters] = useState<any>(tasksState.filters);


    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
        selectedUnreadyKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
        selectedUnreadyKeys: [],
    });

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        actionButtonDisabled: boolean;
        actionButtonLoading: boolean;
        actionButtonSelectedType: undefined | string;
        actionButtonSelectedAutomation: undefined | string;
        actionButtonSelectedTicketTaskId: undefined | string;
        editButtonLoading: boolean;
        assignToButtonLoading: boolean;
        totalTaskCount: number;
    }>({
        actionButtonDisabled: true,
        actionButtonLoading: false,
        actionButtonSelectedType: undefined,
        actionButtonSelectedAutomation: undefined,
        actionButtonSelectedTicketTaskId: undefined,
        editButtonLoading: false,
        assignToButtonLoading: false,
        totalTaskCount: 0,
    });

    let isActionAllowed = false;
    if (
        isEmpty(rolePermissions.TASK_ACTION_SEND_NOTIFICATION) ||
        includes(
            rolePermissions.TASK_ACTION_SEND_NOTIFICATION,
            selectedUserCompany.Role.Name
        )
    ) {
        isActionAllowed = true;
    }

    let isActionButtonLoading = isActionAllowed
        ? actionBarItemsState.actionButtonLoading
        : false;

    /**
    * Function called to get the ticket reasons needed for the dropdown inside the drawer.
    */
    const updateTicketReasons = (ticketReasonsObject: any[]) => {
        setTicketReasons(
            ticketReasonsObject
        );
    };

    /**
      * Function for setting the ticket reasons values and display value
      */
    const getTicketReasons = async () => {
        try {
            const reasons: DynamicObject[] = [];
            await dispatch(
                getTicketOptionsRequestAction(
                    companyId,
                    async (res: any) => {
                        if (res) {
                            // Call getTicketOptionReasonValuesOptions here
                            const valuesList: DynamicObject[] = []; // Initialize or fetch an existing values list
                            const updatedReasons = await getTicketOptionReasonValuesOptions(res, valuesList);

                            updateTicketReasons(updatedReasons);
                        }
                    }
                )
            );
        } catch (error) {
            console.error("Error fetching ticket reasons:", error);
        }
    };

    const getTicketOptionReasonValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ): Promise<DynamicObject[]> => {
        try {
            // Filter and map the options
            const filteredOptions = options.filter((option) => !option.Hidden);
            const mappedOptions = filteredOptions.map((ticketReason: DynamicObject) => ({
                label: ticketReason.Reason,
                value: ticketReason.Reason,
            }));

            // Combine the values list with the new mapped options
            return [...valuesList, ...mappedOptions];
        } catch (error) {
            console.error("Error processing ticket reason values:", error);
            return valuesList; // Return the original list in case of error
        }
    };

    useEffect(() => {
        if (selectedUserCompany) {
            getTicketReasons();
        }
    }, [selectedUserCompany]);

    /**
     * Function to get used sort options
     */
    const getTaskUsedSortOptions = () => {
        let usedSortByOptions = getUsedSortByOptionsIfCloud(
            tasksSortByOptions,
            isUsingCloudImportType
        );
        if (usingCustomerWorkflow) {
            usedSortByOptions = filter(
                usedSortByOptions,
                (option) =>
                    option.value !== 'AmountOwing' && option.value !== 'Number'
            );
        }

        return usedSortByOptions;
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getTaskUsedSortOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, tasksState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortStatus,
                    sortBy: initialState.activeTasks.sortBy,
                };
                updateTableSortStatusObject(newSortValues);
                dispatch(updateTasksSortByAndStatusAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        tasksState.sortBy,
    ]);

    useEffect(() => {
        return () => {
            if (getTaskViewerIntervalId) clearInterval(getTaskViewerIntervalId);
        }
    }, [getTaskViewerIntervalId]);

    useEffect(() => {
        return () => {
            if (addTaskViewingIntervalId) clearInterval(addTaskViewingIntervalId);
        }
    }, [addTaskViewingIntervalId]);

    /**
     * Function for checking if there are applied filters currently save on redux.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(tasksState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        actionDrawer: boolean;
        assignToDrawer: boolean;
        editDrawer: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        certifyNotPaid: boolean;
        downloadToExcel: boolean;
        createScheduledReport: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        actionDrawer: false,
        assignToDrawer: false,
        editDrawer: false,
        addCommentDrawer: false,
        allSelected: false,
        certifyNotPaid: false,
        downloadToExcel: false,
        createScheduledReport: false
    });

    const [tableSortStatus, setTableSortStatus] = useState<{
        sortBy: string;
        taskStatus: string;
        sortAscending: boolean;
    }>({
        sortBy: tasksState.sortBy,
        taskStatus: tasksState.taskStatus,
        sortAscending: tasksState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(tasksState, 'pageData.currentPage', 0)
    );

    const [recordLimit, setRecordLimit] = useState<number | undefined>(undefined);
    const [appliedRecordLimit, setAppliedRecordLimit] = useState<number | undefined>(undefined);
    const [recordLimitPopoverVisible, setRecordLimitPopoverVisible] = useState(false);

    const [shouldCheckTaskState, setShouldCheckTaskState] = useState(false);

    const isAppliedRecordLimit = !!appliedRecordLimit && appliedRecordLimit > 0;
    const isAppliedSelectAllOrRecordLimit = showConditions.allSelected || isAppliedRecordLimit;

    /**
     * Function for preparing the filters to be used as payload for API.
     */
    const readyAllFiltersSelected = () => {
        let allFilters: DynamicObject = {
            ...tasksState.filters,
            Status: tableSortStatus.taskStatus,
        };

        const assignedUserValue = allFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete allFilters.AssignedUserId;
                allFilters.AssignedEmailAddress = assignedUserValue;
            }
        }

        return allFilters;
    };

    const lockedDeliveryMethod = get(
        selectedUserCompany,
        'Company.LockedDeliveryMethod'
    );

    const isPaymentPlanEnabled = get(
        selectedUserCompany,
        'Company.CompanyPaymentPlan.IsEnabled'
    );

    const supportSendNewInvoice = get(
        selectedUserCompany,
        'Company.SupportSendNewInvoice'
    );

    const supportCashAllocation = get(
        selectedUserCompany,
        'Company.SupportCashAllocation'
    );

    useEffect(() => {
        if (showConditions.actionDrawer) {
            getTaskViewing();
            addTaskViewing();
        } else {
            deleteTaskViewing();
        }
    }, [showConditions.actionDrawer]);

    useEffect(() => {
        if (selectedId) {
            if (taskDetailsDrawer.visible) {
                getTaskViewing(selectedId);
                addTaskViewing(selectedId);
            } else {
                deleteTaskViewing(selectedId);
            }
        }
    }, [taskDetailsDrawer.visible, selectedId]);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const payload: GetTasksRequestPayload = {
            filters: readyAllFiltersSelected(),
            sortBy: tableSortStatus.sortBy,
            sortAscending: tableSortStatus.sortAscending,
            pageSize,
            currentPage: currentPage,
            taskTableFilter: tasksTableFilter,
            lockedDeliveryMethod,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            isPaymentPlanEnabled,
        };

        return payload;
    };

    /**
     * Function that calls an action which triggers a saga for calling the fetch tasks API.
     * @param currentPage - current page for data to be fetched
     * @param pageSize - number of items in page
     */
    const fetchTasks = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getTasksRequestAction(payload));
    };

    /**
     * Function called in preparation to fetchTasks.
     * Manages the page to be used when calling the API.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            tasksState.loading
        )
            return;

        if (!tasksState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchTasks(nextPage);
    };

    /**
     * Function for fetching the action filter options.
     */
    const getActionFilterOptions = () => {
        dispatch(getTasksActionFilterOptionsRequestAction());
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        const usingCustomerWorkflow = get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );

        if (companyIdCognito === selectedCompanyId) {
            const customFieldTypes = usingCustomerWorkflow
                ? [CUSTOM_FIELD_TYPES.CUSTOMER]
                : [CUSTOM_FIELD_TYPES.INVOICE, CUSTOM_FIELD_TYPES.CUSTOMER];
            dispatch(
                getCustomFieldsFilterListByTypes(
                    customFieldTypes, // Filters are sorted based on the orders of types here and Number property
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };
    /**
     * Function that fetches the customer custom fields values
     */
    const getCustomerCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;

        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getTaskCustomerCustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            setCustomerCustomFieldValuesList(response.CustomFieldValues)
                        }
                    }
                )
            );
        }

    };

    /**
     * Function that fetches the invoice custom fields values
     */
    const getInvoiceCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;

        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getTaskInvoiceCustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            // customFieldsFilterOptions = await getCustomFieldsOptionsByKey(customFieldsFilterOptions, response.CustomFieldValues);
                            setInvoiceCustomFieldValuesList(response.CustomFieldValues)
                        }
                    }
                )
            );
        }
    };

    /**
     * Function that fetches the custom fields values
     */
    const getCustomFieldsValueList = () => {
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (invoiceCustomFieldValuesList.length > 0 || customerCustomFieldValuesList.length > 0) {
            customFieldsFilterOptions = getCustomFieldsOptionsByKey(customFieldsFilterOptions);
        }
        setCustomFieldValuesList(customFieldsFilterOptions)
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);
    useEffect(getCustomerCustomFieldsValueList, [customFieldsFilterList]);
    useEffect(getInvoiceCustomFieldsValueList, [customFieldsFilterList]);
    useEffect(getCustomFieldsValueList, [customerCustomFieldValuesList, invoiceCustomFieldValuesList]);

    /**
     * Listener function called when state changes that should trigger the ready state checking.
     */
    const handleShouldCheckTaskState = () => {
        if (shouldCheckTaskState && isActionAllowed) {
            checkReadyStateForSelectedTasks();
        }
    };

    useEffect(handleShouldCheckTaskState, [shouldCheckTaskState]);

    /**
     * Listener function called when changes on selected user company, filter values, sort values, and task status happens.
     */
    const callFetchTasks = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();

                getActionFilterOptions();
            }
            if (!selectedUserCompanyLoading) fetchTasks(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending, taskStatus } = tasksState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortStatus.sortBy ||
            sortAscending !== tableSortStatus.sortAscending ||
            taskStatus !== tableSortStatus.taskStatus
        ) {
            updateTableSortStatusObject({
                sortBy,
                sortAscending,
                taskStatus,
            });
        }
    };

    useEffect(callFetchTasks, [
        tasksState.sortBy,
        tasksState.sortAscending,
        tasksState.filters,
        tasksState.taskStatus,
        selectedUserCompany,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Function called on data update to check the selected keys if Select all button has been clicked.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(tasksState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        } else if (isAppliedRecordLimit) {
            const limitedSelection = tasksState.data.slice(0, appliedRecordLimit);
            const selectedKeys = difference(
                map(limitedSelection, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [tasksState.data]);

    /**
     * Function called when the filter value on upper left dropdown (next to refresh button) has been updated.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeTasksTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== tasksState.taskTableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setTasksTableFilter(filter);
        resetTableScrollAndPageData();
        if (
            refetch &&
            !isUndefined(tasksState.taskTableFilter) &&
            filter === tasksState.taskTableFilter
        ) {
            handleTaskFilterRefresh();
        } else {
            dispatch(updateTasksTableFilterAction(filter));
        }
    };

    /**
     * Function called when `Refresh` button is clicked or the upper left dropdown filter has been set to initial state.
     */
    const handleTaskFilterRefresh = () => {
        fetchTasksRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Function for populating the menu items for the dropdown.
     * @param menu
     */
    const populateTaskFilterSelectDropdownRender = (menu: JSX.Element) => (
        <div>{menu}</div>
    );

    /**
     * Function that checks if `Select all` is in effect.
     * Returns a boolean.
     */
    const checkIfSelectAll = () => {
        const allDataLoaded = tasksState.pageData.hasNextPage === false;
        return (
            !isEmpty(tasksState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 && showConditions.allSelected)
                || (allDataLoaded &&
                    tasksState.data.length ===
                    tableRowSelection.selectedRowKeys.length))
            || (isAppliedRecordLimit)
        );
    };

    /**
     * Function for populating the `Select/Deselect all` label.
     */
    const populateSelectDeselectAllLabel = () => {
        let selectDeselectLabel = '';
        if (checkIfSelectAll()) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return getTranslatedText(`${selectDeselectLabel} all`);
    };

    /**
     * Function for populating the PopoverContent when `Select/Deselect all` label being clicked.
     */
    const populateSelectDeselectPopoverContent = () =>
    (
        <div onClick={(ev) => ev.stopPropagation()} className="pop-action-content">
            <div>
                {getTranslatedText("Select a specific number of tasks")}
            </div>
            <div>
                <InputNumber
                    type='number'
                    min={0}
                    max={MAX_INT_VALUE}
                    placeholder={getTranslatedText("Enter a positive number")}
                    style={{ width: '100%' }}
                    value={recordLimit}
                    onChange={(value) => setRecordLimit(value)} />
            </div>
            <div className="ok-button-container">
                <Button
                    onClick={applyRecordLimit}
                    disabled={!recordLimit || recordLimit <= 0}
                    type="primary"
                >
                    {getTranslatedText("Ok")}
                </Button>
            </div>
        </div>
    )

    /**
     * Function that checks the ready state of the selected tasks.
     * To be used to enable/disable the `Action` button.
     * @param selectedRowKeys - array of selected keyss
     * @param unselectedRowKeys - array of unselected keys
     */
    const checkReadyStateForSelectedTasks = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        const { allExcept, keysToUse, filterObject, sortBy, sortAscending, recordLimit } = getSelectedTasksValues(
            selectedRowKeys,
            unselectedRowKeys
        );

        const payload: GetTasksRequestPayload = {
            filters: filterObject,
            sortBy,
            sortAscending,
            recordLimit,
            pageSize: tablePageSize,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            currentPage: tableCurrentPage,
            taskTableFilter: tasksTableFilter,
            isPaymentPlanEnabled,
            callback: (tasksActionState: any) => {
                updateActionBarItemsState({
                    totalTaskCount: tasksActionState.TotalTaskCount,
                    actionButtonDisabled: !tasksActionState.ActionEnabled,
                    actionButtonSelectedType: tasksActionState.ActionType,
                    actionButtonSelectedAutomation:
                        tasksActionState.ActionAutomation,
                    actionButtonLoading: false,
                });
            },
        };

        dispatch(checkTasksReadyStateAction(payload));

        setShouldCheckTaskState(false);
    };

    /**
     * Function called when `Select/Deselect all` button has been clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition = !appliedRecordLimit
            && (
                isEmpty(tableRowSelection.selectedRowKeys) ||
                tableRowSelection.selectedRowKeys.length < tasksState.data.length
            );

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(tasksState.data, selectedKeysName);
            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
            updateActionBarItemsState({
                actionButtonLoading: true,
            });

            setShouldCheckTaskState(true);
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedUnreadyKeys: [],
            });

            updateActionBarItemsState({
                actionButtonDisabled: true,
                actionButtonLoading: false,
            });
        }

        resetRecordLimit();
    };

    /**
     * Function for resetting RecordLimit related states to default value
     */
    const resetRecordLimit = useMemo(() => () => {
        setRecordLimitPopoverVisible(false);
        setRecordLimit(undefined);
        setAppliedRecordLimit(undefined);
    }, []);

    useEffect(resetRecordLimit, [customFieldsFilterList]);

    /**
     * Function called when `Ok` button on the RecordLimit Popover was clicked
     */
    const applyRecordLimit = () => {
        const slicedTasksState = tasksState.data.slice(0, recordLimit);
        const idKeys: string[] = map(slicedTasksState, selectedKeysName);
        updateTableRowSelection({
            selectedRowKeys: idKeys,
            unselectedRowKeys: [],
        });
        updateActionBarItemsState({
            actionButtonLoading: true,
        });
        setAppliedRecordLimit(recordLimit);
        setShouldCheckTaskState(true);
        setRecordLimitPopoverVisible(false);
        updateShowConditionsObject({
            allSelected: false
        });
    };


    /**
     * Function for updating the sort order of the items in table.
     * @param sortAscending - boolean indicator if the sort order is ascending or not
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateTasksSortByAndStatusAction({
                ...tableSortStatus,
                sortAscending,
            })
        );
    };

    const downloadDisabled = tasksState.loading || isEmpty(tasksState.data);

    /**
     * Function for populating the ellipsis popover upper section.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText("Filter")}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortStatus((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${tasksState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText("Change sort order")}
            </Button>
            <br />
            <Button
                type="link"
                onClick={downloadToExcelHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText("Download to Excel")}
            </Button>
            <br />
            <Button
                type="link"
                onClick={createScheduledReportHandler}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['far', 'calendar']}
                    className={`icon calendar`}
                />
                <FontAwesome
                    icon={['fas', 'clock']}
                    className={`icon clock`}
                />
                <span className='icon-button-wrapper'>{getTranslatedText("Create Scheduled Report")}</span>
            </Button>
            <br />
            <Button
                type="link"
                onClick={setTableCompactViewHandler}
                disabled={downloadDisabled || tasksState.compactViewLoading}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        tasksState.compactView ? 'expand' : 'compress',
                    ]}
                    className="mr-10"
                />
                {getTranslatedText(`Switch to ${tasksState.compactView ? 'expanded' : 'collapsed'} view`)}
            </Button>
        </div>
    );

    const getTableCompactViewStatus = () => {
        dispatch(getTableCompactViewRequestAction());
    };

    useEffect(getTableCompactViewStatus, [userPreference]);

    const setTableCompactViewHandler = () => {
        const compactViewStatus = tasksState.compactView;
        dispatch(
            setTableCompactViewRequestAction(
                !compactViewStatus,
                (res: ResponseModalObject) => {
                    if (!res.IsSuccess) {
                        Modal.error({
                            title: getTranslatedText("Error"),
                            content:
                                getTranslatedText(get(res, 'Messages.0') || "Error encountered while setting the table compact view state"),
                            okText: getTranslatedText("OK")
                        });
                    }
                }
            )
        );
    };

    /**
     * Function called when Download to Excel button is clicked.
     */
    const downloadToExcelHandler = () => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            sortBy,
            sortAscending,
            isUsingCloudImportType,
            lockedDeliveryMethod,
            usingMultipleWorkflow,
        } = filterAndPageProps;

        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );

        if (!usingMultipleWorkflow) delete cleanFilters.WorkflowIds;

        const variables = {
            ...cleanFilters,
            ...sortFields,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            LockedDeliveryMethod: lockedDeliveryMethod,
            IsMultipleWorkflow: usingMultipleWorkflow,
        };

        const payload = {
            Query: activeTasksSummaryPageQuery,
            OperationName: activeTasksSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.TASKS_PAGE,
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            sortBy,
            sortAscending,
            isUsingCloudImportType,
            lockedDeliveryMethod,
            usingMultipleWorkflow,
        } = filterAndPageProps;

        const sortFields = populateSortFieldsParamSortParams(sortBy);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );

        if (!usingMultipleWorkflow) delete cleanFilters.WorkflowIds;

        const variables = {
            ...cleanFilters,
            ...sortFields,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            LockedDeliveryMethod: lockedDeliveryMethod,
            IsMultipleWorkflow: usingMultipleWorkflow,
        };

        const payload = {
            Query: activeTasksSummaryPageQuery,
            OperationName: activeTasksSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.TASKS_PAGE,
            title: 'Active Tasks',
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
    * Function called when create schedule report is closed.
    * @param resetChanges
    * @param saveChanges
    * @param containerRef
    */
    const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function for populating the ellipsis popover lower section.
     */
    const populateFilterEllipsisPopoverContent = () => {
        const usedTasksSortByOptions = getTaskUsedSortOptions();

        return (
            <div className="pop-action-content">
                <div className="mb-10">
                    <span>{getTranslatedText("Sort by")}</span>
                </div>
                <div>
                    <Select
                        onChange={(sortBySelected: string) =>
                            updateTableSortStatusObject({
                                sortBy: sortBySelected,
                            })
                        }
                        value={tableSortStatus.sortBy}
                    >
                        {map(
                            usedTasksSortByOptions,
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: string;
                            }) => {
                                const labelUsed =
                                    replaceInstancesOfCustomerString(
                                        label,
                                        customerLabel
                                    );

                                return (
                                    <Option key={value} value={value}>
                                        {getTranslatedText(labelUsed)}
                                    </Option>
                                );
                            }
                        )}
                        {map(
                            customFieldsFilterList,
                            ({
                                Type,
                                Number: CFNumber,
                                FieldName,
                            }: CompanyCustomFieldConfigure) => (
                                <Option
                                    key={Type + CFNumber + FieldName}
                                    value={`${customFieldIndicator}${Type}--${FieldName}`}
                                >
                                    {getTranslatedText(FieldName)}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                <div className="mb-10">
                    <span>{getTranslatedText("Task status")}</span>
                </div>
                <div>
                    <Select
                        onChange={(statusSelected: string) =>
                            updateTableSortStatusObject({
                                taskStatus: statusSelected,
                            })
                        }
                        value={tableSortStatus.taskStatus}
                    >
                        {map(
                            tasksStatusFilterOptions,
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: number;
                            }) => (
                                <Option key={value} value={value}>
                                    {getTranslatedText(label)}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                <div className="ta-right">
                    <Button
                        type="primary"
                        disabled={
                            tasksState.loading ||
                            (tableSortStatus.sortBy === tasksState.sortBy &&
                                tableSortStatus.taskStatus ===
                                get(tasksState, 'taskStatus'))
                        }
                        onClick={applySortedByAndState}
                    >
                        {getTranslatedText("Apply")}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;

        const { taskTableFilter } = tasksState;
        if (taskTableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchTasksRequired) {
                fetchTasks(0);
                fetchTasksRequired = false;
            }
        } else {
            handlePageViewSelection(
                taskTableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [tasksState.taskTableFilter]);

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (tasksState.taskTableFilter !== initialTableFilter) {
            setTasksTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeTasksTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function called for applying the filters selected.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     * @param fromQuickSearch - boolean indicator if called from the quick search component
     */
    const applyFilters = async (
        filters?: GetTasksRequestPayload['filters'],
        fromFilterBar?: boolean,
        fromQuickSearch?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    tasksTableFilter,
                    fromFilterBar,
                    () => {
                        changeTasksTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.activeTasks.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortStatus(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateTasksFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys(fromQuickSearch);
    };

    /**
     * Common function for applying the quick search filters
     * @param filters
     * @param fromFilterBar
     */
    const applyQuickSearchFilters = (
        filters?: any,
        fromFilterBar?: boolean
    ) => {
        updateShowConditionsObject({
            allSelected: false,
            filterBar: true,
        });
        applyFilters(filters, fromFilterBar, true);
    };

    /**
     * Function called for applying the sort values selected.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortStatus;
        await dispatch(updateTasksSortByAndStatusAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Manipulate State Objects
     */
    /**
     * Function for updating the `showConditions` state.
     * @param selectionObject - must conform to `showConditions` state
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions({
            ...showConditions,
            ...showConditionObject,
        });
    };

    /**
     * Function for updating the `tableRowSelection` state.
     * @param selectionObject - must conform to `tableRowSelection` state
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Function for updating the `actionBarItemsState` state.
     * @param itemStateObject - must conform to `actionBarItemsState` state
    */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState((prev) => ({
            ...prev,
            ...itemStateObject,
        }));
    };

    /**
     * Function for updating the `taskDetailsDrawer` state.
     * @param taskDetailsDrawerStateObject - must conform to `taskDetailsDrawer` state
     */
    const updateTaskDetailsDrawerState = (taskDetailsDrawerStateObject: {}) => {
        setTaskDetailsDrawer({
            ...taskDetailsDrawer,
            ...taskDetailsDrawerStateObject,
        });
    };

    /**
     * Function called for closing the task details drawer.
     * Resets all the active data for the previously selected item in redux state.
     */
    const closeTaskDetailsDrawer = () => {
        updateTaskDetailsDrawerState({
            visible: false,
        });
    };

    /**
     * Function for updating the `tableSortStatus` state.
     * @param tableSortStatusObject - must conform to `tableSortStatus` state
     */
    const updateTableSortStatusObject = (tableSortStatusObject: {}) => {
        setTableSortStatus({
            ...tableSortStatus,
            ...tableSortStatusObject,
        });
    };

    /**
     * Function called when the checkbox is clicked.
     * @param record - data for the row item where the ticked checkbox is in
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when a row item is clicked.
     * @param record - data for the clicked row item
     */
    const onRowClick = (record: DynamicObject) => {
        dispatch(
            setSelectedTaskIdRequestAction(get(record, 'Id'), () => {
                const drawerType = get(record, 'Type');
                const drawerAutomation = get(record, 'Automation');
                updateTaskDetailsDrawerState({
                    visible: true,
                    drawerType,
                    drawerAutomation,
                });
            })
        );
    };

    const generatePayloadForTaskViewing = (key?: string) => {
        const {
            allExcept,
            filterObject,
            keysToUse,
            recordLimit,
            sortAscending,
            sortBy
        } = getSelectedTasksValues();

        const payload: ChangeTaskViewingRequestPayload | GetTaskViewerDetailForUserRequestPayload = {
            filters: filterObject,
            recordLimit,
            taskIds: key ? [key] : keysToUse,
            excludeTasks: key ? false : allExcept,
            taskTableFilter: tasksTableFilter,
            sortBy,
            sortAscending,
            lockedDeliveryMethod,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            isPaymentPlanEnabled,
        };

        return payload;
    };

    const addTaskViewing = (key?: string) => {
        const payload = {
            ...generatePayloadForTaskViewing(key),
            callback: (response: DynamicObject) => {
                console.log(response)
            }
        };
        const action = () => {
            dispatch(
                addTaskViewingsRequestAction(payload)
            );
        };
        action();
        const id: any = setInterval(action, ADD_TASK_VIEWING_INTERVAL);
        setAddTaskViewingIntervalId(id);
    };

    const getTaskViewing = (key?: string) => {
        let silent = false;
        const action = () => {
            const fetchPayload = {
                ...generatePayloadForTaskViewing(key),
                silent
            };
            silent = true;
            dispatch(
                getTaskViewerDetailForUserRequestAction(fetchPayload)
            );
        };
        action();
        const id: any = setInterval(action, GET_TASK_VIEWER_INTERVAL);
        setGetTaskViewerIntervalId(id);
    };

    const deleteTaskViewing = (key?: string) => {
        setGetTaskViewerIntervalId(undefined);
        setAddTaskViewingIntervalId(undefined);
        const payload = {
            ...generatePayloadForTaskViewing(key),
            callback: (response: DynamicObject) => {
                // console.log(response)
            }
        };
        dispatch(
            deleteTaskViewingsRequestAction(payload)
        );
    };

    /**
     * Function to populate the loading text for the table.
     */
    const populateTableLoadingText = () => {
        const loadingText = tasksState.loading
            ? `Fetching ${tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
            } tasks`
            : 'Please wait';

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function called when the row is selected/deselected.
     * @param record - data item for the selected/deselected row
     * @param selected - indicator if the row is selected or deselected
     * @param selectedRows
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: any,
        nativeEvent: any
    ) => {
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];

        const selectedUnreadyKeys = [...tableRowSelection.selectedUnreadyKeys];

        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
            if (!record.isReady) {
                selectedUnreadyKeys.push(record.key);
            }
        } else {
            unselectedRowKeys = isAppliedSelectAllOrRecordLimit
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];

            if (!record.isReady) {
                remove(
                    selectedUnreadyKeys,
                    (unReadyKey) => unReadyKey === record.key
                );
            }
        }

        const { allExcept } = getSelectedTasksValues();

        let isActionNotAllowedForSelected =
            !isEmpty(selectedUnreadyKeys) || isEmpty(selectedRowKeys);

        if (allExcept) {
            isActionNotAllowedForSelected =
                actionBarItemsState.actionButtonDisabled;
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
            selectedUnreadyKeys,
        });

        if (isActionAllowed) {
            if (isAppliedSelectAllOrRecordLimit) {
                updateActionBarItemsState({
                    actionButtonLoading: true,
                });

                setShouldCheckTaskState(true);
            } else {
                const selectedTypes: any[] = [];
                const selectedAutomations: any[] = [];
                const selectedTicketTypes: any[] = [];

                forEach(tasksState.data, (taskData) => {
                    if (includes(selectedRowKeys, taskData.Id)) {
                        const type = taskData.Type;
                        if (!includes(selectedTypes, type))
                            selectedTypes.push(type);

                        const automation = type === taskHistoryBatchTypes.PhoneCall
                            ? taskHistoryAutomation.Hybrid
                            : taskData.Automation;
                        if (!includes(selectedAutomations, automation))
                            selectedAutomations.push(automation);

                        if (taskData.Type === taskHistoryBatchTypes.Ticket) {
                            const ticketType = taskData.Ticket.TicketOption.Reason;

                            if (!includes(selectedTicketTypes, ticketType))
                                selectedTicketTypes.push(ticketType);
                        }
                    }
                });

                const selectedTypesLength = selectedTypes.length;
                const currentlySelectedType =
                    selectedTypesLength === 1 ? selectedTypes[0] : undefined;

                const selectedAutomationsLength = selectedAutomations.length;
                let currentlySelectedAutomation =
                    selectedAutomationsLength === 1
                        ? selectedAutomations[0]
                        : undefined;
                currentlySelectedAutomation = currentlySelectedAutomation && (
                    currentlySelectedType === taskHistoryBatchTypes.PhoneCall
                        ? taskHistoryAutomation.Hybrid
                        : currentlySelectedAutomation
                );

                if (currentlySelectedType === taskHistoryBatchTypes.PaymentPlan
                    && isEmpty(selectedUnreadyKeys)) {
                    isActionNotAllowedForSelected = false;
                } else if (
                    (currentlySelectedType === taskHistoryBatchTypes.Ticket ||
                        currentlySelectedAutomation ===
                        taskHistoryAutomation.Manual) &&
                    selectedRowKeys.length > 1
                ) {
                    if (currentlySelectedType === taskHistoryBatchTypes.RemittanceAdvice ||
                        currentlySelectedType === taskHistoryBatchTypes.PaymentVerification ||
                        currentlySelectedType === taskHistoryBatchTypes.PaymentManualAllocation
                    ) {
                        if (selectedTypesLength > 1)
                            isActionNotAllowedForSelected = true;
                        else
                            isActionNotAllowedForSelected = false;
                    }
                    else if (currentlySelectedType === taskHistoryBatchTypes.Ticket) {
                        if (selectedTicketTypes.length > 1)
                            isActionNotAllowedForSelected = true;
                        else
                            isActionNotAllowedForSelected = false;
                    }
                    else
                        isActionNotAllowedForSelected = true;
                } else if (
                    currentlySelectedType ===
                    taskHistoryBatchTypes.PaymentSchedule &&
                    !currentlySelectedAutomation &&
                    selectedRowKeys.length > 1
                ) {
                    isActionNotAllowedForSelected = true;
                } else if (
                    currentlySelectedType ===
                    taskHistoryBatchTypes.NewInvoiceEmail &&
                    !currentlySelectedAutomation &&
                    selectedRowKeys.length > 1
                ) {
                    isActionNotAllowedForSelected = true;
                } else if (selectedTypesLength > 1) {
                    if (
                        (selectedTypesLength === 2 || selectedTypesLength === 3) &&
                        (
                            includes(
                                selectedTypes,
                                taskHistoryBatchTypes.Communication
                            ) ||
                            includes(
                                selectedTypes,
                                taskHistoryBatchTypes.PaymentSchedule
                            ) ||
                            includes(
                                selectedTypes,
                                taskHistoryBatchTypes.NewInvoiceEmail
                            )
                        ) &&
                        !includes(
                            selectedTypes,
                            taskHistoryBatchTypes.Collection
                        ) &&
                        !includes(
                            selectedAutomations,
                            taskHistoryAutomation.Manual
                        ) &&
                        !includes(
                            selectedAutomations,
                            taskHistoryAutomation.Hybrid
                        ) &&
                        isEmpty(selectedUnreadyKeys)
                    ) {
                        isActionNotAllowedForSelected = false;
                    } else {
                        isActionNotAllowedForSelected = true;
                    }
                }
                updateActionBarItemsState({
                    actionButtonDisabled: isActionNotAllowedForSelected,
                    actionButtonSelectedType: currentlySelectedType,
                    actionButtonSelectedAutomation: currentlySelectedAutomation,
                    actionButtonSelectedTicketTaskId:
                        currentlySelectedType === taskHistoryBatchTypes.Ticket
                            ? selectedRowKeys[0]
                            : undefined,
                });
            }
        }
    };

    /**
     * Function for updating the visibility of popovers.
     * @param name - name of popover
     * @param condition - optional condition if the change should be applied
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (
                    name === 'actionDrawer' ||
                    name === 'assignToDrawer' ||
                    name === 'editDrawer' ||
                    name === 'addCommentDrawer'
                ) {
                    const selectedTask: Task | undefined = find(tasksState.data, (c) => c.Id === tableRowSelection.selectedRowKeys[0]);
                    const isMultipleSelected = tableRowSelection.selectedRowKeys.length > 1;

                    if (selectedTask && !isMultipleSelected && get(selectedTask, 'Type') === taskHistoryBatchTypes.PhoneCall && name === 'actionDrawer') {
                        onRowClick(selectedTask);
                    } else {
                        visibilityCondition = !showConditions[name];
                        updateShowConditionsObject({
                            [name]: visibilityCondition,
                        });
                    }
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Function for resetting the scroll and current page.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        if (isAppliedRecordLimit) {
            resetRecordLimit();
            updateTableRowSelection({
                selectedRowKeys: [],
                selectedUnreadyKeys: [],
            });
        }
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting the selected, unselected, and selected unread keys.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        updateActionBarItemsState({
            actionButtonDisabled: true,
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
        resetRowSelectionAndExpandStatus();
        resetRecordLimit();
    };

    /**
     * Function called for closing the filter bar section.
     * Resets all the filters to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Common function for getting the filter values, and keys to be used in payload when calling the API.
     * @param selectedRowKeys
     * @param unselectedRowKeys
     */
    const getSelectedTasksValues = (
        selectedRowKeys?: string[],
        unselectedRowKeys?: string[]
    ) => {
        let allExcept = false;
        let keysToUse = selectedRowKeys || [
            ...tableRowSelection.selectedRowKeys,
        ];

        if (isAppliedSelectAllOrRecordLimit) {
            allExcept = true;
            keysToUse = unselectedRowKeys || [
                ...tableRowSelection.unselectedRowKeys,
            ];
        }
        const filters = readyAllFiltersSelected();
        const filterObject: DynamicObject = {
            ...filters,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
            sortBy: tableSortStatus.sortBy,
            sortAscending: tableSortStatus.sortAscending,
            recordLimit: appliedRecordLimit && appliedRecordLimit - tableRowSelection.unselectedRowKeys.length
        };
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchTasks(0);
    };

    /**
     * Function for resetting the action drawer values (state).
     * @param refreshList
     */
    const resetActionDrawerValues = (refreshList?: boolean) => {
        updateShowConditionsObject({
            actionDrawer: false,
            certifyNotPaid: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
            resetRowSelectionAndExpandStatus();
        }
    };

    /**
     * Common function for resetting all checkbox and row collapse status.
     */
    const resetRowSelectionAndExpandStatus = () => {
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
            selectedUnreadyKeys: [],
        });
        dispatch(setTaskExpandStatusAction());
    };

    /**
     * Function to populate the `Action` button view.
     */
    const populateActionProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'forward']} />
            <span>{getTranslatedText("Action")}</span>
        </>
    );

    /**
     * Function to populate the Edit task drawer title.
     */
    const populateEditDrawerTitle = () => {
        if (tableRowSelection.selectedRowKeys.length > 1) {
            return getTranslatedText("Edit tasks");
        } else {
            return getTranslatedText("Edit task");
        }
    };

    /**
     * Function to populate the Assign to drawer title.
     */
    const populateAssignToDrawerTitle = () => getTranslatedText("Assign to user");

    /**
     * Function for populating the `Edit` button view.
     */
    const populateEditProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'edit']} />
            <span>{getTranslatedText("Edit")}</span>
        </>
    );

    /**
     * Function called when edit task changes has been saved.
     * @param values
     */
    const onSaveTaskEditChanges = (values: DynamicObject) => {
        const {
            allExcept, keysToUse, filterObject,
            recordLimit, sortAscending, sortBy
        } = getSelectedTasksValues();

        dispatch(
            bulkSaveTaskDetailsUpdateRequestAction({
                filter: filterObject,
                taskIds: keysToUse,
                excludeTasks: allExcept,
                taskObject: values,
                callback: bulkSaveTaskDetailsResponseModal(keysToUse.length),
                sortBy,
                sortAscending,
                recordLimit,
                isPaymentPlanEnabled,
            })
        );
    };

    /**
     * Function called when assign to changes has been saved.
     * @param values
     */
    const onSaveAssignToUserChanges = (values: DynamicObject) => {
        const {
            allExcept, keysToUse, filterObject,
            recordLimit, sortAscending, sortBy
        } = getSelectedTasksValues();

        dispatch(
            bulkSaveTaskDetailsUpdateRequestAction({
                filter: filterObject,
                taskIds: keysToUse,
                excludeTasks: allExcept,
                taskObject: values,
                callback: bulkAssignTaskToUserResponseModal(keysToUse.length),
                sortBy,
                sortAscending,
                recordLimit,
                isPaymentPlanEnabled,
            })
        );
    };

    /**
     * Function for populating the modal title for editing task details.
     */
    const populateTaskDetailsBatchSaveRequestModalTitle = () => {
        const { keysToUse } = getSelectedTasksValues();
        let response: string = "Saving task details";

        if (keysToUse.length > 1) {
            response = "Saving tasks details"
        }

        return getTranslatedText(response);
    };

    /**
     * Function for populating the modal body for editing task details.
     */
    const populateTaskDetailsBatchSaveRequestModalDisplayMessage = () => {
        const { keysToUse } = getSelectedTasksValues();
        let response: string = "Please wait while saving the task data";

        if (keysToUse.length > 1) {
            response = "Please wait while saving the tasks data";
        }

        return getTranslatedText(response);
    };

    /**
     * Function responsible for showing the response modal after assign task/s to user has finished.
     * Either success/error.
     * @param idsCount - number of selected items assigned to user, used for populating the label if plural/singular
     */
    const bulkAssignTaskToUserResponseModal =
        (idsCount: number) =>
            ({
                IsSuccess,
                Messages,
            }: {
                IsSuccess: boolean;
                Messages: string[] | undefined;
            }) => {
                if (IsSuccess) {
                    Modal.success({
                        title: getTranslatedText("Success"),
                        // content: successMessageContent,
                        onOk: () => {
                            // resetTableScrollAndPageData();
                            updateShowConditionsObject({
                                allSelected: false,
                                assignToDrawer: false,
                            });
                            refetchListAndResetScroll();
                            updateActionBarItemsState({
                                assignToButtonLoading: false,
                            });
                            resetRowSelectionAndExpandStatus();
                        },
                        okText: getTranslatedText("OK"),
                    });
                } else {
                    let errorMessageContent: any = "Failed to assign task to user";

                    if (idsCount > 1) {
                        errorMessageContent = "Failed to assign tasks to user";
                    }

                    if (Messages !== undefined && !isEmpty(Messages)) {
                        errorMessageContent = Messages.map((error: string, index: number) => {
                            let errorMessage: string = error;
                            const numberRegex = /\d+/; // Regular expression to match digits

                            // Extract the number from the errorMessage
                            const match = errorMessage.match(numberRegex);
                            const extractedNumber = match ? match[0] : null;

                            // Concatenate the extracted number back with the text
                            if (extractedNumber) {
                                errorMessage = extractedNumber ? errorMessage.replace(numberRegex, '') : errorMessage;
                                errorMessage = errorMessage.trim();
                                errorMessage = getTranslatedText(errorMessage).replace("{TaskEditAction}", extractedNumber);
                            }

                            return (
                                <div key={index}>{errorMessage}</div>
                            );
                        });
                    }
                    else {
                        errorMessageContent = getTranslatedText(errorMessageContent);
                    }

                    Modal.error({
                        title: getTranslatedText("Error"),
                        content: errorMessageContent,
                        okText: getTranslatedText("OK"),
                    });
                }
            };

    /**
     * Function responsible for showing the response modal after task editing has finished.
     * Either success/error.
     * @param idsCount - number of selected items edited, used for populating the label if plural/singular
     */
    const bulkSaveTaskDetailsResponseModal =
        (idsCount: number) =>
            ({
                IsSuccess,
                Messages,
            }: {
                IsSuccess: boolean;
                Messages: string[] | undefined;
            }) => {
                if (IsSuccess) {
                    /**
                     * commented out - Ang's request
                     */
                    // let successMessageContent: any = `Task${
                    //     idsCount > 1 ? 's' : ''
                    // } data updated successfully!`;
                    // if (!isEmpty(Messages)) {
                    //     successMessageContent = map(
                    //         Messages,
                    //         (message: string, index: number) => (
                    //             <div key={index}>{message}</div>
                    //         )
                    //     );
                    // }
                    Modal.success({
                        title: getTranslatedText("Success"),
                        // content: successMessageContent,
                        onOk: () => {
                            // resetTableScrollAndPageData();
                            updateShowConditionsObject({
                                allSelected: false,
                                editDrawer: false,
                            });
                            refetchListAndResetScroll();
                            updateActionBarItemsState({
                                editButtonLoading: false,
                            });
                            resetRowSelectionAndExpandStatus();
                        },
                        okText: getTranslatedText("OK"),
                    });
                } else {
                    let errorMessageContent: any = "Failed to update task data";

                    if (idsCount > 1) {
                        errorMessageContent = "Failed to update tasks data"
                    }

                    if (!isEmpty(Messages)) {
                        errorMessageContent = map(
                            Messages,
                            (error: string, index: number) => (
                                <div key={index}>{error}</div>
                            )
                        );
                    }
                    else {
                        errorMessageContent = getTranslatedText(errorMessageContent);
                    }

                    Modal.error({
                        title: getTranslatedText("Error"),
                        content: errorMessageContent,
                        okText: getTranslatedText("OK"),
                    });
                }
            };

    /**
     * Function for populating the edit drawer content.
     */
    const populateEditDrawerContent = () => {
        return (
            <TaskEditDrawerContent
                onCancelClick={() => {
                    updateShowConditionsObject({
                        editDrawer: false,
                    });
                }}
                onSaveClick={onSaveTaskEditChanges}
                visible={showConditions.editDrawer}
                getSelectedTasksValues={getSelectedTasksValues}
                getWorkflowFilterOptions={getWorkflowFilterOptions}
            />
        );
    };

    /**
     * Function for populating the assign to drawer content.
     */
    const populateAssignToDrawerContent = () => {
        return (
            <TaskAssignToDrawerContent
                onCancelClick={() => {
                    updateShowConditionsObject({
                        assignToDrawer: false,
                    });
                }}
                onSaveClick={onSaveAssignToUserChanges}
                visible={showConditions.assignToDrawer}
                getSelectedTasksValues={getSelectedTasksValues}
            />
        );
    };

    /**
     * Function for populating the button content for add comment.
     */
    const populateAddCommentProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'comment']} />
            <span>{getTranslatedText("Add Comment")}</span>
        </>
    );

    /**
     * Function called to close the add comment panel
     * @param refreshList
     */
    const closeAddCommentPanel = (refreshList?: boolean) => {
        updateShowConditionsObject({
            addCommentDrawer: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };

    /**
     * Function for populating the payload needed for payment add comment.
     */
    const getAddCommentFilterPayload = () => {
        const {
            allExcept, keysToUse, filterObject,
            recordLimit, sortAscending, sortBy
        } = getSelectedTasksValues();

        return {
            filter: filterObject,
            taskIds: keysToUse,
            excludeTasks: allExcept,
            isPaymentPlanEnabled,
            sortBy, sortAscending, recordLimit
        };
    };

    /**
     * Function for populating the popover content for add comment.
     */
    const populateAddCommentDrawerContent = () => {
        return (
            <AddCommentPanel
                visible={showConditions.addCommentDrawer}
                closePanel={closeAddCommentPanel}
                filterPayload={getAddCommentFilterPayload()}
                dispatchAction={taskAddCommentRequestAction}
            />
        );
    };

    /**
     * Function for populating the workflow options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getWorkflowStepOptionsByKey = (
        workflowKey: string,
        actionFilterOptions: DynamicObject[]
    ) => {
        forEach(
            get(
                tasksState.actionFilterOptions,
                `${workflowKey}WorkflowOptions`
            ),
            (workflowOption) => {
                const childWorkflowSteps = map(workflowOption.States, (ws) => ({
                    label: ws,
                    value: ws,
                }));

                actionFilterOptions.push({
                    label: get(workflowOption, 'Workflow.WorkflowName'),
                    value: get(workflowOption, 'Workflow.WorkflowId'),
                    children: childWorkflowSteps,
                });
            }
        );

        return actionFilterOptions;
    };

    /**
     * Function for populating the custom fields options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getCustomFieldsOptionsByKey = (
        customFieldsFilterOptions: DynamicObject[],
        // customFieldValuesList: DynamicObject[]
    ) => {
        forEach(
            customFieldsFilterList,
            (customFieldsOption, index) => {
                const customFieldName = get(customFieldsOption, 'FieldName');
                const customFieldType = get(customFieldsOption, 'Type');
                let customFieldLists: DynamicObject[] = [];

                if (customFieldType === 'Customer') {
                    customFieldLists = customerCustomFieldValuesList.filter(x => x.Name == customFieldName && x.Value != null);
                } else {
                    customFieldLists = invoiceCustomFieldValuesList.filter(x => x.Name == customFieldName && x.Value != null);
                }

                const childCustomFieldValues = map(customFieldLists, (cfl, index) => ({
                    label: cfl.Value,
                    value: cfl.Value,
                }));

                customFieldsFilterOptions.push({
                    Number: index,
                    Type: get(customFieldsOption, 'Type'),
                    FieldName: get(customFieldsOption, 'FieldName'),
                    children: childCustomFieldValues,
                    filterLoading: tasksState.actionFilterOptionsLoading
                });
            }
        );

        return customFieldsFilterOptions;
    };

    /**
     * Function for populating the workflow name filter options.
     */
    const getWorkflowFilterOptions = () => {
        return map(workflowNames, (option: WorkflowOption) => {
            return {
                label: option.WorkflowName,
                value: option.WorkflowId,
            };
        });
    };

    /**
     * Function for checking before being able to proceed with filtering
     * @param inputValue - string
     */
    const checkingForAssignedUser = (inputValue: string | undefined | null) => {
        // const companyEnabledExternalTaskAssignment =
        //     get(
        //         selectedUserCompany,
        //         'Company.EnableExternalUserTaskAssignment'
        //     ) === true;

        // if (
        //     companyEnabledExternalTaskAssignment &&
        //     checkIfEmailIsValid(inputValue)
        // )
        if (checkIfEmailIsValid(inputValue)) return true;

        return false;
    };

    /**
     * Function for getting the filter values to be used in FilterBar component.
     */
    const populateTasksFilterBarFilters = () => {
        let actionFilterOptions: DynamicObject[] = [];
        if (usingCustomerWorkflow) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'Customer',
                actionFilterOptions
            );
        } else {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'Invoice',
                actionFilterOptions
            );
        }

        actionFilterOptions = getWorkflowStepOptionsByKey(
            'Ticket',
            actionFilterOptions
        );

        if (isPaymentPlanEnabled) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'PaymentPlan',
                actionFilterOptions
            );
        }
        if (supportCashAllocation) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'RemittanceAdvice',
                actionFilterOptions
            );
        }
        if (supportCashAllocation) {
            actionFilterOptions = getWorkflowStepOptionsByKey(
                'PaymentAllocation',
                actionFilterOptions
            );
        }

        const usedSpan = usingCustomerWorkflow ? 4 : 3;
        const typeOptionsUsed: {
            label: string;
            value: number;
        }[] = tasksTypeOptions.filter((tto) =>
            (isPaymentPlanEnabled || !paymentPlanTaskTypes.includes(tto.label)) &&
            (supportSendNewInvoice || newInvoiceEmailTaskType !== tto.label) &&
            (supportCashAllocation || paymentAllocationTaskType !== tto.label) &&
            (supportPaymentBehaviourInsights || paymentBehaviorInsightsType !== tto.label)
        );

        //  tasksReadyStateOptions.forEach((option, index) => {
        // tasksReadyStateOptions[index] = getTranslatedText(tasksReadyStateOptions[index]);
        // });

        let tasksFilterBarFilters = [
            // {
            //     filterName: 'Priority',
            //     filterStateName: 'Priority',
            //     filterElement: 'checkbox-group',
            //     filterType: 'array',
            //     filterOptions: [
            //         {
            //             label: 'High',
            //             value: 'High',
            //         },
            //         {
            //             label: 'Medium',
            //             value: 'Medium',
            //         },
            //         {
            //             label: 'Low',
            //             value: 'Low',
            //         },
            //     ],
            // },
            {
                filterName: getTranslatedText("Type"),
                filterStateName: 'Type',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: typeOptionsUsed.map((option: {
                    label: string;
                    value: number;
                }) => ({
                    ...option,
                    label: getTranslatedText(option.label) // Apply translation to the label
                })),
                span: usedSpan,
            },
            {
                filterName: getTranslatedText("Contact type"),
                filterStateName: 'ContactType',
                filterElement: 'checkbox-group',
                tagType: 'sentence',
                filterType: 'array',
                filterOptions: [
                    {
                        label: getTranslatedText('Email'),
                        value: 1,
                    },
                    {
                        label: getTranslatedText('Mobile (SMS)'),
                        value: 2,
                    },
                    {
                        label: getTranslatedText('Postal address'),
                        value: 4,
                    },
                    // {
                    //     label: 'Landline',
                    //     value: 8,
                    // },
                ],
                span: usedSpan,
            },
            {
                filterName: getTranslatedText("Ticket Reason"),
                filterStateName: 'TicketOptionReasons',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: ticketReasons.map(option => ({
                    ...option,
                    label: getTranslatedText(option.label)
                }))
            },
            {
                filterName: getTranslatedText("Ready state"),
                filterStateName: 'ActionDate',
                filterPrefixString: 'Ready',
                filterElement: 'select',
                filterType: 'text',
                filterOptions: tasksReadyStateOptions,
                filterOptionsCustomTypes: {
                    'Custom date range': 'date-range',
                    'Custom days range': 'all-days-range-from-to',
                },
                span: usedSpan,
            },
            {
                filterName: getTranslatedText("Workflow"),
                filterStateName: 'WorkflowNameStep',
                // filterElement: 'checkbox-group',
                filterElement: 'checkbox-group-tree',
                filterType: 'array',
                filterOptions: actionFilterOptions,
                filterLoading: tasksState.actionFilterOptionsLoading,
                span: usedSpan,
            },
            // {
            //     filterName: 'Invoice number',
            //     filterStateName: 'InvoiceNumber',
            //     filterElement: 'input',
            //     filterType: 'text',
            // },
            {
                filterName: getTranslatedText(capitalize(customerLabel)),
                filterStateName: 'Customer',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'Company name',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'DisplayName',
                span: usedSpan,
            },
            {
                filterName: getTranslatedText(capitalize(customerLabel) + ' A-Z'),
                filterStateName: 'CustomerFirstLetter',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: customerValuesOption
            },
            {
                filterName: getTranslatedText("Assigned user"),
                filterStateName: 'AssignedUserId',
                filterNameQuery: 'Name',
                filterElement: 'select-with-search',
                filterQuery: 'GET_USERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterResponse: 'GetUsersForCompany.CompanyUserRoles',
                filterLabelField: ['User.GivenName', 'User.FamilyName'],
                filterSort: 'Name',
                filterNameQuerySub: 'Email',
                filterSubChecking: checkIfEmailIsValid,
                filterJSONValueFieldSub: 'User.Email',
                span: usedSpan,
                tagClass: 'wb-bw',
                filterMappingUsed: selectUserFilterMapping,
            },
            {
                filterName: getTranslatedText("Country"),
                filterStateName: 'CustomerCountry',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'CustomerCountry',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'Country',
                span: usedSpan,
            }, {
                filterName: getTranslatedText("Postal State"),
                filterStateName: 'CustomerState',
                filterElement: 'input-autocomplete',
                filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                filterSort: 'CustomerState',
                filterResponse: 'GetCustomersForCompany.Customers',
                filterLabelField: 'State',
                span: usedSpan,
            },
            // {
            //     filterName: 'Amount',
            //     filterStateName: 'Amount',
            //     filterElement: 'select-multiple-and-input-amount',
            //     filterType: 'text',
            //     filterOptions: [
            //         {
            //             AmountType: tasksAmountType,
            //         },
            //         {
            //             AmountOperator: invoicesAmountOperatorOptions,
            //         },
            //     ],
            // },
            {
                filterName: getTranslatedText("Actions"),
                filterStateName: 'Action',
                filterElement: 'checkbox-group',
                filterType: 'array',
                filterOptions: ticketActionsOptions,
            }
        ];

        let invFilterIdx = 4,
            amtFilterIdx = 6;
        // if (usingMultipleWorkflow) {
        //     let workflowFilterOptions: DynamicObject[] =
        //         getWorkflowFilterOptions();
        //     tasksFilterBarFilters = insertAt(tasksFilterBarFilters, 4, [
        //         {
        //             filterName: 'Workflow',
        //             filterStateName: 'WorkflowIds',
        //             filterElement: 'checkbox-group',
        //             filterType: 'array',
        //             filterOptions: workflowFilterOptions,
        //             filterLoading: workflowFetchLoading,
        //         },
        //     ]);
        //     invFilterIdx++;
        //     amtFilterIdx++;
        // }

        if (!usingCustomerWorkflow) {
            // invoicesDueInOptions(isCalendarView).forEach((option, index) => {
            //     invoicesDueInOptions(isCalendarView)[index] = getTranslatedText(invoicesDueInOptions(isCalendarView)[index]);         
            // });
            tasksFilterBarFilters = insertAt(
                tasksFilterBarFilters,
                invFilterIdx,
                [
                    {
                        filterName: getTranslatedText("Date"),
                        filterStateName: 'InvoiceDueDate',
                        filterElement: 'select',
                        filterType: 'text',
                        filterOptions: invoicesDueInOptions(isCalendarView),
                        filterOptionsCustomTypes: {
                            'Custom date range': 'date-range',
                            'Custom days range': 'all-days-range',
                        }
                    }
                ]
            );

            tasksFilterBarFilters = insertAt(
                tasksFilterBarFilters,
                invFilterIdx,
                [
                    {
                        filterName: getTranslatedText("Invoice number"),
                        filterStateName: 'InvoiceNumber',
                        filterElement: 'input-autocomplete',
                        filterQuery:
                            'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER',
                        filterSort: invoicesSortValues.INVOICE_NUMBER,
                        filterResponse: 'GetInvoicesForCompany.Invoices',
                        filterLabelField: 'Number',
                        span: usedSpan,
                    },
                ]
            );

            tasksFilterBarFilters = insertAt(
                tasksFilterBarFilters,
                amtFilterIdx,
                [
                    {
                        filterName: getTranslatedText("Amount"),
                        filterStateName: 'Amount',
                        filterElement: 'select-multiple-and-input-amount',
                        filterType: 'text',
                        filterOptions: [
                            {
                                AmountType: tasksAmountType,
                            },
                            {
                                AmountOperator: invoicesAmountOperatorOptions,
                            },
                        ],
                        span: usedSpan,
                    },
                ]
            );
        }

        setFilterItemList(tasksFilterBarFilters);
    };

    useEffect(populateTasksFilterBarFilters, [
        usingCustomerWorkflow,
        tasksState.actionFilterOptions,
        workflowFetchLoading,
    ]);

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        const isLg = getIfIsLg();
        const rowHeight = isLg
            ? TASKS_PAGE.compactRowHeight + 20
            : TASKS_PAGE.compactRowHeight;

        setUsedRowHeight(rowHeight);
    };

    /**
     * Common function for populating the quick search common filter section
     */
    const populateQSCommonFilterOptions = () => {
        const filterOptions = [
            {
                label: getTranslatedText("Invoice number"),
                value: qsFilterNames.INVOICE,
            },
            {
                label: getTranslatedText(capitalize(customerLabel)),
                value: qsFilterNames.CUSTOMER,
            },
        ];

        if (usingCustomerWorkflow) filterOptions.splice(0, 1);

        return filterOptions;
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    const selectedTaskCount = isAppliedSelectAllOrRecordLimit
        ? actionBarItemsState.totalTaskCount
        : tableRowSelection.selectedRowKeys.length;

    /**
    * Function that fetches the customer first letter values
    */
    const getCustomerFirstLetterValuesList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let options: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerFirstLetterValuesRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            options = await getCustomerFirstLatterValuesOptions(options, response.Values);
                            setCustomerValuesList(options)
                        }
                    }
                )
            );
        }
    };
    const getCustomerFirstLatterValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ) => {
        forEach(
            valuesList,
            (value, index) => {
                options.push({
                    label: value,
                    value: value
                });
            }
        );
        return options;
    };

    useEffect(getCustomerFirstLetterValuesList, [selectedUserCompany]);

    const getTotalSelectedTaskCount = (selectedTaskCount: number): string => {
        let response: string = getTranslatedText("{selectedTaskCount} selected");
        response = response.replace("{selectedTaskCount}", selectedTaskCount.toString());

        return response;
    }

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>
                            {getTranslatedText("Active Tasks")}&nbsp;
                            {
                                selectedTaskCount > 0 && !isActionButtonLoading && (
                                    <span className='text-grey'>({getTotalSelectedTaskCount(selectedTaskCount)})</span>
                                )
                            }
                        </Title>
                    </Col>
                    <Col className="ta-right">
                        <QuickSearchComponent
                            loading={tasksState.loading}
                            customFieldsFilterList={customFieldsFilterList}
                            applyFilters={applyQuickSearchFilters}
                            filterOptions={populateQSCommonFilterOptions()}
                            selectedUserCompany={selectedUserCompany}
                        />
                    </Col>
                </Row>
                <div className="spacer-5" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={tasksState.loading}
                                    applyFilters={applyFilters}
                                    filters={filterItemList}
                                    filterValues={tasksState.filters}
                                    colDivision={3}
                                    closeFilterBar={closeFilterBar}
                                    appliedView={tasksTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={IsSearchCustomFieldsByCheckBox ? customFieldValuesList : customFieldsFilterList}
                                    checkingForAssignedUser={
                                        checkingForAssignedUser
                                    }
                                    tableSortState={{
                                        sortBy: tasksState.sortBy,
                                        taskStatus: tasksState.taskStatus,
                                        sortAscending: tasksState.sortAscending,
                                    }}
                                />
                            </Suspense>
                        </div>
                    )}
                    <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={tasksState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'task-filter',
                                actionType: 'select-with-button',
                                // selectOptions: tasksTableFilterOptions,
                                selectValue: tasksTableFilter,
                                selectDropdownRender:
                                    populateTaskFilterSelectDropdownRender,
                                onSelectChange: changeTasksTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText("Refresh")}</span>
                                    </>
                                ),
                                buttonDisabled: tasksState.loading,
                                onButtonClick: handleTaskFilterRefresh,
                                // minWidth: 250,
                            },
                            {
                                actionKey: 'task-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonLoading: isActionButtonLoading,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span className='mr-8'>{populateSelectDeselectAllLabel()}</span>
                                        <Popover
                                            visible={recordLimitPopoverVisible}
                                            trigger="click"
                                            onVisibleChange={(visible) => setRecordLimitPopoverVisible(visible)}
                                            placement="bottom"
                                            content={populateSelectDeselectPopoverContent()}
                                        >
                                            <div style={{ display: 'inline-block' }}
                                                onClick={(ev) => ev.stopPropagation()}>
                                                <FontAwesome className='fs-20' icon={['fas', 'sort-down']} />
                                            </div>
                                        </Popover>
                                    </>
                                )
                            },
                            {
                                actionKey: 'task-action',
                                actionType: 'protected-drawer-button',
                                allowedRoles:
                                    rolePermissions.TASK_ACTION_SEND_NOTIFICATION,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'actionDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                buttonLoading: isActionButtonLoading,
                                buttonDisabled:
                                    !isActionAllowed ||
                                    actionBarItemsState.actionButtonDisabled,
                                buttonContent:
                                    populateActionProtectedPopoverButtonContent(),
                                popoverComponent: <TaskActionDrawer
                                    getSelectedTasksValues={getSelectedTasksValues}
                                    popoverVisible={showConditions.actionDrawer}
                                    selectedTaskIds={tableRowSelection.selectedRowKeys}
                                    selectedTaskId={actionBarItemsState.actionButtonSelectedTicketTaskId ||
                                        get(tableRowSelection.selectedRowKeys, 0)}
                                    onClose={resetActionDrawerValues}
                                    onNotifyCustomerResponseOk={() => {
                                        resetActionDrawerValues(true);
                                        updateActionBarItemsState({
                                            actionButtonDisabled: true,
                                            actionButtonLoading: false,
                                        });
                                        updateShowConditionsObject({
                                            allSelected: false,
                                            actionDrawer: false,
                                            certifyNotPaid: false,
                                        });
                                    }}
                                    selectedType={actionBarItemsState.actionButtonSelectedType}
                                    selectedAutomation={actionBarItemsState.actionButtonSelectedAutomation}
                                />
                            },
                            {
                                actionKey: 'task-assign-to',
                                actionType: 'protected-drawer-button',
                                allowedRoles:
                                    rolePermissions.TASK_ASSIGN_TO_USER,
                                popoverVisible: showConditions.assignToDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'assignToDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: populateAssignToDrawerTitle(),
                                popoverContent: populateAssignToDrawerContent(),
                                buttonLoading:
                                    actionBarItemsState.assignToButtonLoading,
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'user-tag']}
                                        />
                                        <span>{getTranslatedText("Assign to")}</span>
                                    </>
                                ),
                            },
                            // {
                            //     actionKey: 'task-escalate',
                            //     actionType: 'protected-button',
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     onButtonClick: () =>
                            //         console.log('Escalate clicked!'),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome icon={['fas', 'signal']} />
                            //             <span>Escalate</span>
                            //         </>
                            //     ),
                            // },
                            {
                                actionKey: 'task-edit',
                                actionType: 'protected-drawer-button',
                                allowedRoles: rolePermissions.TASK_EDIT_DATA,
                                popoverVisible: showConditions.editDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'editDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: populateEditDrawerTitle(),
                                popoverContent: populateEditDrawerContent(),
                                buttonLoading:
                                    actionBarItemsState.editButtonLoading,
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent:
                                    populateEditProtectedPopoverButtonContent(),
                            },
                            {
                                actionKey: 'task-add-comment',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.addCommentDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'addCommentDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText("Add a comment"),
                                popoverContent:
                                    populateAddCommentDrawerContent(),
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent:
                                    populateAddCommentProtectedPopoverButtonContent(),
                            },
                        ]}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row
                    key="table-container"
                    className="vlist-container"
                    style={{ height: 'calc(100vh - 180px)' }}
                >
                    <Col span={24} className="h-100">
                        <VirtualizedList
                            dataSource={tasksState.data}
                            fetchMore={handleFetch}
                            // scroll={computeTableScroll(
                            //     window.innerHeight - 185,
                            //     tablePageSize,
                            //     usedRowHeight
                            // )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={
                                tasksState.loading ||
                                tasksState.compactViewLoading
                            }
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(tasksState.errorMessages)
                                    ? get(tasksState, 'errorMessages.0')
                                    : getTranslatedText('No tasks found')
                            }
                            hasNextPage={tasksState.pageData.hasNextPage}
                            itemComponent={TaskItemComponent}
                            itemHeight={usedRowHeight}
                            selectedId={selectedId}
                            usingCustomerWorkflow={usingCustomerWorkflow}
                            lockedDeliveryMethod={lockedDeliveryMethod}
                            isCompactView={tasksState.compactView}
                            toggledIds={toggledIds}
                            customerLabel={customerLabel}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                                isAppliedRecordLimit,
                                shouldDisableSelect: (item: Task) =>
                                    isAppliedRecordLimit
                                    && !tableRowSelection.selectedRowKeys
                                        .concat(tableRowSelection.unselectedRowKeys)
                                        .includes(item.Id)
                            }}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <TaskItemDetailsDrawerComponent
                            {...taskDetailsDrawer} // spread the taskDetailsDrawer state
                            closeDrawer={closeTaskDetailsDrawer}
                            usingCustomerWorkflow={usingCustomerWorkflow}
                            lockedDeliveryMethod={lockedDeliveryMethod}
                            isUsingCloudImportType={isUsingCloudImportType}
                            usingMultipleWorkflow={usingMultipleWorkflow}
                            isPaymentPlanEnabled={isPaymentPlanEnabled}
                            isCalendarView={isCalendarView}
                            isActionAllowed={isActionAllowed}
                            onCloseTaskActionDrawer={(refresh?: boolean) => {
                                if (refresh) {
                                    resetActionDrawerValues(true);
                                    closeTaskDetailsDrawer();
                                }
                            }}
                            getTaskDataRequestAction={getTaskDataRequestAction}
                        />
                    </Suspense>
                    {!showConditions.assignToDrawer &&
                        tasksState.saveTaskDataLoading && (
                            <Suspense fallback={null}>
                                <ModalWithSpinner
                                    modalTitle={populateTaskDetailsBatchSaveRequestModalTitle()}
                                    modalVisible={
                                        tasksState.saveTaskDataLoading
                                    }
                                    displayMessage={populateTaskDetailsBatchSaveRequestModalDisplayMessage()}
                                />
                            </Suspense>
                        )}
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={populateDownloadToExcelModalTitle()}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={populateDownloadToExcelModalDisplayMessage()}
                            />
                        </Suspense>
                    )}
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(
        withDateFormatHandler(ActiveTasksManagementPage)
    )
);
