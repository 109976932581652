/**
 * File responsible for the content when clicking `Allocate to invoice` in sales orders management pages.
 */

import { Drawer } from 'antd';
import React, { } from 'react';
import { Payment } from '../../store/payments/types';
import { RemittanceAdvice } from '../../store/remittanceAdvices/types';
import { getTranslatedText } from '../../utils/commonFunctions';
import { SalesOrder } from '../../store/salesOrders/types';
import CreateSalesOrderItemManuallyDrawerContentWrapper from './CreateSalesOrderItemManuallyDrawerContent';


interface IProps {
    readonly visible: boolean;
    readonly isEditMode: boolean;
    readonly editSalesOrder?: Partial<SalesOrder>;
    readonly existingSalesOrderList?: Partial<SalesOrder>[];
    readonly closePanel?: (refetchList?: boolean) => void;
    readonly form?: any;
    readonly handleAddSalesOrder?: (salesOrder: Partial<SalesOrder>) => void;
    readonly handleEditSalesOrder?: (salesOrder: Partial<SalesOrder>) => void;
    readonly disableCustomer?: boolean;
    readonly payment?: Payment;
    readonly remittanceAdvice?: RemittanceAdvice;
}

const CreateSalesOrderItemManuallyPanel: React.FC<IProps> = ({
    visible,
    isEditMode,
    editSalesOrder,
    existingSalesOrderList,
    closePanel,
    form,
    handleAddSalesOrder,
    handleEditSalesOrder,
    disableCustomer,
    payment
}: IProps) => {
    
    /**
     * Function called when `Cancel` button is clicked inside Reconcile report panel.
     */
    const handleClosePanel = () => {
        if (closePanel) closePanel();
    };

    return (
        <Drawer
            title={getTranslatedText(`${isEditMode ? 'Edit' : 'Add'} Sales Order`)}
            width={800}
            onClose={handleClosePanel}
            visible={visible}
            className="add-sales-order-item-manually-panel"
            closable={false}
            maskClosable={false}
        >
            <CreateSalesOrderItemManuallyDrawerContentWrapper
                visible={visible}
                isEditMode={isEditMode}
                closePanel={handleClosePanel}
                editSalesOrder={editSalesOrder}
                handleAddSalesOrder={ handleAddSalesOrder }
                handleEditSalesOrder={ handleEditSalesOrder }
                existingSalesOrderList={existingSalesOrderList}
                disableCustomer={disableCustomer}
                payment={payment}
            />
        </Drawer>
    );
};

export default CreateSalesOrderItemManuallyPanel;