import update from 'immutability-helper';
import { concat, get, isEqual, keyBy, merge, uniqWith, values } from 'lodash';
import { Reducer } from 'redux';
import { DETAILS_TAB, SALES_ORDERS_PAGE } from '../../config/tableAndPageConstants';
import { conversationTableFilterOptions } from '../../constants/common';
import {
    salesOrdersChangesTableFilterOptions,
    salesOrdersSortByOptions,
    salesOrdersStateFilterOptions,
} from '../../constants/salesOrdersSortAndFilters';
import { SalesOrdersActionTypes, SalesOrdersState } from './types';

// Type-safe initialState!
export const initialState: SalesOrdersState = {
    loading: false,
    errorMessages: [],
    data: [],
    pageData: {
        pageSize: SALES_ORDERS_PAGE.pageSize,
        currentPage: 0,
        hasNextPage: false,
    },
    filters: {
        InvoiceNumber: '',
        SalesOrderNumber: '',
        Customer: '',
        CustomerCode: '',
        CreatedDateMin: '',
        CreatedDateMax: '',
        DueDateMin: '',
        DueDateMax: '',
        AmountType: '',
        AmountValue: '',
        AmountOperator: '',
    },
    tableFilter: undefined,
    salesOrderState: get(salesOrdersStateFilterOptions, `${0}.value`),
    sortBy: salesOrdersSortByOptions[0].value,
    sortAscending: true,
    activeData: {
        record: {},
        loading: false,
        selectedId: null,
        errorMessages: [],
        conversation: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            conversationTableFilter: get(
                conversationTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
        salesOrderChanges: {
            loading: false,
            errorMessages: [],
            data: [],
            pageData: {
                pageSize: DETAILS_TAB.SALES_ORDER_CHANGES_TIMELINE.pageSize,
                currentPage: 0,
                hasNextPage: false,
            },
            filters: {},
            salesOrderChangesTableFilter: get(
                salesOrdersChangesTableFilterOptions,
                '0.value'
            ),
            sortBy: 'Created Date Time',
            sortAscending: false,
        },
    },
    compactView: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SalesOrdersState> = (state = initialState, action) => {
    switch (action.type) {
        case SalesOrdersActionTypes.GET_SALES_ORDERS_FOR_ORGANISATION_REQUEST:
        case SalesOrdersActionTypes.GET_SALES_ORDERS_REQUEST: {
            return { ...state, loading: true };
        }
        case SalesOrdersActionTypes.GET_SALES_ORDERS_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.data, action.payload.data),
                        isEqual
                    );
                }
            }

            return {
                ...state,
                loading: false,
                data: newDataState,
                pageData: action.payload.pageData,
                errorMessages: initialState.errorMessages,
            };
        }
        case SalesOrdersActionTypes.GET_SALES_ORDERS_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                errorMessages: action.payload,
            };
        }
        case SalesOrdersActionTypes.UPDATE_SALES_ORDERS_FILTERS: {
            return { ...state, filters: action.payload };
        }
        case SalesOrdersActionTypes.UPDATE_SALES_ORDERS_SORT_BY_AND_STATE: {
            return {
                ...state,
                sortBy: action.payload.sortBy,
                sortAscending: action.payload.sortAscending,
                salesOrderState: action.payload.salesOrderState,
            };
        }
        case SalesOrdersActionTypes.UPDATE_SALES_ORDERS_TABLE_FILTER_STATE: {
            return {
                ...state,
                tableFilter: action.payload,
            };
        }
        case SalesOrdersActionTypes.CLEAR_SALES_ORDERS_STATE_ALL_TABLE_FILTERS: {
            return {
                ...state,
                filters: initialState.filters,
                sortBy: initialState.sortBy,
                sortAscending: initialState.sortAscending,
                salesOrderState: initialState.salesOrderState,
                tableFilter: initialState.tableFilter,
            };
        }
        case SalesOrdersActionTypes.CLEAR_SALES_ORDERS_STATE_DATA: {
            return {
                ...initialState,
            };
        }
        case SalesOrdersActionTypes.CLEAR_SALES_ORDERS_LIST_DATA: {
            return {
                ...state,
                loading: false,
                data: [],
                pageData: initialState.pageData,
                errorMessages: initialState.errorMessages,
            };
        }

        //Single Record
        case SalesOrdersActionTypes.SET_SALES_ORDER_SELECTED_ID_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                    },
                },
            });

            return newState;
        }

        case SalesOrdersActionTypes.SET_SALES_ORDER_SELECTED_ID_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return newState;
        }
        case SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_DATA_REQUEST:
        case SalesOrdersActionTypes.GET_SALES_ORDER_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: true,
                    },
                },
            });

            return newState;
        }

        case SalesOrdersActionTypes.GET_SALES_ORDER_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages: initialState.activeData.errorMessages,
                    },
                },
            });

            return newState;
        }

        case SalesOrdersActionTypes.GET_SALES_ORDER_DATA_ERROR: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        record: initialState.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return newState;
        }

        case SalesOrdersActionTypes.CLEAR_SALES_ORDER_DATA_SUCCESS: {
            return {
                ...state,
                activeData: initialState.activeData,
            };
        }

        // For conversation list reducers
        case SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_CONVERSATION_REQUEST:
        case SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_REQUEST: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: true,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.conversation.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.conversation.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages:
                        initialState.activeData.conversation.errorMessages,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_ERROR: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    loading: false,
                    data: initialState.activeData.conversation.data,
                    errorMessages: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.UPDATE_SALES_ORDER_CONVERSATION_FILTERS: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    filters: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.UPDATE_SALES_ORDER_CONVERSATION_TABLE_FILTER: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    conversationTableFilter: action.payload,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.UPDATE_SALES_ORDER_CONVERSATION_SORT_BY: {
            const newConversationState = update(state.activeData.conversation, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            const newActiveData = update(state.activeData, {
                conversation: { $set: newConversationState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        // For salesOrder changes list reducers
        case SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_CHANGES_REQUEST:
        case SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_REQUEST: {
            const newSalesOrderChangesState = update(
                state.activeData.salesOrderChanges,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                salesOrderChanges: { $set: newSalesOrderChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.activeData.salesOrderChanges.data, {
                    $set: action.payload.data,
                });
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeData.salesOrderChanges.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newSalesOrderChangesState = update(
                state.activeData.salesOrderChanges,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeData.salesOrderChanges
                                .errorMessages,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                salesOrderChanges: { $set: newSalesOrderChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_ERROR: {
            const newSalesOrderChangesState = update(
                state.activeData.salesOrderChanges,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeData.salesOrderChanges.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                salesOrderChanges: { $set: newSalesOrderChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.UPDATE_SALES_ORDER_CHANGES_FILTERS: {
            const newSalesOrderChangesState = update(
                state.activeData.salesOrderChanges,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                salesOrderChanges: { $set: newSalesOrderChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.UPDATE_SALES_ORDER_CHANGES_TABLE_FILTER: {
            const newSalesOrderChangesState = update(
                state.activeData.salesOrderChanges,
                {
                    $merge: {
                        salesOrderChangesTableFilter: action.payload,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                salesOrderChanges: { $set: newSalesOrderChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.UPDATE_SALES_ORDER_CHANGES_SORT_BY: {
            const newSalesOrderChangesState = update(
                state.activeData.salesOrderChanges,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newActiveData = update(state.activeData, {
                salesOrderChanges: { $set: newSalesOrderChangesState },
            });

            return {
                ...state,
                activeData: newActiveData,
            };
        }

        case SalesOrdersActionTypes.SALES_ORDER_SET_TABLE_COMPACT_VIEW: {
            return {
                ...state,
                compactView: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as salesOrdersReducer };
