import Amplify, { API, Auth } from 'aws-amplify';
import { forEach, get, includes } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import update from 'immutability-helper';
import { ApplicationState } from '..';
import config, { generateAmplifyConfig } from '../../AmplifyConfig';
import {
    API_NAME,
    DEFAULT_REGION_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    checkShouldRequestRefetch,
    getRegionConfigFromList,
} from './../../utils/commonFunctions';
import {
    getIdentityProviderConfigAction,
    getRegionKeyConfigAction,
    getRegionSettingsConfigAction,
    loginUserAction,
    updateIdentityProviderConfigAction,
    updateRegionKeyConfigAction,
    updateRegionSettingsConfigAction,
} from './actions';
import { AuthActionTypes } from './types';
import { getCurrentUser } from '../users/sagas';
import { store } from '../..';

/**
 * Function used in useSelector for getting the region setting config.
 */
export const getRegionSettingConfig = (state: ApplicationState) =>
    state.auth.regionSettingsConfig;

/**
 * Function used in useSelector for getting the region key config.
 */
export const getRegionKeyConfig = (state: ApplicationState) =>
    state.auth.regionKeyConfig;

/**
 * Function used in useSelector for getting the identity provider config.
 */
export const getIdentityProviderConfig = (state: ApplicationState) =>
    state.auth.identityProviderConfig;

/**
 * Function called for changing the endpoints (multi-region setup).
 */
function* handleChangeEndpoints({ payload: sagaPayload }: any) {
    try {
        let regionKeyConfig: DynamicObject[] = yield select(getRegionKeyConfig);
        let regionSettingsConfig: DynamicObject[] = yield select(getRegionSettingConfig);
        const config = getRegionConfigFromList(
            get(sagaPayload, 'Company.Region', DEFAULT_REGION_NAME),
            regionKeyConfig,
            regionSettingsConfig
        );

        const amplifyConfig: DynamicObject = yield generateAmplifyConfig(config);
        yield Amplify.configure(amplifyConfig);

        // To call async functions, use redux-saga's `call()`.
        yield call([API, 'post'], API_NAME, '/core/activatesystems', {});

        return;
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

let refetchCount = 0;

/**
 * Function called for getting the region key config from the json file.
 */
function* handleGetRegionSettingsConfig() {
    try {
        const res: DynamicObject = yield call(
            fetch,
            `${get(config, 'API.endpoints.0.endpoint')}/region`
        );
        const responseData: DynamicObject = yield res.json();
        const regionSettingConfig = get(responseData, 'Settings');
        refetchCount = 0;
        yield put(updateRegionSettingsConfigAction(regionSettingConfig));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRegionSettingsConfigAction());
        } else {
            console.log('Error trying to get region settings', err);
        }
    }
}

/**
 * Function called for getting the region key config from the json file.
 */
function* handleGetRegionKeyConfig() {
    try {
         const jsonResponse: DynamicObject = yield call(
             fetch,
             `${window.location.origin}/multi-region-config.json`
         );
         const regionKeyConfig: DynamicObject[] = yield jsonResponse.json();
        refetchCount = 0;
        yield put(updateRegionKeyConfigAction(regionKeyConfig));
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRegionKeyConfigAction());
        } else {
            console.log('Error trying to get region key config', err);
        }
    }
}

/**
 * Function called for getting the region key config from the json file.
 */
function* handleGetIdentityProviderConfig() {
    try {
         const jsonResponse: DynamicObject = yield call(
             fetch,
             `${window.location.origin}/identity-provider-config.json`
         );
         const identityProviderConfig: DynamicObject[] = yield jsonResponse.json();
        refetchCount = 0;
        yield put(updateIdentityProviderConfigAction(identityProviderConfig));
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getIdentityProviderConfigAction());
        } else {
            console.log('Error trying to get region key config', err);
        }
    }
}

function* handleGenerateMfaSoftwareToken({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/mfa/generate/software/token',
            {}
        );

        if (callback) {
            const response = {
                IsSuccess: true,
                QrCode: get(res, 'QrCode')
            };

            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}
        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.');
        //}
    }
}

function* handleSignInWithOtherProviders({ payload: sagaPayload }: any) {
    const { provider } = sagaPayload;
    const credentials: DynamicObject = yield Auth.federatedSignIn({ customProvider: provider });
    yield credentials;
}

function* handleVerifyMfaSoftwareToken({ payload: sagaPayload }: any) {
    const { callback, payload } = sagaPayload;
    const UserCode = get(payload, 'UserCode');

    const handleError = (err: any) => {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            const Messages = get(returnData, 'Messages');
            forEach(Messages, (message, idx) => {
                if (includes(message, 'Code mismatch')) {
                    Messages[idx] = 'Invalid OTP code';
                }
            });
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }

    try {
        const currentUser: DynamicObject = yield select(getCurrentUser);
        // To call async functions, use redux-saga's `call()`.
        yield call(Auth.verifyTotpToken, currentUser, UserCode);

        // Could not use `call` since there's a strange error using that way
        Auth.setPreferredMFA(currentUser, 'TOTP').then((preferredMFA) => {
            const newUserData = update(currentUser, {
                $merge: {
                    preferredMFA
                },
            });

            store.dispatch(loginUserAction({
                currentUser: newUserData,
                isAuth: true,
            }));

            if (callback) callback({
                IsSuccess: true
            });
        }).catch(handleError);
    } catch (err) {
        handleError(err)
    }
}

function* handleUseSmsMfa({ payload }: any) {
    const { callback } = payload;

    const handleError = (err: any) => {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            const Messages = get(returnData, 'Messages');
            forEach(Messages, (message, idx) => {
                if (includes(message, 'Code mismatch')) {
                    Messages[idx] = 'Invalid OTP code';
                }
            });
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }

    try {
        const currentUser: DynamicObject = yield select(getCurrentUser);
        // To call async functions, use redux-saga's `call()`.
        // Could not use `call` since there's a strange error using that way
        Auth.setPreferredMFA(currentUser, 'SMS').then((preferredMFA) => {
            const newUserData = update(currentUser, {
                $merge: {
                    preferredMFA
                },
            });

            store.dispatch(loginUserAction({
                currentUser: newUserData,
                isAuth: true,
            }));

            if (callback) callback({
                IsSuccess: true
            });
        }).catch(handleError);
    } catch (err) {
        handleError(err)
    }
}

function* watchChangeEndpoints() {
    yield takeLatest(AuthActionTypes.CHANGE_ENDPOINTS, handleChangeEndpoints);
}

function* watchGetRegionSettingsConfig() {
    yield takeLatest(
        AuthActionTypes.GET_REGION_SETTINGS_CONFIG,
        handleGetRegionSettingsConfig
    );
}

function* watchGetRegionKeyConfig() {
    yield takeLatest(
        AuthActionTypes.GET_REGION_KEY_CONFIG,
        handleGetRegionKeyConfig
    );
}

function* watchGenerateMfaSoftwareToken() {
    yield takeLatest(
        AuthActionTypes.GENERATE_MFA_SOFTWARE_TOKEN,
        handleGenerateMfaSoftwareToken
    );
}

function* watchVerifyMfaSoftwareToken() {
    yield takeLatest(
        AuthActionTypes.VERIFY_MFA_SOFTWARE_TOKEN,
        handleVerifyMfaSoftwareToken
    );
}

function* watchSignInWithOtherProviders() {
    yield takeLatest(
        AuthActionTypes.SIGN_IN_WITH_OTHER_PROVIDERS,
        handleSignInWithOtherProviders
    );
}

function* watchUseSmsMfa() {
    yield takeLatest(
        AuthActionTypes.USE_SMS_MFA,
        handleUseSmsMfa
    );
}

function* watchGetIdentityProviderConfig() {
    yield takeLatest(
        AuthActionTypes.GET_IDENTITY_PROVIDER_CONFIG,
        handleGetIdentityProviderConfig
    );
}


// We can also use `fork()` here to split our saga into multiple watchers.
function* authSaga() {
    yield all([
        fork(watchChangeEndpoints),
        fork(watchGetRegionSettingsConfig),
        fork(watchGetRegionKeyConfig),
        fork(watchGenerateMfaSoftwareToken),
        fork(watchVerifyMfaSoftwareToken),
        fork(watchUseSmsMfa),
        fork(watchSignInWithOtherProviders),
        fork(watchGetIdentityProviderConfig)
    ]);
}

export default authSaga;
