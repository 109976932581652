/**
 * Component for handling the Auth actions for user.
 */

import Auth from '@aws-amplify/auth';
import { Modal, notification } from 'antd';
import Cookies from 'js-cookie';
import { get, isFunction } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    IODM_COMPANY_STORAGE_NAME,
    initialPrimaryColor,
    TOKEN_NAME,
    TOKEN_NAME_EXPIRY,
    IODM_ORGANISATION_STORAGE_NAME,
    IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME,
    IODM_LOGIN_WITH_HOSTED_UI,
} from '../../config/config';
import { UserJWTTokenAttribute } from '../../constants/authUserAttributes';
import {
    loginUserAction,
    logoutUserAction,
    resetReduxStateAction,
} from '../../store/auth/actions';
import {
    addDaysToDate,
    getBooleanIndicatorIfInactiveWithinPeriod,
    updateLessVariables,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { getFederatedDefaultConfig } from '../../AmplifyConfig';
import { Hub } from 'aws-amplify';
export const withAuthHandler = (WrappedComponent: any) => {
    interface IProps {
        history: { push: (path: string) => void };
    }
    const AuthHandler: React.FC<IProps> = (props: IProps) => {
        const dispatch = useDispatch();

        /**
         * HOC function for logging in the user.
         * @param userData
         */
        const loginUser = (userData: DynamicObject) => {
            const isInactive = getBooleanIndicatorIfInactiveWithinPeriod();
            if (isInactive) {
                logoutUser();
            } else {
                Cookies.set(TOKEN_NAME, get(userData, UserJWTTokenAttribute), {
                    path: '/',
                    expires: addDaysToDate(new Date(), TOKEN_NAME_EXPIRY),
                });
                dispatch(
                    loginUserAction({
                        currentUser: userData,
                        isAuth: true,
                    })
                );
            }
        };

        const clearAppState = () => {
            Cookies.remove(TOKEN_NAME, { path: '/' });
            localStorage.removeItem(IODM_COMPANY_STORAGE_NAME);
            localStorage.removeItem(IODM_PREVIEW_CUSTOMIZATION_STORAGE_NAME);
            localStorage.removeItem(IODM_ORGANISATION_STORAGE_NAME);
            dispatch(logoutUserAction());
            dispatch(resetReduxStateAction());
            Modal.destroyAll();
            notification.destroy();
            updateLessVariables({
                '@custom-primary-color': initialPrimaryColor,
            });
        };

        const clearSSOState = () => {
            clearAppState();
            localStorage.removeItem(IODM_LOGIN_WITH_HOSTED_UI);
            sessionStorage.clear();
            document.cookie.split(";").forEach((cookie) => {
                const cookieName = cookie.split("=")[0].trim();
                document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
            });
        };

        /**
         * HOC function for logging out the user.
         * @param callback
         */
        const logoutUser = (callback?: () => void) => {
            try {
                if(localStorage.getItem(IODM_LOGIN_WITH_HOSTED_UI) === "true") {
                    setTimeout(() => {
                        clearSSOState();
                        const amplifyConfig = getFederatedDefaultConfig();
                        const oauth = get(amplifyConfig, 'Auth.oauth');
                        let logoutUrl = '/';
                        
                        if(oauth) {
                            logoutUrl = `https://${oauth.domain}/logout?client_id=${oauth.client_id}&logout_uri=${encodeURIComponent(oauth.redirectSignOut)}`;
                        }
                        
                        window.location.href = logoutUrl;
                    }, 500);
                }
                else {
                    Auth.signOut({ global: true })
                    .then(() => {
                        clearAppState();
                        if (callback && isFunction(callback)) callback();
                        if (props.history) {
                            props.history.push('/');
                        }
                    })
                    .catch(error => {
                        console.error('Logout error:', error);
                    });
                }
            }
            catch (error) {
                console.error('Logout error:', error);
            }
            
        };

        return (
            <WrappedComponent
                {...props}
                loginUser={loginUser}
                logoutUser={logoutUser}
            />
        );
    };

    return withRouter(AuthHandler);
};
