import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    fork,
    put,
    takeLatest,
    delay,
    select,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { DETAILS_TAB, SALES_ORDERS_PAGE } from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import queries from '../../graphql/queries.graphql';
import {
    removeAppliedFiltersForApiRequest,
    getSortFieldsWithCustomFields,
    checkShouldRequestRefetch,
    formatDateToDateObjectUTC,
    getRegionConfigFromList,
    getGraphqlQueryString,
    getCompanyFlagValue,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import {
    getSalesOrderConversationErrorAction,
    getSalesOrderConversationSuccessAction,
    getSalesOrderDataErrorAction,
    getSalesOrderDataSuccessAction,
    getSalesOrdersErrorAction,
    getSalesOrdersSuccessAction,
    setSelectedSalesOrderIdSuccessAction,
    getSalesOrdersRequestAction,
    getSalesOrderChangesSuccessAction,
    getSalesOrderChangesErrorAction,
    getSalesOrdersForOrganisationRequestAction,
    getSalesOrdersBySalesOrderCodesErrorAction,
} from './actions';
import { SalesOrdersActionTypes } from './types';
import { getRegionKeyConfig, getRegionSettingConfig } from '../auth/sagas';
import { Auth } from 'aws-amplify';
import { AtbViewType } from '../../constants/settings';

export const getSalesOrderData = (state: ApplicationState) =>
    state.salesOrders.activeData;

export const getSelectedSalesOrderId = (state: ApplicationState) =>
    state.salesOrders.activeData.selectedId;

export const getSalesOrdersPageData = (state: ApplicationState) =>
    state.salesOrders.pageData;

let refetchCount = 0;
/**
 * Function that fetches the sales order list - api connection.
 * @param param0
 */
function* handleGetSalesOrdersRequest({ payload }: any) {
    const errorMessage = 'Error fetching sales orders list. Please try again later.';
    try {
        const {
            filters,
            salesOrderState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            isPaymentPlanEnabled,
            excludeInvoices
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true
        );

  

        const sortObject = getSortFieldsWithCustomFields(sortBy);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_SALES_ORDERS_FOR_COMPANY, {
                ...cleanFilters,
                SalesOrderState: salesOrderState,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * SALES_ORDERS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                IsMultipleWorkflow: usingMultipleWorkflow,
                UsingCustomerWorkflow: usingCustomerWorkflow,
                PaymentPlanEnabled: isPaymentPlanEnabled,
                ExcludeInvoices: excludeInvoices
            })
        );

        const { SalesOrders } = get(res.data, 'GetSalesOrdersForCompany');
        if (SalesOrders) {
            const responsePayload = {
                data: SalesOrders,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(SalesOrders.length < pageSize) &&
                        !(pageSize < SALES_ORDERS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getSalesOrdersSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getSalesOrdersRequestAction(payload));
        } else {
            yield put(getSalesOrdersErrorAction([errorMessage]));
        }
    }
}

function* handleGetSalesOrdersForOrganisationRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching sales order list. Please try again later.';
    try {
        const {
            filters,
            salesOrderState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
            region,
            conversionCurrency,
            organisationCurrencies,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice'
        );

        const sortObject = getSortFieldsWithCustomFields(sortBy);

        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_SALES_ORDERS_FOR_ORGANISATION;
        const variables = {
            SalesOrderState: salesOrderState,
            ...cleanFilters,
            // SortField: sortBy,
            ...sortObject,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * SALES_ORDERS_PAGE.pageSize,
            IsCloudImportType: isUsingCloudImportType,
            UsingCustomerWorkflow: usingCustomerWorkflow,
            IsMultipleWorkflow: usingMultipleWorkflow,
            ConversionCurrency: conversionCurrency,
            OrganisationCurrencies: organisationCurrencies
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { SalesOrders } = get(res.data, 'GetSalesOrdersForOrganisation');
        if (SalesOrders) {
            const responsePayload = {
                data: SalesOrders,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(SalesOrders.length < pageSize) &&
                        !(pageSize < SALES_ORDERS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getSalesOrdersSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getSalesOrdersForOrganisationRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getSalesOrdersErrorAction([errorMessage]));
        }
    }
}

/**
 * Function that calls the API for getting the sales order details based on the given Id.
 * @param param0
 */
function* handleGetSalesOrderDataRequest({
    payload: { salesOrderId },
}: any) {
    const errorMessage =
        'Error fetching sales order details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_SALES_ORDER_DETAILS_FOR_COMPANY, {
                SalesOrderId: salesOrderId
            })
        );

        const SalesOrder = get(res.data, 'GetSalesOrderDetailsForCompany.SalesOrder');
        if (SalesOrder) {
            const responsePayload = {
                record: SalesOrder,
            };

            yield put(getSalesOrderDataSuccessAction(responsePayload));
        } else {
            yield put(getSalesOrderDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSalesOrderDataErrorAction([errorMessage]));
    }
}

/**
 * Function calling the API for fetching the sales order data based on the given id.
 * @param param0
 */
function* handleGetOrganisationSalesOrderDataRequest({
    payload: {
        invoiceId: invoiceId,
        region,
        isUsingCloudImportType
    },
}: any) {
    const errorMessage =
        'Error fetching sales order details. Please try again later.';
    try {
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        const isCalendarView: boolean = yield select(
            (state: ApplicationState) => getCompanyFlagValue(state, AtbViewType.CalendarView)
        );
        let res: DynamicObject;
        let variables = {
            SalesOrderId: invoiceId,
            IsCloudImportType: isUsingCloudImportType,
            IsCalendarView: isCalendarView
        };
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(queries.GET_SALES_ORDER_DETAILS_FOR_ORGANISATION),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_SALES_ORDER_DETAILS_FOR_ORGANISATION, variables)
            );
        }
        const SalesOrder = get(res.data, 'GetSalesOrderDetailsForOrganisation');

        if (SalesOrder) {
            const responsePayload = {
                record: SalesOrder,
            };

            yield put(getSalesOrderDataSuccessAction(responsePayload));
        } else {
            yield put(getSalesOrderDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSalesOrderDataErrorAction([errorMessage]));
    }
}


/**
 * Function that sets the selected sales order id in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedSalesOrderIdRequest({ payload }: any) {
    const { salesOrderId, callback } = payload;
    yield put(setSelectedSalesOrderIdSuccessAction(salesOrderId));
    callback();
}

/**
 * Function that fetches the conversation list of an sales order.
 * @param param0
 */
function* handleGetSalesOrderConversationRequest({ payload }: any) {
    const errorMessage = `Error fetching sales order's conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getSalesOrderConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSalesOrderConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given customer.
 * @param param0
 */
function* handleGetOrganisationSalesOrderConversationRequest({
    payload: sagaPayload
}: any) {
    const errorMessage = `Error fetching sales order's conversation list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'invoice',
            true
        );
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_CONVERSATION_LINES_FOR_ORGANISATION;

        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { ConversationLines } = get(res.data, 'GetConversationLinesForOrganisation');
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getSalesOrderConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSalesOrderConversationErrorAction([errorMessage]));
    }
}

/**
 * Function called for adding a comment in the sales order conversation list.
 * @param param0
 */
function* handleSalesOrderAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, invoiceIds, excludeInvoices, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'invoice'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        InvoiceManagementFilter: {
            ...cleanFilters,
            InvoiceIds: invoiceIds,
            ExcludeInvoices: excludeInvoices,
            Type: "SalesOrder"
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/invoicecomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (invoiceIds.length === 1 && excludeInvoices === false) {
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const invoicesUpdated: DynamicObject[] = map(
                    invoiceIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getSalesOrdersPageData);
                yield put(
                    getSalesOrdersSuccessAction({
                        data: invoicesUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for adding comment to the customer's conversation list.
 * @param param0
 */
function* handleOrganisationSalesOrderAddCommentRequest({ payload: sagaPayload }: any) {
    const { payload, region } = sagaPayload;
    const { filter, invoiceIds, excludeInvoices, comment, callback } =
        payload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'invoice'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const postPayload = {
        InvoiceManagementFilter: {
            ...cleanFilters,
            InvoiceIds: invoiceIds,
            ExcludeInvoices: excludeInvoices,
            Type: "SalesOrder"
        },
        Comment: comment,
    };

    try {
        let apiUrl: string | undefined = undefined;

        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');

            yield fetch(
                `${apiUrl}/conversation/save/invoicecomment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify(postPayload),
                }
            );
        }
        else {
            yield call(
                [API, 'post'],
                API_NAME,
                '/conversation/save/invoicecomment',
                {
                    body: postPayload,
                }
            );
        }
        
        if (callback) {
            let RefetchList = true;
            if (invoiceIds.length === 1 && excludeInvoices === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const invoicesUpdated: DynamicObject[] = map(
                    invoiceIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getSalesOrdersPageData);
                yield put(
                    getSalesOrdersSuccessAction({
                        data: invoicesUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for fetching the invoiceChanges list of a given sales order.
 * @param param0
 */
function* handleGetSalesOrderChangesRequest({ payload }: any) {
    const errorMessage = `Error fetching sales order changes list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CHANGE_LINES_FOR_COMPANY, {
                ...cleanFilters,
                PageSize: pageSize,
                Skip:
                    currentPage * DETAILS_TAB.SALES_ORDER_CHANGES_TIMELINE.pageSize,
            })
        );

        const { ChangeLines } = get(res.data, 'GetChangeLinesForCompany');

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.SALES_ORDER_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getSalesOrderChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSalesOrderChangesErrorAction([errorMessage]));
    }
}


/**
 * Function for fetching the sales order Changes list of a given sales order.
 * @param param0
 */
function* handleGetOrganisationSalesOrderChangesRequest({
    payload: sagaPayload
}: any) {
    const errorMessage = `Error fetching sales order changes list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {
        const { filters, pageSize, currentPage } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters, true);

        let apiUrl: string | undefined = undefined;
        
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }
        
        let res: DynamicObject;
        const usedQuery = queries.GET_CHANGE_LINES_FOR_ORGANISATION;

        const variables = {
            ...cleanFilters,
            PageSize: pageSize,
            Skip:
                currentPage * DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize,
        };

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { ChangeLines } = get(res.data, 'GetChangeLinesForOrganisation');

        if (ChangeLines) {
            const responsePayload = {
                data: ChangeLines,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangeLines.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CREDIT_CHANGES_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getSalesOrderChangesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getSalesOrderChangesErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the sales order custom fields for a company.
 * @param param0
 */
function* handleGetSalesOrderCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_SALES_ORDER_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetSalesOrderCustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/*
 * Function for creating the sales order manually.
 * @param param0
 */
function* handleCreateSalesOrderManuallyRequest({ payload: sagaPayload }: any) {
    const { SalesOrders, callback } = sagaPayload;
    
    const payload = {SalesOrders};

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/salesorder/update',
            {
                body: payload
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for editing the sales order manually.
 * @param param0
 */
function* handleEditSalesOrderManuallyRequest({ payload: sagaPayload }: any) {
    const { SalesOrders, callback } = sagaPayload;
    
    const payload = {SalesOrders: [SalesOrders], IsEdit: true};

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/salesorder/update',
            {
                body: payload
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleDeleteSalesOrderManuallyRequest({ payload: sagaPayload }: any) {
    const { filter, invoiceIds, excludeInvoices, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(filter, true);
    const payload = {
        SalesOrderManagementFilter: {
            ...cleanFilters,
            InvoiceIds: invoiceIds,
            ExcludeInvoices: excludeInvoices,
        }
    };

    try {
        const apiResponse: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/salesorder/update',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: get(apiResponse, 'Messages')
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function calling the API for fetching the credit data based on the given credit codes.
 * @param param0
 */
function* handleGetSalesOrdersBySalesOrderCodesRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    const errorMessage =
        'Error fetching sales order details. Please try again later.';
    try {
        
        const { ...InvoiceCodes } = payload;
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_SALES_ORDERS_FOR_COMPANY, {
                ...InvoiceCodes
            })
        );

        const { SalesOrders } = get(res.data, 'GetSalesOrdersForCompany');
        
        refetchCount = 0;
        if (callback) callback(SalesOrders);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            //yield put(getCreditsByCreditCodesRequestAction(payload));
        } else {
            yield put(getSalesOrdersBySalesOrderCodesErrorAction([errorMessage]));
        }
    }
}


// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetSalesOrdersRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDERS_REQUEST,
        handleGetSalesOrdersRequest
    );
}

function* watchGetSalesOrdersForOrganisationRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDERS_FOR_ORGANISATION_REQUEST,
        handleGetSalesOrdersForOrganisationRequest
    );
}

function* watchGetSalesOrderDataRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDER_DATA_REQUEST,
        handleGetSalesOrderDataRequest
    );
}

function* watchGetOrganisationSalesOrderDataRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_DATA_REQUEST,
        handleGetOrganisationSalesOrderDataRequest
    );
}

function* watchSetSelectedSalesOrderIdRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.SET_SALES_ORDER_SELECTED_ID_REQUEST,
        handleSetSelectedSalesOrderIdRequest
    );
}

function* watchGetSalesOrderConversationRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDER_CONVERSATION_REQUEST,
        handleGetSalesOrderConversationRequest
    );
}

function* watchGetOrganisationSalesOrderConversationRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_CONVERSATION_REQUEST,
        handleGetOrganisationSalesOrderConversationRequest
    );
}

function* watchSalesOrderAddCommentRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.SALES_ORDER_ADD_COMMENT_REQUEST,
        handleSalesOrderAddCommentRequest
    );
}

function* watchOrganisationSalesOrderAddCommentRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.ORGANISATION_SALES_ORDER_ADD_COMMENT_REQUEST,
        handleOrganisationSalesOrderAddCommentRequest
    );
}

function* watchGetSalesOrderChangesRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDER_CHANGES_REQUEST,
        handleGetSalesOrderChangesRequest
    );
}

function* watchGetSalesOrderCustomFieldsForCompanyRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetSalesOrderCustomFieldsForCompanyRequest
    );
}

function* watchGetOrganisationSalesOrderChangesRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_ORGANISATION_SALES_ORDER_CHANGES_REQUEST,
        handleGetOrganisationSalesOrderChangesRequest
    );
}

function* watchCreateSalesOrderManuallyRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.CREATE_SALES_ORDER_MANUALLY_REQUEST,
        handleCreateSalesOrderManuallyRequest
    );
}

function* watchEditSalesOrderManuallyRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.EDIT_SALES_ORDER_MANUALLY_REQUEST,
        handleEditSalesOrderManuallyRequest
    );
}

function* watchDeleteSalesOrderManuallyRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.DELETE_SALES_ORDER_MANUALLY_REQUEST,
        handleDeleteSalesOrderManuallyRequest
    );
}

function* watchGetSalesOrdersBySalesOrderCodesRequest() {
    yield takeLatest(
        SalesOrdersActionTypes.GET_SALES_ORDER_BY_SALES_ORDER_CODES_REQUEST,
        handleGetSalesOrdersBySalesOrderCodesRequest
    );
}


// We can also use `fork()` here to split our saga into multiple watchers.
function* salesOrdersSaga() {
    yield all([
        fork(watchGetSalesOrdersRequest),
        fork(watchGetSalesOrdersForOrganisationRequest),
        fork(watchGetSalesOrderDataRequest),
        fork(watchSetSelectedSalesOrderIdRequest),
        fork(watchGetSalesOrderConversationRequest),
        fork(watchGetOrganisationSalesOrderConversationRequest),
        fork(watchSalesOrderAddCommentRequest),
        fork(watchGetSalesOrderChangesRequest),
        fork(watchGetSalesOrderCustomFieldsForCompanyRequest),
        fork(watchGetOrganisationSalesOrderChangesRequest),
        fork(watchGetOrganisationSalesOrderDataRequest),
        fork(watchOrganisationSalesOrderAddCommentRequest),
        fork(watchCreateSalesOrderManuallyRequest),
        fork(watchEditSalesOrderManuallyRequest),
        fork(watchDeleteSalesOrderManuallyRequest),
        fork(watchGetSalesOrdersBySalesOrderCodesRequest)

    ]);
}

export default salesOrdersSaga;
