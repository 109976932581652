/**
 * File responsible for all the UI and actions for Sales>Customers page - `/app/sales/customers`.
 */

import { Button, Col, Modal, Row, Select, Typography } from 'antd';
import {
    capitalize,
    difference,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isEqual,
    isUndefined,
    map,
    remove,
    toLower,
} from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withAccountingSystemHandler } from '../../components/common/AccountingSystemHandler';
import ActionBar from '../../components/common/ActionBar';
import AddCommentPanel from '../../components/common/AddCommentPanel';
import { customFieldIndicator } from '../../components/common/FilterBar';
import FontAwesome from '../../components/common/FontAwesome';
import { withPageViewHandler } from '../../components/common/PageViewHandler';
import QuickSearchComponent, {
    qsFilterNames,
} from '../../components/common/QuickSearchComponent';
import SendInformationPanel from '../../components/common/SendInformationPanel';
import VirtualizedList from '../../components/common/VirtualizedList';
import CustomerItemComponent from '../../components/sales/CustomerItemComponent';
import CustomerAssignUserPanel from '../../components/sales/CustomerAssignUserPanel';
import CustomerEditDrawerContent from '../../components/sales/CustomerEditDrawerContent';
import {
    CUSTOMERS_PAGE,
    CUSTOM_FIELD_TYPES,
    PAGE_NAMES_FOR_VIEW,
} from '../../config/tableAndPageConstants';
import { CompanyIdAttribute } from '../../constants/authUserAttributes';
import {
    customersATBCalculationOptions,
    customersSortByOptions,
    getCustomersFilterBarFilters,
} from '../../constants/customersSortAndFilters';
import {
    customersSummaryPageQuery,
    customersSummaryQueryName,
    defaultPageSizeForReport,
} from '../../constants/downloadToExcel';
import { ApplicationState } from '../../store';
import {
    downloadToExcelAction,
    getCustomFieldsFilterListByTypes,
} from '../../store/common/actions';
import {
    CompanyCustomFieldConfigure,
    CompanyUserRole,
} from '../../store/companies/types';
import {
    bulkSaveCustomerUpdateRequestAction,
    customerAddCommentRequestAction,
    customerSendInformationRequestAction,
    getCustomerDataRequestAction,
    getCustomersRequestAction,
    setCustomerSelectedIdRequestAction,
    updateCustomersFiltersAction,
    updateCustomersSortByAndStateAction,
    updateCustomersTableFilterAction,
    getCustomerCustomFieldsForCompanyRequestAction,
    getCustomerFirstLetterValuesRequestAction
} from '../../store/customers/actions';
import { initialState } from '../../store/customers/reducer';
import {
    getCustomerSelectedId,
    getCustomerUILabel,
} from '../../store/customers/sagas';
import {
    CustomersState,
    GetCustomersRequestPayload,
} from '../../store/customers/types';
import { getCurrentUser } from '../../store/users/sagas';
import {
    computeTableScroll,
    downloadToExcelModalResponseHandler,
    emptyPredefinedFilterOnAppliedFilters,
    getGraphqlQueryString,
    getSortFieldsWithCustomFields,
    getUsedSortByOptionsIfCloud,
    populateDownloadToExcelModalDisplayMessage,
    populateDownloadToExcelModalTitle,
    removeAppliedFiltersForApiRequest,
    replaceInstancesOfCustomerString,
    checkIfEmailIsValid,
    useCompanyFlagValue,
    getTranslatedText
} from '../../utils/commonFunctions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import SpecialisedReportsDrawer from '../../components/common/SpecialisedReportsDrawer';
import { SpecialisedReports } from '../../constants/common';
import { getRolePermissions } from '../../store/roles/sagas';
import CreateNewPaymentPlanPanelWrapper from '../../components/banking/CreateNewPaymentPlanPanel';
import {
    manualCreateNewPaymentPlanRequestAction
} from '../../store/paymentPlans/actions';
import { getManualCommunicationTemplatesAction } from '../../store/contents/actions';
import { ManualCommunicationTemplate } from '../../store/contents/types';
import CreateScheduledReportDrawer from '../../components/common/CreateScheduledReportDrawer';
import { AtbViewType } from '../../constants/settings';
import queries from '../../graphql/queries.graphql';
import InvoiceNoPaymentPlanPanel from '../../components/banking/InvoiceNoPaymentPlanPanel';


const CustomerItemDetailsDrawerComponent = lazy(
    () => import('../../components/sales/CustomerItemDetailsDrawerComponent')
);
const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);
const FilterBar = lazy(() => import('../../components/common/FilterBar'));

const { Title } = Typography;
const { Option } = Select;

let lastSelectedCompanyId: string | null = null;
let resetTableScroll = false;
let isRefetching = false;
let fetchCustomersRequired = false;
let skipListenToPreconfiguredFilter = false;
const tablePageSize = CUSTOMERS_PAGE.pageSize;
const pageName = PAGE_NAMES_FOR_VIEW.CUSTOMERS_PAGE;
let initialTableFilter: undefined | string = undefined;
interface IProps {
    readonly isUsingCloudImportType: boolean;
    readonly handlePageViewSelection: (
        tableFilterValue: string | undefined,
        applyFiltersFunction: Function,
        actionBarRefCurrent?: any,
        pageName?: string
    ) => void;
}
const CustomersManagementPage: React.FC<IProps> = ({
    isUsingCloudImportType,
    handlePageViewSelection,
}: IProps) => {
    const dispatch = useDispatch();
    
    const customerLabel = useSelector(getCustomerUILabel);

    const selectedId = useSelector(getCustomerSelectedId);
    const currentUser = useSelector(getCurrentUser);
    const rolePermissions = useSelector(getRolePermissions);
    const isDueDateView = useCompanyFlagValue(AtbViewType.DueDateView);
    const isCalendarView = useCompanyFlagValue(AtbViewType.CalendarView);

    const selectedUserCompanyLoading: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.loading
    );

    const actionBarRef: RefObject<DynamicObject | null | undefined> = useRef();

    const [customFieldsFilterList, setCustomFieldsFilterList] = useState<[]>(
        []
    );

    const [customFieldValuesList, setCustomFieldValuesList] = useState<DynamicObject[]>(
        []
    );

    const [customerValuesOption, setCustomerValuesList] = useState<DynamicObject[]>(
        []
    );
    
    const [customerDetailsDrawer, setCustomerDetailsDrawer] = useState<{
        visible: boolean;
        activeTab: undefined | string;
    }>({
        visible: false,
        activeTab: undefined,
    });

    const customersState: CustomersState = useSelector(
        (state: ApplicationState) => state.customers
    );

    const [customersTableFilter, setCustomersTableFilter] = useState<
        string | undefined
    >(
        isEqual(customersState.filters, initialState.filters)
            ? initialState.tableFilter
            : customersState.tableFilter
    );

    const [createScheduleReportPayload, setCreateScheduleReportPayload] = useState<any>();

    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );
    const { Company } = selectedUserCompany;
    const manualCommunicationEnabled: boolean | undefined = get(
        selectedUserCompany,
        'Company.EnableManualCommunication'
    );

    const IsSearchCustomFieldsByCheckBox: boolean = get(
        selectedUserCompany,
        'Company.IsSearchCustomFieldsByCheckbox'
        );
    const isPaymentPlanEnabled = get(
        selectedUserCompany,
        'Company.CompanyPaymentPlan.IsEnabled'
    );

    const [tableFilters, setTableFilters] = useState<any>(
        customersState.filters
    );

    const [tableRowSelection, setTableRowSelection] = useState<{
        selectedRowKeys: string[];
        unselectedRowKeys: string[];
    }>({
        selectedRowKeys: [],
        unselectedRowKeys: [],
    });

    const [actionBarItemsState, setActionBarItemsState] = useState<{
        editButtonLoading: boolean;
    }>({
        editButtonLoading: false,
    });

    const [templateOptions, setTemplateOptions] = useState<{
        options: ManualCommunicationTemplate[];
        loading: boolean;
    }>({
        options: [],
        loading: false,
    });

    let isCreateNewPaymentPlanAllowed = false;
    if (
        isEmpty(rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN) ||
        includes(
            rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN,
            selectedUserCompany.Role.Name
        )
    ) {
        isCreateNewPaymentPlanAllowed = tableRowSelection.selectedRowKeys.length === 1;
    }

    let isCreateBatchPaymentPlanAllowed = false;
    if (
        isEmpty(rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN) ||
        includes(
            rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN,
            selectedUserCompany.Role.Name
        )
    ) {
        isCreateBatchPaymentPlanAllowed = tableRowSelection.selectedRowKeys.length >1;
    }

    /**
     * Function to get used sort options
     */
    const getCustomersSortByOptions = () => {
        const usedSortOptions = customersSortByOptions(tableSortState.atbType === customersATBCalculationOptions[1].value, isCalendarView);
        return getUsedSortByOptionsIfCloud(
            usedSortOptions,
            isUsingCloudImportType
        );
    };

    /**
     * Function for checking if sort value is in options and set the first one if not.
     */
    const checkSortBySelectedIfAvailable = () => {
        if (!selectedUserCompany) return;
        const usedSortByOptions = getCustomersSortByOptions();
        const usedSortValues = map(
            usedSortByOptions,
            (sortOpt) => sortOpt.value
        );
        forEach(
            customFieldsFilterList,
            ({ Type, FieldName }: CompanyCustomFieldConfigure) => {
                usedSortValues.push(
                    `${customFieldIndicator}${Type}--${FieldName}`
                );
            }
        );
        if (!includes(usedSortValues, customersState.sortBy)) {
            setTimeout(() => {
                const newSortValues = {
                    ...tableSortState,
                    sortBy: initialState.sortBy,
                };

                updateTableSortStateObject(newSortValues);
                dispatch(updateCustomersSortByAndStateAction(newSortValues));
            }, 200);
        }
    };

    useEffect(checkSortBySelectedIfAvailable, [
        selectedUserCompany,
        customersState.sortBy,
        isCalendarView
    ]);

    /**
     * Function for getting the template options to be used in populating the dropdown in Send information panel
     */
    const getManualCommunicationTemplateOptions = () => {
        if (showConditions.sendInformationTo) {
            if (manualCommunicationEnabled) {
                setTemplateOptions((state) => ({
                    ...state,
                    loading: true,
                }));
                dispatch(
                    getManualCommunicationTemplatesAction((templates: ManualCommunicationTemplate[]) => {
                        setTemplateOptions(state => ({
                            ...state,
                            loading: false,
                            options: templates
                        }));
                    })
                );
            } else {
                // Reset the templates to empty for company that doesn't support manual communication feature
                setTemplateOptions((state) => ({
                    ...state,
                    options: [],
                    loading: false
                }));
            }
        }
    };

    /**
     * Function that checks if there are filters from redux for this page.
     * Returns boolean.
     */
    const getHasFiltersOnRedux = () => {
        let hasFiltersOnRedux = false;
        forEach(customersState.filters, (filterValue: any) => {
            if (!isEmpty(filterValue)) {
                hasFiltersOnRedux = true;
                return false; // terminates the foreach
            }
        });

        return hasFiltersOnRedux;
    };

    const [showConditions, setShowConditions] = useState<{
        filterBar: boolean;
        filterEllipsis: boolean;
        addCommentDrawer: boolean;
        allSelected: boolean;
        editDrawer: boolean;
        downloadToExcel: boolean;
        specialisedReportsPanel: boolean;
        sendInformationTo: boolean;
        assignUserDrawer: boolean;
        createNewPaymentPlanDrawer: boolean;
        invoiceNoPaymentPlanDrawer: boolean;
        createScheduledReport: boolean;
    }>({
        filterBar: getHasFiltersOnRedux(),
        filterEllipsis: false,
        addCommentDrawer: false,
        allSelected: false,
        editDrawer: false,
        downloadToExcel: false,
        specialisedReportsPanel: false,
        sendInformationTo: false,
        assignUserDrawer: false,
        createNewPaymentPlanDrawer: false,
        invoiceNoPaymentPlanDrawer: false,
        createScheduledReport: false
    });

    const [tableSortState, setTableSortState] = useState<{
        sortBy: string;
        atbType: number;
        sortAscending: boolean;
    }>({
        sortBy: customersState.sortBy,
        atbType: isDueDateView
            ? customersATBCalculationOptions[1].value
            : customersATBCalculationOptions[0].value,
        sortAscending: customersState.sortAscending,
    });

    const [tableCurrentPage, setTableCurrentPage] = useState<number>(
        get(customersState, 'pageData.currentPage', 0)
    );

    useEffect(getManualCommunicationTemplateOptions, [Company, manualCommunicationEnabled, showConditions]);

    /**
     * Function that prepares the payload for the fetch request (either in table or excel report).
     * @param currentPage
     * @param pageSize
     */
    const generatePayloadForRequest = (
        currentPage: number,
        pageSize: number
    ) => {
        const usingMultipleWorkflow = get(
            selectedUserCompany,
            'Company.UsingMultipleWorkflow'
        );
        const usingCustomerWorkflow = get(
            selectedUserCompany,
            'Company.UsingCustomerWorkflow'
        );
        const payload: GetCustomersRequestPayload = {
            filters: customersState.filters,
            sortBy: customersState.sortBy,
            sortAscending: customersState.sortAscending,
            pageSize,
            currentPage: currentPage,
            tableFilter: customersTableFilter,
            atbType: customersState.atbType,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        };

        return payload;
    };

    /**
     * Function that handles fetching of customers (calls an action that triggers an API call).
     * @param currentPage - page dependent on scroll
     * @param pageSize - number of items in page
     */
    const fetchCustomers = (
        currentPage = tableCurrentPage,
        pageSize = tablePageSize
    ) => {
        if (isEmpty(selectedUserCompany)) return;
        const payload = generatePayloadForRequest(currentPage, pageSize);

        if (!isRefetching) resetTableScroll = false;
        dispatch(getCustomersRequestAction(payload));
    };

    /**
     * Function called when more data from list is requested
     * by scrolling down.
     */
    const handleFetch = () => {
        if (
            isUndefined(initialTableFilter) ||
            isEmpty(selectedUserCompany) ||
            customersState.loading
        )
            return;

        if (!customersState.pageData.hasNextPage) return;

        const nextPage = tableCurrentPage + 1;
        setTableCurrentPage(nextPage);
        fetchCustomers(nextPage);
    };

    /**
     * Function that fetches the custom fields filter list
     */
    const getCustomFieldsFilterList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomFieldsFilterListByTypes(
                    [CUSTOM_FIELD_TYPES.CUSTOMER],
                    (response: any) => {
                        if (response.IsSuccess) {
                            setCustomFieldsFilterList(response.CustomFields);
                        }
                    }
                )
            );
        }
    };

    /**
     * Function that fetches the custom fields values
     */
    const getCustomFieldsValueList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let customFieldsFilterOptions: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerCustomFieldsForCompanyRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            customFieldsFilterOptions = await getCustomFieldsOptionsByKey(customFieldsFilterOptions,response.CustomFieldValues);
                            setCustomFieldValuesList(customFieldsFilterOptions)
                        }
                    }
                )
            ); 
        }          
    };

    /**
     * Function for populating the custom field options by key used (affected when UsingCustomerWorkflow is changed).
     * @param workflowKey
     * @param actionFilterOptions
     */
    const getCustomFieldsOptionsByKey = async (
        customFieldsFilterOptions: DynamicObject[],
        customFieldValuesList: DynamicObject[]
    ) => {
        forEach(
            customFieldsFilterList,
            (customFieldsOption,index) => {
                const customFieldName = get(customFieldsOption, 'FieldName');
                const customFieldLists = customFieldValuesList.filter(x => x.Name == customFieldName && x.Value != null);
                
                const childCustomFieldValues = map(customFieldLists, (cfl,index) => ({
                    label: cfl.Value,
                    value: cfl.Value,
                }));
                
                customFieldsFilterOptions.push({
                    Number:index,
                    Type: get(customFieldsOption, 'Type'),
                    FieldName: get(customFieldsOption, 'FieldName'),
                    children: childCustomFieldValues,
                    filterLoading: customersState.loading
                });
            }
        );

        return customFieldsFilterOptions;
    };

    useEffect(getCustomFieldsFilterList, [selectedUserCompany]);
    useEffect(getCustomFieldsValueList, [customFieldsFilterList]);

    const setInitialFilterATBType = () => {
        dispatch(
            updateCustomersSortByAndStateAction({
                ...tableSortState,
                atbType:
                    isDueDateView === true
                        ? customersATBCalculationOptions[1].value
                        : customersATBCalculationOptions[0].value,
            })
        );
    };

    useEffect(setInitialFilterATBType, [isDueDateView]);

    /**
     * Function called when page loads, changes in filters, and sort options,
     * or company is changed.
     */
    const callFetchCustomers = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');

        if (companyIdCognito === selectedCompanyId) {
            if (lastSelectedCompanyId !== selectedCompanyId) {
                lastSelectedCompanyId = selectedCompanyId;
                resetAllSelectedRowKeys();
                checkAllTableFiltersOnCompanySwitch();
                resetTableScrollAndPageData();
            }
            if (!selectedUserCompanyLoading) fetchCustomers(0);
        }
    };

    /**
     * Function for comparing component and redux state then setting the correct values.
     */
    const checkAllTableFiltersOnCompanySwitch = () => {
        const { filters, sortBy, sortAscending } = customersState;
        if (!isEqual(filters, tableFilters)) {
            setTableFilters(filters);
        }

        if (
            sortBy !== tableSortState.sortBy ||
            sortAscending !== tableSortState.sortAscending
        ) {
            updateTableSortStateObject({
                sortBy,
                sortAscending,
            });
        }
    };

    useEffect(callFetchCustomers, [
        customersState.sortBy,
        customersState.sortAscending,
        customersState.filters,
        customersState.atbType,
        selectedUserCompany,
    ]);

    // on Unmount
    useEffect(() => {
        return () => {
            lastSelectedCompanyId = null;
            skipListenToPreconfiguredFilter = true;
        };
    }, []);

    const selectedKeysName = 'Id';

    /**
     * Function that checks if the row is selected.
     * Specifically if `Select all` is clicked and the customer data is updated,
     * it marks all the checkbox depending on the selected value.
     */
    const checkRowSelectionState = () => {
        if (showConditions.allSelected) {
            const selectedKeys = difference(
                map(customersState.data, selectedKeysName),
                tableRowSelection.unselectedRowKeys
            );

            updateTableRowSelection({
                selectedRowKeys: selectedKeys,
            });
        }
    };

    useEffect(checkRowSelectionState, [customersState.data]);

    /**
     * Action Bar Functions
     */
    /**
     * Function that updates the redux table filters and state table filters.
     * @param filter - dropdown view value
     * @param refetch - boolean indicator if fetching of items is to be called
     */
    const changeCustomersTableFilter = (
        filter: string,
        refetch: boolean = true
    ) => {
        if (!initialTableFilter) {
            initialTableFilter = filter;
        } else {
            if (filter !== initialTableFilter) {
                updateShowConditionsObject({
                    allSelected: false,
                    filterBar: true,
                });
            }
        }

        if (filter !== customersState.tableFilter) {
            skipListenToPreconfiguredFilter = false;
        }

        setCustomersTableFilter(filter);
        resetTableScrollAndPageData();
        if (refetch && filter === customersState.tableFilter) {
            handleCustomerFilterRefresh();
        } else {
            dispatch(updateCustomersTableFilterAction(filter));
        }
    };

    /**
     * Function responsible for refetching tasks data after an update or when clicking the refresh button.
     */
    const refetchListAndResetScroll = () => {
        isRefetching = true;
        resetTableScrollAndPageData();
        fetchCustomers(0);
    };

    /**
     * Function called when refresh button is clicked (upper left near table filter dropdown).
     */
    const handleCustomerFilterRefresh = () => {
        fetchCustomersRequired = true;
        skipListenToPreconfiguredFilter = false;
        resetAllSelectedRowKeys();
        refetchListAndResetScroll();
    };

    /**
     * Listener function called when the table filter value for the dropdown at the upper left has been changed.
     */
    const listenToPreConfiguredFilter = () => {
        if (skipListenToPreconfiguredFilter) return;
        const { tableFilter } = customersState;
        if (tableFilter === initialTableFilter) {
            closeFilterBar();

            if (fetchCustomersRequired) {
                fetchCustomers(0);
                fetchCustomersRequired = false;
            }
        } else {
            handlePageViewSelection(
                tableFilter,
                applyFilters,
                actionBarRef.current,
                pageName
            );
        }
    };

    useEffect(listenToPreConfiguredFilter, [customersState.tableFilter]);

    /**
     * Function that populates the customer table filter (upper left).
     * @param menu
     */
    const populateCustomerFilterSelectDropdownRender = (
        menu: React.Component
    ) => (
        <div>
            {menu}
            {/* <Divider className="action-bar-divider" />
            <div
                className="pa-12 cursor-p"
                onMouseDown={() => console.log('Add Custom View Clicked')}
            >
                Add custom view
            </div> */}
        </div>
    );

    /**
     * Function that populates the label for button (Select all or Deselect all).
     */
    const populateSelectDeselectAllLabel = () => {
        const allDataLoaded = customersState.pageData.hasNextPage === false;

        let selectDeselectLabel = '';
        if (
            !isEmpty(customersState.data) &&
            ((tableRowSelection.unselectedRowKeys.length === 0 &&
                showConditions.allSelected) ||
                (allDataLoaded &&
                    customersState.data.length ===
                        tableRowSelection.selectedRowKeys.length))
        ) {
            selectDeselectLabel = 'Deselect';
        } else {
            selectDeselectLabel = 'Select';
        }

        return `${selectDeselectLabel} all`;
    };

    /**
     * Function called when `Select all` button is clicked.
     */
    const selectAllRows = () => {
        const selectAllCondition =
            isEmpty(tableRowSelection.selectedRowKeys) ||
            tableRowSelection.selectedRowKeys.length <
                customersState.data.length;

        updateShowConditionsObject({
            allSelected: selectAllCondition,
        });

        if (selectAllCondition) {
            const idKeys: string[] = map(customersState.data, selectedKeysName);

            updateTableRowSelection({
                selectedRowKeys: idKeys,
                unselectedRowKeys: [],
            });
        } else {
            updateTableRowSelection({
                selectedRowKeys: [],
            });
        }
    };

    /**
     * Function called when sort order button is clicked (ascending or descending).
     * @param sortAscending - boolean - true for ascending and false for descending
     */
    const changeSortOrder = async (sortAscending: boolean) => {
        await resetTableScrollAndPageData();
        dispatch(
            updateCustomersSortByAndStateAction({
                ...tableSortState,
                sortAscending,
            })
        );
    };

    const downloadDisabled =
        customersState.loading || isEmpty(customersState.data);

    /**
     * Function for populating the upper section for the ellipsis popover.
     */
    const populateFilterEllipsisPopoverTitle = () => (
        <div className="pop-action-title">
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setShowConditions((prevState: { filterBar: boolean }) => ({
                        ...showConditions,
                        filterEllipsis: false,
                        filterBar: !prevState.filterBar,
                    }));
                }}
            >
                <FontAwesome icon={['fas', 'filter']} className="mr-10" />
                {getTranslatedText('Filter')}
            </Button>
            <br />
            <Button
                type="link"
                disabled={downloadDisabled}
                onClick={() => {
                    setTableSortState((prevState: any) => {
                        const sortOrder = !prevState.sortAscending;
                        changeSortOrder(sortOrder);
                        return {
                            ...prevState,
                            sortAscending: sortOrder,
                        };
                    });
                }}
            >
                <FontAwesome
                    icon={[
                        'fas',
                        `sort-amount-${
                            customersState.sortAscending ? 'down' : 'up-alt'
                        }`,
                    ]}
                    className="mr-10"
                />
                {getTranslatedText('Change sort order')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => downloadToExcelHandler()}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Download to Excel')}
            </Button>
            <br />
            <Button
                type="link"
                onClick={createScheduledReportHandler}
                // disabled={downloadDisabled}
            >
                    <FontAwesome
                        icon={['far', 'calendar']}
                        className={`icon calendar`}
                    />
                    <FontAwesome
                        icon={['fas', 'clock']}
                        className={`icon clock`}
                    />
                <span className='icon-button-wrapper'>{getTranslatedText('Create Scheduled Report')}</span>
            </Button>
            <br />
            <Button
                type="link"
                onClick={() => {
                    updateShowConditionsObject({
                        filterEllipsis: false,
                        specialisedReportsPanel: true
                    })
                }}
                disabled={downloadDisabled}
            >
                <FontAwesome
                    icon={['fas', 'cloud-download-alt']}
                    className="mr-10"
                />
                {getTranslatedText('Specialised Reports')}
            </Button>
        </div>
    );

    /**
     * Function called when Download to Excel button is clicked.
     */
     const downloadToExcelHandler = (specializedReportType?: string) => {
        updateShowConditionsObject({
            downloadToExcel: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            atbType,
            sortBy,
            sortAscending,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer',
            true
        );
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const variables = {
            ...cleanFilters,
            ATBType: atbType,
            // SortField: sortBy,
            ...sortObject,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            UsingCustomerWorkflow: usingCustomerWorkflow,
        };

        const usedQuery = queries[customersSummaryQueryName];

        const payload = {
            Query: getGraphqlQueryString(usedQuery),
            OperationName: customersSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.CUSTOMERS_PAGE,
            SpecializedReport: specializedReportType
        };

        dispatch(downloadToExcelAction(payload, downloadToExcelModalResponse));
    };

    /**
     * Function called when create schedule report is closed.
     * @param resetChanges
     * @param saveChanges
     * @param containerRef
     */
    const onCreateScheduledReportEnd = (
        containerRef?: any
    ) => {
        updateShowConditionsObject({
            createScheduledReport: false,
        });
    };

    /**
     * Function called when Create Scheduled report button is clicked.
     */
    const createScheduledReportHandler = () => {
        updateShowConditionsObject({
            createScheduledReport: true,
            filterEllipsis: false,
        });

        const filterAndPageProps = generatePayloadForRequest(
            0,
            defaultPageSizeForReport
        );

        const {
            filters,
            atbType,
            sortBy,
            sortAscending,
            usingMultipleWorkflow,
            usingCustomerWorkflow,
        } = filterAndPageProps;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const variables = {
            ...cleanFilters,
            ATBType: atbType,
            // SortField: sortBy,
            ...sortObject,
            Ascending: sortAscending,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            UsingCustomerWorkflow: usingCustomerWorkflow,
        };

        const payload = {
            Query: customersSummaryPageQuery,
            OperationName: customersSummaryQueryName,
            Variables: JSON.stringify(variables),
            PageName: PAGE_NAMES_FOR_VIEW.CUSTOMERS_PAGE,
            title: `${capitalize(customerLabel)}`,
            Type: 1
        };

        setCreateScheduleReportPayload(payload);
    };

    /**
     * Callback function after calling the download to excel api.
     * @param param0 - response with type `ResponseModalObject`
     */
    const downloadToExcelModalResponse = ({
        IsSuccess,
        Messages,
    }: ResponseModalObject) => {
        updateShowConditionsObject({
            downloadToExcel: false,
            filterEllipsis: false,
        });
        downloadToExcelModalResponseHandler(IsSuccess, Messages);
    };

    /**
     * Function that populates the lower section of the ellipsis popover.
     */
    const populateFilterEllipsisPopoverContent = () => {
        const usedSortOptions = getCustomersSortByOptions();
        return (
            <div className="pop-action-content">
                <div className="mb-10">
                    <span>{getTranslatedText('Sort by')}</span>
                </div>
                <div>
                    <Select
                        onChange={(sortBySelected: string) =>
                            updateTableSortStateObject({
                                sortBy: sortBySelected,
                            })
                        }
                        value={tableSortState.sortBy}
                    >
                        {map(
                            usedSortOptions,
                            ({
                                label,
                                value,
                            }: {
                                label: any;
                                value: string;
                            }) => {
                                const labelUsed =
                                    replaceInstancesOfCustomerString(
                                        label,
                                        customerLabel
                                    );

                                return (
                                    <Option key={value} value={value}>
                                        {getTranslatedText(labelUsed)}
                                    </Option>
                                );
                            }
                        )}
                        {map(
                            customFieldsFilterList,
                            ({
                                Type,
                                Number: CFNumber,
                                FieldName,
                            }: CompanyCustomFieldConfigure) => (
                                <Option
                                    key={Type + CFNumber + FieldName}
                                    value={`${customFieldIndicator}${Type}--${FieldName}`}
                                >
                                    {getTranslatedText(FieldName)}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br />
                {/* <div className="mb-10">
                    <span>ATB calculation</span>
                </div>
                <div>
                    <Select
                        onChange={(atbTypeSelected: number) =>
                            updateTableSortStateObject({
                                atbType: atbTypeSelected,
                            })
                        }
                        value={tableSortState.atbType}
                    >
                        {map(
                            customersATBCalculationOptions,
                            ({
                                label,
                                value,
                            }: {
                                label: string;
                                value: number;
                            }) => (
                                <Option key={value} value={value}>
                                    {label}
                                </Option>
                            )
                        )}
                    </Select>
                </div>
                <br /> */}
                <div className="ta-right">
                    <Button
                        type="primary"
                        disabled={
                            customersState.loading ||
                            (tableSortState.sortBy === customersState.sortBy &&
                                tableSortState.atbType ===
                                    customersState.atbType)
                        }
                        onClick={applySortedByAndState}
                    >
                        {getTranslatedText('Apply')}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Filter Bar Functions
     */
    /**
     * Function that updates the table filters state, also the table filters redux state.
     * Called when `Apply filters` button is clicked.
     * @param filters
     * @param fromFilterBar - boolean indicator if called from Apply filters button in FilterBar component
     * @param fromQuickSearch - boolean indicator if called from the quick search component
     */
    const applyFilters = async (
        filters?: GetCustomersRequestPayload['filters'],
        fromFilterBar?: boolean,
        fromQuickSearch?: boolean
    ) => {
        if (!filters) {
            setTableFilterToInitialState();
        } else {
            if (fromFilterBar) {
                emptyPredefinedFilterOnAppliedFilters(
                    filters,
                    tableFilters,
                    customersTableFilter,
                    fromFilterBar,
                    () => {
                        changeCustomersTableFilter('');
                    }
                );
            }
        }

        let appliedFilters: any = filters || initialState.filters;
        const savedSortState = get(appliedFilters, 'tableSortState');
        if (savedSortState) {
            setTableSortState(savedSortState);
            applySortedByAndState(savedSortState);
            delete appliedFilters.tableSortState;
        }

        await resetTableScrollAndPageData();
        await setTableFilters(appliedFilters);
        await dispatch(updateCustomersFiltersAction(appliedFilters));

        await resetAllSelectedRowKeys(fromQuickSearch);
    };

    /**
     * Function that sets the value of the dropdown filter next to refresh button to it's initial state.
     */
    const setTableFilterToInitialState = () => {
        if (customersState.tableFilter !== initialTableFilter) {
            setCustomersTableFilter(initialTableFilter);

            if (!isUndefined(initialTableFilter))
                changeCustomersTableFilter(initialTableFilter);

            resetAllSelectedRowKeys();
        }
    };

    /**
     * Function called when `Apply` button is clicked inside the ellipsis popover,
     * also hides the ellipsis popover after `Save` is clicked.
     * @param savedValues - optional param - used when a specific tableSortState value is passed.
     */
    const applySortedByAndState = async (savedValues?: any) => {
        await resetTableScrollAndPageData();
        const usedValues = get(savedValues, 'sortBy')
            ? savedValues
            : tableSortState;
        await dispatch(updateCustomersSortByAndStateAction(usedValues));
        await updateShowConditionsObject({
            filterEllipsis: false,
        });
    };

    /**
     * Common function that manipulates the state object for showConditions state.
     */
    const updateShowConditionsObject = (showConditionObject: {}) => {
        setShowConditions((prevState) => ({
            ...prevState,
            ...showConditionObject
        }));
    };

    /**
     * Common function that updates the tableRowSelection state.
     * @param selectionObject - must conform to tableRowSelection object
     */
    const updateTableRowSelection = (selectionObject: {}) => {
        setTableRowSelection({
            ...tableRowSelection,
            ...selectionObject,
        });
    };

    /**
     * Common function for updating the action bar items state.
     * @param itemStateObject - must conform to actionBarItemsState object
     */
    const updateActionBarItemsState = (itemStateObject: {}) => {
        setActionBarItemsState({
            ...actionBarItemsState,
            ...itemStateObject,
        });
    };

    /**
     * Customer Details Functions
     */
    /**
     * Function called when customer details drawer is closed.
     */
    const closeCustomerDetailsDrawer = () => {
        setCustomerDetailsDrawer({
            visible: false,
            activeTab: undefined,
        });
    };

    /**
     * Table Functions
     */
    /**
     * Common function called for updating table sort state.
     * @param tableSortStateObject  - must conform to tableSortState object
     */
    const updateTableSortStateObject = (tableSortStateObject: {}) => {
        setTableSortState({
            ...tableSortState,
            ...tableSortStateObject,
        });
    };

    /**
     * Function called when checkbox is clicked (either checked/unchecked) - will call onRowSelect.
     * @param record - the data for each row (with additional `key` property to identify the row)
     */
    const onCheckboxClick = (record: DynamicObject) => {
        const selectedRowKeys = [...tableRowSelection.selectedRowKeys];
        const isCurrentlySelected = includes(selectedRowKeys, record.key);
        const newSelectedRowKeys = !isCurrentlySelected
            ? [...selectedRowKeys, record.key]
            : remove(selectedRowKeys, (key: string) => key !== record.key);

        onRowSelect(record, !isCurrentlySelected, newSelectedRowKeys, true);
    };

    /**
     * Function called when row is clicked. Responsible for showing the drawer.
     * @param record - data for each row
     * @param activeTab - the tab key to be shown upon showing the drawer
     */
    const onRowClick = (record: DynamicObject, activeTab?: string) => {
        dispatch(
            setCustomerSelectedIdRequestAction(get(record, 'Id'), () => {
                setCustomerDetailsDrawer({
                    visible: true,
                    activeTab,
                });
            })
        );
    };

    /**
     * Function triggered when after checkbox is clicked. Responsible for setting the state of selected
     * or unselected rows.
     * @param record - table row data record
     * @param selected - boolean if checked/unchecked
     * @param selectedRows - list of keys selected previously
     * @param nativeEvent
     */
    const onRowSelect = (
        record: DynamicObject,
        selected: boolean,
        selectedRows: string[],
        nativeEvent: Event | boolean
    ) => {
        // nativeEvent overridden
        const selectedRowKeys =
            nativeEvent === true ? [...selectedRows] : map(selectedRows, 'key');

        let unselectedRowKeys = [];
        if (selected) {
            unselectedRowKeys = filter(
                tableRowSelection.unselectedRowKeys,
                (unselectedKey: string) => unselectedKey !== record.key
            );
        } else {
            unselectedRowKeys = showConditions.allSelected
                ? [...tableRowSelection.unselectedRowKeys, record.key]
                : [];
        }

        updateTableRowSelection({
            selectedRowKeys,
            unselectedRowKeys,
        });
    };

    /**
     * Function triggered when action bar items with drawer/popover is clicked.
     * @param name - name of popover item
     * @param condition - optional condition whether to show the popover/drawer
     */
    const popoverOnVisibleChange = (name: string, condition?: boolean) => {
        return (visible: boolean) => {
            if (condition === undefined || condition === true) {
                let visibilityCondition = visible;
                if (name === 'editDrawer' || name === 'addCommentDrawer' 
                || name === 'sendInformationTo' || name === 'assignUserDrawer' || name === 'createNewPaymentPlanDrawer'
                || name === 'invoiceNoPaymentPlanDrawer') {
                    visibilityCondition = !showConditions[name];
                    updateShowConditionsObject({
                        [name]: visibilityCondition,
                    });
                } else {
                    updateShowConditionsObject({
                        [name]: visible,
                    });
                }
            }
        };
    };

    /**
     * Reset Functions
     */
    /**
     * Function for resetting the table scroll and sets current page to initial state.
     */
    const resetTableScrollAndPageData = async () => {
        resetTableScroll = true;
        await setTableCurrentPage(0);
    };

    /**
     * Function for resetting all selected and unselected row keys in the state.
     * Also resets some of the action bar items state
     * and the allselected indicator for `Select all` button.
     * @param excludeShowConditions - boolean indicator that checks if allSelected property in
     * `showConditions` state be excluded in resetting.
     */
    const resetAllSelectedRowKeys = (excludeShowConditions = false) => {
        // reset Selected Row Keys after change role success
        updateTableRowSelection({
            selectedRowKeys: [],
            unselectedRowKeys: [],
        });
        updateActionBarItemsState({
            editButtonDisabled: true,
        });
        if (!excludeShowConditions) {
            updateShowConditionsObject({
                allSelected: false,
            });
        }
    };

    /**
     * Function called when closing the filter bar.
     * Responsible for hiding the filter bar.
     * Also resets all the table state filters applied to initial state.
     */
    const closeFilterBar = async () => {
        await applyFilters();
        updateShowConditionsObject({
            filterBar: false,
        });
    };

    /**
     * Function called when customer batch edit is cancelled.
     */
    const onCancelBatchCustomerUpdate = () => {
        updateShowConditionsObject({
            editDrawer: false,
        });
    };

    /**
     * Function for getting the values needed when connecting to API.
     * Like the selected keys, filter object, and boolean indicator if
     * the selected keys are to be excluded from search or included.
     */
    const getSelectedCustomersValues = () => {
        let allExcept = false;
        let keysToUse = [...tableRowSelection.selectedRowKeys];

        if (showConditions.allSelected) {
            allExcept = true;
            keysToUse = [...tableRowSelection.unselectedRowKeys];
        }

        const filterObject = {
            ...tableFilters,
            ATBType: tableSortState.atbType,
        };

        return {
            allExcept,
            keysToUse,
            filterObject,
        };
    };

    /**
     * Function called when clicking the `Save` button on batch edit customers (Custmoier object, for now: VIP status).
     * @param value - object needed when updating the Customer data, for now only vip prop
     */
    const onSaveBatchCustomerUpdate = (value: any) => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedCustomersValues();
        const vipValue = get(value, 'vip');

        dispatch(
            bulkSaveCustomerUpdateRequestAction({
                filter: filterObject,
                customerIds: keysToUse,
                excludeCustomers: allExcept,
                customerObject: {
                    IsVip: isUndefined(vipValue) ? undefined : !!vipValue,
                    NewInvoiceSendMode: get(value, 'newInvoiceSendMode')
                },
                callback: bulkSaveCustomersResponseModal(keysToUse.length),
            })
        );
    };

    // /**
    //  * Function called when customer data is updated (vip status)
    //  * either in Edit button (batch)
    //  * or the vip status update inside the Customer details drawer.
    //  */
    // const refetchCustomersListAfterEditing = () => {
    //     isRefetching = true;
    //     resetTableScrollAndPageData();
    //     fetchCustomers(0);
    // };

    /**
     * Function responsible for showing the response modal after
     * saving the customer/s data.
     * @param idsCount - wrapper param - indicates if label is plural or singular
     */
    const bulkSaveCustomersResponseModal =
        (idsCount: number) =>
        ({ IsSuccess }: { IsSuccess: boolean }) => {
            if (IsSuccess) {
                Modal.success({
                    title: getTranslatedText('Success'),
                    content: getTranslatedText(`${capitalize(customerLabel)}${
                        idsCount > 1 ? 's' : ''
                        } data updated successfully!`),
                    onOk: () => {
                        // resetTableScrollAndPageData();
                        updateShowConditionsObject({
                            allSelected: false,
                            editDrawer: false,
                        });
                        refetchListAndResetScroll();
                        updateActionBarItemsState({
                            editButtonLoading: false,
                        });
                    },
                    okText: getTranslatedText('OK'),
                });
            } else {
                Modal.error({
                    title: getTranslatedText('Error'),
                    content: (
                        <div>
                            <div>{getTranslatedText(`Failed to update ${toLower(customerLabel)}${
                                idsCount > 1 ? 's' : ''
                                } data!`) }</div>
                        </div>
                    ),
                    okText: getTranslatedText('OK'),
                });
            }
        };

    /**
     * Function for populating the loading text of table.
     */
    const populateTableLoadingText = () => {
        const loadingText = `Fetching ${
            tableCurrentPage === 0 || isRefetching ? 'list of' : 'more'
        } ${toLower(customerLabel)}s`;

        isRefetching = false;

        return loadingText;
    };

    /**
     * Function for populating the edit draewr title.
     * Plural/singular
     */
    const populateEditDrawerTitle = () =>
        `Edit ${toLower(customerLabel)}${
            tableRowSelection.selectedRowKeys.length > 1 ? 's' : ''
        }`;

    /**
     * Function for populating the modal title when saving the customer data.
     */
    const populateCustomerBatchSaveRequestModalTitle = () => {
        const { keysToUse } = getSelectedCustomersValues();

        return `Saving ${toLower(customerLabel)}${
            keysToUse.length > 1 ? 's' : ''
        } data`;
    };

    /**
     * Function for populating the modal body when saving the customer data.
     */
    const populateCustomerBatchSaveRequestModalDisplayMessage = () => {
        const { keysToUse } = getSelectedCustomersValues();

        return `Please wait while saving the ${toLower(customerLabel)}${
            keysToUse.length > 1 ? "s'" : "'s"
        } classification status. . .`;
    };

    /**
     * Function for populating the edit drawer content for editing vip status.
     */
    const populateEditDrawerContent = () => {
        return (
            <CustomerEditDrawerContent
                onCancelClick={onCancelBatchCustomerUpdate}
                onSaveClick={onSaveBatchCustomerUpdate}
                visible={showConditions.editDrawer}
            />
        );
    };

    /**
     * Function for populating the `Edit` button action bar item.
     */
    const populateEditProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'edit']} />
            <span>{getTranslatedText('Edit')}</span>
        </>
    );

    /**
     * Function called when add comment panel is closed.
     * @param refreshList - optional boolean indicator whether to refresh the table list or not
     */
    const closeAddCommentPanel = (refreshList?: boolean) => {
        updateShowConditionsObject({
            addCommentDrawer: false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };

    /**
     * Function called when send information panel is closed.
     */
     const closeSendInformationPanel = () => {
        updateShowConditionsObject({
            sendInformationTo: false,
        });
    };

    /**
     * Function called to close the add comment panel
     * @param refreshList
     */
    const closePaymentPlanPanel =(refreshList?: boolean) => {
        updateShowConditionsObject({
            createNewPaymentPlanDrawer : false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };

    const closeInvoiceNoPaymentPlanPanel =(refreshList?: boolean) => {
        updateShowConditionsObject({
            invoiceNoPaymentPlanDrawer : false,
        });

        if (refreshList) {
            refetchListAndResetScroll();
        }
    };

    /**
     * Function for getting the payload for sending information.
     */
    const getSendInformationFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedCustomersValues();

        return {
            filter: filterObject,
            customerIds: keysToUse,
            excludeCustomers: allExcept,
        };
    };

    /**
     * Function for getting the payload for adding comment.
     */
    const getAddCommentFilterPayload = () => {
        const { allExcept, keysToUse, filterObject } =
            getSelectedCustomersValues();

        return {
            filter: filterObject,
            customerIds: keysToUse,
            excludeCustomers: allExcept,
        };
    };

    /**
     * Function that checks if the Name of the page view to be saved already exists.
     * @param name - name of page view
     */
    const doesViewNameExist = (name: string) => {
        if (actionBarRef.current)
            return actionBarRef.current.doesViewNameExist(name);
    };

    /**
     * Common function for applying the quick search filters
     * @param filters
     * @param fromFilterBar
     */
    const applyQuickSearchFilters = (
        filters?: any,
        fromFilterBar?: boolean
    ) => {
        applyFilters(filters, fromFilterBar, true);
    };

    /**
     * Common function for populating the quick search common filter section
     */
    const populateQSCommonFilterOptions = () => {
        const filterOptions = [
            {
                label: capitalize(customerLabel),
                value: qsFilterNames.CUSTOMER,
            },
        ];

        return filterOptions;
    };

    const usingCustomerWorkflow = get(
        selectedUserCompany,
        'Company.UsingCustomerWorkflow'
    );

    /**
    * Function called when click download specialised reports is closed.
    */
    const closeSpecialisedReportsPanel = () => {
        updateShowConditionsObject({
            specialisedReportsPanel: false
        });
    };

    
    /**     
    * Function called when assign to changes has been saved successfully.
    * @param values
    */
    const onSaveSuccessAssignToUserChanges = (values: DynamicObject) => {
        handleCustomerFilterRefresh();

        updateShowConditionsObject({
            assignUserDrawer: false
        });
    };

    const populateAssigUsertProtectedPopoverButtonContent = () => (
        <>
            <FontAwesome icon={['fas', 'user']} />
            <span>Assign to</span>
        </>
    );

    /**
     * Function for populating the assign to drawer content.
     */
    const populateAssignToDrawerContent = () => {
        return (
            <CustomerAssignUserPanel
                onCancelClick={() => {
                    updateShowConditionsObject({
                        assignUserDrawer: false
                    });
                }}
                onSaveSuccess={onSaveSuccessAssignToUserChanges}
                visible={showConditions.assignUserDrawer}
                getSelectedValues={getSelectedCustomersValues}
                companyId={get(selectedUserCompany, 'Company.CompanyId')}
            />
        );
    };

    const checkingForAssignedUser = (inputValue: string | undefined | null) => {

        if (checkIfEmailIsValid(inputValue)) return true;

        return false;
    };

    /**
    * Function that fetches the customer first letter values
    */
    const getCustomerFirstLatterValuesOptions = async (
        options: DynamicObject[],
        valuesList: DynamicObject[]
    ) => {
        forEach(
            valuesList,
            (value, index) => {
                options.push({
                    label: value,
                    value: value
                });
            }
        );
        return options;
    };
    const getCustomerFirstLetterValuesList = () => {
        if (!selectedUserCompany) return;
        const companyIdCognito = get(currentUser, CompanyIdAttribute);
        const selectedCompanyId = get(selectedUserCompany, 'Company.CompanyId');
        let options: DynamicObject[] = [];

        if (companyIdCognito === selectedCompanyId) {
            dispatch(
                getCustomerFirstLetterValuesRequestAction(
                    selectedCompanyId,
                    async (response: any) => {
                        if (response.IsSuccess) {
                            options = await getCustomerFirstLatterValuesOptions(options, response.Values);
                            setCustomerValuesList(options)
                        }
                    }
                )
            );
        }
    };
    useEffect(getCustomerFirstLetterValuesList, [selectedUserCompany]);

    return (
        <Col span={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container" type="flex" justify="space-between">
                    <Col>
                        <Title level={3}>{getTranslatedText(capitalize(customerLabel))}</Title>
                    </Col>
                    <Col className="ta-right">
                        <QuickSearchComponent
                            loading={customersState.loading}
                            customFieldsFilterList={customFieldsFilterList}
                            applyFilters={applyQuickSearchFilters}
                            filterOptions={populateQSCommonFilterOptions()}
                            selectedUserCompany={selectedUserCompany}
                        />
                    </Col>
                </Row>
                <div className="spacer-5" />
                {/* Filter Bar */}
                <QueueAnim type="top" leaveReverse duration={300}>
                    {showConditions.filterBar && (
                        <div key="filter-bar-container">
                            <Suspense fallback={null}>
                                <FilterBar
                                    pageName={pageName}
                                    loading={customersState.loading}
                                    applyFilters={applyFilters}
                                    filters={getCustomersFilterBarFilters(
                                        tableSortState.atbType,
                                        customerValuesOption,
                                        capitalize(customerLabel) + ' A-Z',
                                        isCalendarView
                                    )}
                                    filterValues={customersState.filters}
                                    colDivision={4}
                                    closeAllPopovers={
                                        customerDetailsDrawer.visible
                                    }
                                    closeFilterBar={closeFilterBar}
                                    appliedView={customersTableFilter}
                                    doesViewNameExist={doesViewNameExist}
                                    customFieldsFilters={IsSearchCustomFieldsByCheckBox ? customFieldValuesList : customFieldsFilterList}
                                    tableSortState={{
                                        sortBy: customersState.sortBy,
                                        atbType: customersState.atbType,
                                        sortAscending:
                                            customersState.sortAscending,
                                    }}
                                    checkingForAssignedUser={
                                        checkingForAssignedUser
                                    }
                                />
                            </Suspense>
                        </div>
                    )}
                    <SpecialisedReportsDrawer
                        visible={showConditions.specialisedReportsPanel}
                        onClose={closeSpecialisedReportsPanel}
                        downloadToExcelCallBack={downloadToExcelHandler}
                        reportOptions={{
                            options: SpecialisedReports,
                            loading: false
                        }}
                    /> 
                </QueueAnim>
                <div key="action-bar-container">
                    <ActionBar
                        ref={actionBarRef}
                        pageName={pageName}
                        loading={customersState.loading}
                        filterBarOpen={showConditions.filterBar}
                        actionItems={[
                            {
                                actionKey: 'customer-filter',
                                actionType: 'select-with-button',
                                selectValue: customersTableFilter,
                                selectDropdownRender:
                                    populateCustomerFilterSelectDropdownRender,
                                onSelectChange: changeCustomersTableFilter,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fa', 'sync']}
                                            className="mr-8"
                                        />
                                        <span>{getTranslatedText('Refresh')}</span>
                                    </>
                                ),
                                buttonDisabled: customersState.loading,
                                onButtonClick: handleCustomerFilterRefresh,
                            },
                            {
                                actionKey: 'customer-select-all',
                                actionType: 'protected-button',
                                buttonDisabled: downloadDisabled,
                                onButtonClick: selectAllRows,
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'check-double']}
                                        />
                                        <span>
                                            {getTranslatedText(populateSelectDeselectAllLabel())}
                                        </span>
                                    </>
                                ),
                            },
                            // {
                            //     actionKey: 'customer-assign-to',
                            //     actionType: 'protected-button',
                            //     buttonDisabled: isEmpty(
                            //         tableRowSelection.selectedRowKeys
                            //     ),
                            //     onButtonClick: () => console.log('Assign to'),
                            //     buttonContent: (
                            //         <>
                            //             <FontAwesome
                            //                 icon={['fas', 'user-tag']}
                            //             />
                            //             <span>Assign to</span>
                            //         </>
                            //     ),
                            // },
                            {
                                actionKey: 'task-edit',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.editDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'editDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText(populateEditDrawerTitle()),
                                popoverContent: populateEditDrawerContent(),
                                buttonLoading:
                                    actionBarItemsState.editButtonLoading,
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent:
                                    populateEditProtectedPopoverButtonContent(),
                            },
                            !manualCommunicationEnabled ? null : {
                                actionKey: 'customer-send-information-to',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.sendInformationTo,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'sendInformationTo',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText('Send information'),
                                popoverContent: (
                                    <SendInformationPanel
                                        visible={
                                            showConditions.sendInformationTo
                                        }
                                        closePanel={closeSendInformationPanel}
                                        customerFilterPayload={getSendInformationFilterPayload()}
                                        dispatchAction={
                                            customerSendInformationRequestAction
                                        }
                                        templateOptions={templateOptions}
                                    />
                                ),
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'info-circle']}
                                        />
                                        <span>{getTranslatedText('Send information to')}</span>
                                    </>
                                ),
                                drawerWidth: 350,
                            },
                            {
                                actionKey: 'customer-add-comment',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.addCommentDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'addCommentDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: getTranslatedText('Add a comment'),
                                popoverContent: (
                                    <AddCommentPanel
                                        visible={
                                            showConditions.addCommentDrawer
                                        }
                                        closePanel={closeAddCommentPanel}
                                        filterPayload={getAddCommentFilterPayload()}
                                        dispatchAction={
                                            customerAddCommentRequestAction
                                        }
                                    />
                                ),
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'comment']}
                                        />
                                        <span>{getTranslatedText('Add comment')}</span>
                                    </>
                                ),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'customer-assign-user',
                                actionType: 'protected-drawer-button',
                                buttonDisabled: isEmpty(
                                    tableRowSelection.selectedRowKeys
                                ),
                                buttonContent:
                                    populateAssigUsertProtectedPopoverButtonContent(),
                                popoverVisible: showConditions.assignUserDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'assignUserDrawer',
                                    !isEmpty(tableRowSelection.selectedRowKeys)
                                ),
                                popoverTitle: 'Assign to User',
                                popoverContent: populateAssignToDrawerContent(),
                                drawerWidth: 500,
                            },
                            {
                                actionKey: 'paymentPlan-create-new-payment-plan',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.createNewPaymentPlanDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'createNewPaymentPlanDrawer',
                                    true
                                ),
                                allowedRoles:
                                    rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN,
                                hideElement: !isPaymentPlanEnabled,
                                buttonDisabled: !isCreateNewPaymentPlanAllowed,
                                popoverTitle: getTranslatedText('Create new payment plan'),
                                popoverContent: (
                                    <CreateNewPaymentPlanPanelWrapper
                                        visible={
                                            showConditions.createNewPaymentPlanDrawer
                                        }
                                        closePanel={closePaymentPlanPanel}
                                        dispatchAction={
                                            manualCreateNewPaymentPlanRequestAction
                                        }
                                        customerId={tableRowSelection.selectedRowKeys[0]}
                                    />
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText('Create new payment plan')}</span>
                                    </>
                                ),
                                drawerWidth: 800,
                            },
                            {
                                actionKey: 'invoices-no-payment-plan',
                                actionType: 'protected-drawer-button',
                                popoverVisible: showConditions.invoiceNoPaymentPlanDrawer,
                                drawerCloseable: false,
                                popoverOnVisibleChange: popoverOnVisibleChange(
                                    'invoiceNoPaymentPlanDrawer',
                                    true
                                ),
                                allowedRoles:
                                    rolePermissions.PAYMENT_PLAN_CREATE_NEW_PAYMENT_PLAN,
                                hideElement: !isPaymentPlanEnabled,
                                buttonDisabled: !isCreateBatchPaymentPlanAllowed,
                                popoverTitle: getTranslatedText('Create batch payment plan'),
                                popoverContent: (
                                    <InvoiceNoPaymentPlanPanel
                                        visible={showConditions.invoiceNoPaymentPlanDrawer}
                                        closePanel={closeInvoiceNoPaymentPlanPanel}
                                        customerLabel={customerLabel}
                                        requestFromPage={pageName}
                                        getSelectedItemsValues={getSelectedCustomersValues}
                                    />
                                ),
                                buttonContent: (
                                    <>
                                        <FontAwesome
                                            icon={['fas', 'plus-circle']}
                                        />
                                        <span>{getTranslatedText('Create batch payment plan')}</span>
                                    </>
                                ),
                                drawerWidth: 800,
                            }
                        ].filter(action => !isEmpty(action))}
                        actionEllipsis={{
                            popoverVisible: showConditions.filterEllipsis,
                            popoverOnVisibleChange:
                                popoverOnVisibleChange('filterEllipsis'),
                            popoverTitle: populateFilterEllipsisPopoverTitle(),
                            popoverContent:
                                populateFilterEllipsisPopoverContent(),
                            buttonContent: (
                                <FontAwesome icon={['fas', 'ellipsis-h']} />
                            ),
                        }}
                    />
                </div>
                <div className="spacer-15" />
                {/* Table Section */}
                <Row key="table-container">
                    <Col span={24}>
                        <VirtualizedList
                            dataSource={customersState.data}
                            fetchMore={handleFetch}
                            scroll={computeTableScroll(
                                window.innerHeight - 185,
                                tablePageSize,
                                CUSTOMERS_PAGE.rowHeight
                            )}
                            resetTableScroll={resetTableScroll}
                            selectedRowKeys={tableRowSelection.selectedRowKeys}
                            rerenderTrigger={tableRowSelection.selectedRowKeys}
                            onRowClick={onRowClick}
                            onCheckboxClick={onCheckboxClick}
                            loading={customersState.loading}
                            loadingText={populateTableLoadingText()}
                            emptyText={
                                !isEmpty(customersState.errorMessages)
                                    ? get(customersState, 'errorMessages.0')
                                    : `No ${toLower(customerLabel)}s found`
                            }
                            hasNextPage={customersState.pageData.hasNextPage}
                            itemComponent={CustomerItemComponent}
                            itemHeight={CUSTOMERS_PAGE.rowHeight}
                            selectedId={selectedId}
                            usingCustomerWorkflow={usingCustomerWorkflow}
                            extraData={{
                                addCommentDrawerVisible:
                                    showConditions.addCommentDrawer,
                            }}
                        />
                    </Col>
                    <Suspense fallback={null}>
                        <CustomerItemDetailsDrawerComponent
                            visible={customerDetailsDrawer.visible}
                            activeTab={customerDetailsDrawer.activeTab}
                            closeDrawer={closeCustomerDetailsDrawer}
                            refetchList={refetchListAndResetScroll}
                            atbType={get(customersState, 'atbType')}
                            getCustomerDataRequestAction={getCustomerDataRequestAction}
                        />
                    </Suspense>
                    {customersState.saveCustomerDataLoading && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateCustomerBatchSaveRequestModalTitle())}
                                modalVisible={
                                    customersState.saveCustomerDataLoading
                                }
                                displayMessage={getTranslatedText(populateCustomerBatchSaveRequestModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                    {showConditions.downloadToExcel && (
                        <Suspense fallback={null}>
                            <ModalWithSpinner
                                modalTitle={getTranslatedText(populateDownloadToExcelModalTitle())}
                                modalVisible={showConditions.downloadToExcel}
                                displayMessage={getTranslatedText(populateDownloadToExcelModalDisplayMessage())}
                            />
                        </Suspense>
                    )}
                    <CreateScheduledReportDrawer
                        visible={showConditions.createScheduledReport}
                        onClose={onCreateScheduledReportEnd}
                        widgetQuery={createScheduleReportPayload}
                    />
                </Row>
            </QueueAnim>
        </Col>
    );
};

export default withPageViewHandler(
    withAccountingSystemHandler(CustomersManagementPage)
);
